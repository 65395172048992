import React, { ReactElement } from 'react';
import { Tabs } from '@chakra-ui/react';

import { IEnjcItemTitled, IItemViewProps } from '../interfaces';
import { EnjcTabsList } from './EnjcTabsList';
import { EnjcPanels } from './EnjcPanels';

interface IProps<TEnjcItem extends IEnjcItemTitled> {
  readonly items: ReadonlyArray<TEnjcItem>;
  readonly selectedItemId: string | undefined;
  readonly onItemSelect: (item: string | undefined) => void;
  readonly onItemClose: (item: string) => void;

  readonly itemView: ({ item }: IItemViewProps<TEnjcItem>) => ReactElement;
}

const EnjcItemsTabsF = <TEnjcItem extends IEnjcItemTitled = IEnjcItemTitled>({
  items,
  selectedItemId,
  onItemSelect,
  onItemClose,
  itemView,
}: IProps<TEnjcItem>): ReactElement => {
  const tabIndex = React.useMemo(() => {
    if (selectedItemId === undefined) {
      return 0;
    }
    const itemIndex = items.findIndex((v) => v.id === selectedItemId);
    return itemIndex < 0 ? 0 : itemIndex + 1;
  }, [items, selectedItemId]);

  const handleTabChange = React.useCallback(
    (nextTabIndex: number) => {
      onItemSelect(nextTabIndex <= 0 || nextTabIndex > items.length ? undefined : items[nextTabIndex - 1].id);
    },
    [items, onItemSelect],
  );

  return (
    <Tabs bg="blackAlpha.50" index={tabIndex < 0 ? 0 : tabIndex} onChange={handleTabChange}>
      {/* todo: remove all components */}
      {/*<EnjcTabsList items={items} selectedItemId={selectedItemId} onItemClose={onItemClose} />*/}
      <EnjcPanels items={items} itemView={itemView} />
    </Tabs>
  );
};

export const EnjcItemsTabs = EnjcItemsTabsF; // React.memo(EnjcItemsTabsF);
