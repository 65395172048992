import { keyListDeltaToInput, stringDeltaToInput } from '../../enjc-delta';
import { EnjcValueTreeNodeDelta } from '../../enjc-workspace-editing';
import { ValueTreeNodeInput } from '../../enjicalc-graphql';
import { literalToInput } from './literalToInput';

export const convertValueTreeNodeDeltaToDiffInput = (delta: EnjcValueTreeNodeDelta): ValueTreeNodeInput => {
  return {
    key: delta.key,
    // TODO: implement clear flag for tags
    tags: delta.tags && { remove: delta.tags.subsetBefore, add: delta.tags.subsetAfter },
    draft: delta.draft && delta.draft.map((d) => stringDeltaToInput(d, false)),
    mode: delta.mode?.atomAfter,

    literal: delta.literal && literalToInput(delta.literal.atomAfter),
    symbol: delta.symbol?.atomAfter,
    function: delta.function?.atomAfter,
    arguments: delta.arguments && delta.arguments.map((d) => keyListDeltaToInput(d, false)),

    remove: delta.removeAdd === 'remove' || undefined,
  };
};
