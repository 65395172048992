import { assertNever } from '../../../../utils';
import { IValueTreeViewContext, getCursorPositionNode } from '../../../enjc-value-view-ctx';
import { ValueTreeClientDelta } from '../../tree-editing';
import { isTreePositionNode, isTreePositionNodeDraft } from '../../tree-position';
import { mkTreeCursorNode, mkTreeCursorNodeDraft } from '../../tree-cursor';

export const handleTreeKeyEnterEscape = (vtvCtx: IValueTreeViewContext, keyEscape: boolean): ValueTreeClientDelta => {
  const { cursorPosition, positionNode } = getCursorPositionNode(vtvCtx)!;

  if (isTreePositionNode(cursorPosition)) {
    if (!keyEscape) {
      // Move cursor to draft
      return {
        cursor: mkTreeCursorNodeDraft(cursorPosition.nodeKey, cursorPosition.atEnd ? positionNode.draft.length : 0),
      };
    } else {
      // Do nothing
      return {};
    }
  }

  if (isTreePositionNodeDraft(cursorPosition)) {
    if (keyEscape) {
      return {
        cursor: mkTreeCursorNode(cursorPosition.nodeKey, cursorPosition.at >= positionNode.draft.length / 2),
      };
    } else {
      // FIXME: replace with value hint processing
      return {
        cursor: mkTreeCursorNode(cursorPosition.nodeKey, cursorPosition.at >= positionNode.draft.length / 2),
      };
    }
  }

  return assertNever(cursorPosition);
};
