import { defineStyleConfig, extendTheme } from '@chakra-ui/react';
import '@fontsource/plus-jakarta-sans';

const Text = defineStyleConfig({
  baseStyle: {
    color: 'gray',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: '400',
  },
});

const Button = defineStyleConfig({
  baseStyle: {
    colorScheme: 'black',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: '400',
  },
});

const Tab = defineStyleConfig({
  baseStyle: {
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: '400',
    color: 'gray',
  },
});

const Spinner = defineStyleConfig({
  baseStyle: {
    emptyColor: 'gray.200',
    color: 'blackAlpha',
  },
  defaultProps: {
    size: 'xl',
  },
});

const Popover = defineStyleConfig({
  baseStyle: {
    content: { width: 'unset' },
  },
});

const theme = extendTheme({
  components: {
    Text,
    Button,
    Tab,
    Spinner,
    Popover,
  },
  colors: {
    primary: {
      100: '#10753B',
      200: '#10753B', //TODO: hire design director to fix
    },
    orange: {
      100: '#FF6D00',
      200: '#DD6B20',
    },
  },
  styles: {
    global: {
      '*:where(img, svg, video, canvas, audio, iframe, embed, object)': {
        display: 'inline',
      },
    },
  },
  shadows: {
    base: '0px 2px 3px gray',
  },
});

export { theme };
