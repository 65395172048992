import React, { ReactElement } from 'react';
import { ISymbolValueBinaryOperatorViewProps } from '../../enjc-symbol-value-composite-view/props';

export const SymbolValueBinaryOperatorMathmlView = ({
  operator,
  argLeft,
  argRight,
  onNodeClick,
}: ISymbolValueBinaryOperatorViewProps): ReactElement => {
  const unicodeOperator = operator.glyph;
  return (
    <mrow>
      {argLeft}
      <mo>{unicodeOperator}</mo>
      {argRight}
    </mrow>
  );
};
