import React, { ReactElement } from 'react';
import { glyphFromAsciiMathStr } from '../../../enjc-glyph';
import { MathMathMLAttributes } from '../../../enjc-react-mathml/attributes';
import { EnjcGlyphMathmlView } from './EnjcGlyphMathmlView';

interface IProps extends MathMathMLAttributes {
  readonly glyphAsciiMathStr: string;
  readonly optional?: boolean;
}

export const EnjcGlyphMathmlAdapterView = ({ glyphAsciiMathStr, optional, ...mathProps }: IProps): ReactElement => {
  const enjcGlyph = React.useMemo(() => {
    if (!glyphAsciiMathStr) {
      return undefined;
    }
    try {
      return glyphFromAsciiMathStr(glyphAsciiMathStr);
    } catch (error) {
      return undefined;
    }
  }, [glyphAsciiMathStr]);

  if (!enjcGlyph) {
    return (
      <mi mathcolor={'red'} {...mathProps}>
        {optional ? '' : '?'}
      </mi>
    );
  }

  return <EnjcGlyphMathmlView enjcGlyph={enjcGlyph} {...mathProps} />;
};
