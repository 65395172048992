export const countMatchingKeys = (
  keys1: ReadonlyArray<string>,
  keys2: ReadonlyArray<string>,
  countFromEnd: boolean,
): number => {
  let i = 0;
  while (
    i < keys1.length &&
    i < keys2.length &&
    keys1[countFromEnd ? keys1.length - 1 - i : i] === keys2[countFromEnd ? keys2.length - 1 - i : i]
  ) {
    i++;
  }
  return i;
};
