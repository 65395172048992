import React, { ReactNode } from 'react';

export const TreeFunctionFracView = ({ argNum, argDen }: { argNum: ReactNode; argDen: ReactNode }) => {
  return (
    <mfrac>
      {argNum}
      {argDen}
    </mfrac>
  );
};
