import React, { ReactElement } from 'react';
import { assertNever } from '../../../../utils';
import { EnjcGlyphItem } from '../../../enjc-glyph/model';
import { EnjcGlyphSubSupPlainView } from './EnjcGlyphSubSupPlainView';
import { EnjcGlyphDivisionPlainView } from './EnjcGlyphDivisionPlainView';

interface IProps {
  readonly enjcGlyphItem: EnjcGlyphItem;
}

export const EnjcGlyphItemPlainView = ({ enjcGlyphItem }: IProps): ReactElement => {
  switch (enjcGlyphItem.__typename) {
    case 'EnjcGlyphSubSup':
      return <EnjcGlyphSubSupPlainView glyphSubSup={enjcGlyphItem} />;
    case 'EnjcGlyphDivision':
      return <EnjcGlyphDivisionPlainView glyphDivision={enjcGlyphItem} />;
    default:
      return assertNever(enjcGlyphItem);
  }
};
