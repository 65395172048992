import React, { ReactElement } from 'react';
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdMoreHoriz, MdDriveFileRenameOutline, MdOutlineFileCopy, MdOutlineFileOpen } from 'react-icons/md';
import { EnjicalcWorkspace, TSectionId } from '../../libenjc/enjc-workspace';
import {
  editDeleteSection,
  EnjicalcWorkspaceUpdateResult,
  WorkspaceEditHistoryEntry,
} from '../../libenjc/enjc-workspace-editing';
import { EnjcSectionFragment } from '../../libenjc/enjicalc-graphql';
import { IoCloseSharp } from 'react-icons/io5';
import { DeleteConfirmationPopover } from 'src/components/misc';
import { QUICK_START_TOUR_STEP_COUNT, useQuickStartTour } from 'src/hooks';

type TSheet = EnjcSectionFragment;

interface IProps {
  readonly workspace: EnjicalcWorkspace;
  readonly sheet: TSheet;
  readonly onSheetOpen: (sheetSectionId: TSectionId) => void;
  readonly onRenameTitle: (sheetSectionId: TSectionId) => void;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateResult>;
}

const SheetMenuF = ({ workspace, sheet, onSheetOpen, performWorkspaceEdit, onRenameTitle }: IProps): ReactElement => {
  const { setCurrentStep } = useQuickStartTour();

  const handleDeleteSheet = React.useCallback(() => {
    const hEntry = editDeleteSection(workspace, sheet.id);
    performWorkspaceEdit(hEntry);
  }, [performWorkspaceEdit, sheet.id, workspace]);

  return (
    <>
      <Menu>
        <MenuButton
          onClick={(e) => e.stopPropagation()}
          as={IconButton}
          size={'sm'}
          aria-label={'sheet menu'}
          icon={<MdMoreHoriz />}
          backgroundColor="transparent"
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        />

        <MenuList minW="150px" onClick={(e) => e.stopPropagation()}>
          <MenuItem
            fontSize={'14px'}
            pr={'20px'}
            icon={<MdOutlineFileOpen size={17} color="black" />}
            onClick={() => {
              onSheetOpen(sheet.id);
              setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddSymbol);
            }}
          >
            {'Open sheet'}
          </MenuItem>
          <MenuItem
            fontSize={'14px'}
            icon={<MdDriveFileRenameOutline size={17} color="black" />}
            onClick={() => onRenameTitle(sheet.id)}
          >
            {'Rename sheet'}
          </MenuItem>
          <MenuItem fontSize={'14px'} icon={<MdOutlineFileCopy size={17} color="black" />} isDisabled>
            {'Copy to Global Library (Pro Version)'}
          </MenuItem>
          <DeleteConfirmationPopover onConfirm={handleDeleteSheet}>
            <MenuItem icon={<IoCloseSharp size={17} color="red" />} fontSize={'14px'}>
              {'Delete sheet'}
            </MenuItem>
          </DeleteConfirmationPopover>
        </MenuList>
      </Menu>
    </>
  );
};

export const SheetMenu = React.memo(SheetMenuF);
