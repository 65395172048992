import React, { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { HStack, Input, VStack } from '@chakra-ui/react';
import { SymbolEditor } from '../symbol-editor';
import { WorkspaceSymbolsStats } from './WorkspaceSymbolsStats';
import { useCtxEnjicalcWorkspace } from '../../../libenjc/enjc-react/enjc-react-context';

interface IProps {
  // readonly workspace: EnjicalcWorkspaceFragment;
  readonly tmpFlag?: boolean;
}

type FComp = (props: IProps) => ReactElement;

export const WorkspaceSymbolsEditor = React.memo<FComp>(({ tmpFlag }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchTerm = React.useMemo(() => searchParams.get('searchTerm') || '', [searchParams]);

  const setSearchTerm = React.useCallback(
    (value: string) => setSearchParams(value ? { searchTerm: value } : {}),
    [setSearchParams],
  );

  const { workspace } = useCtxEnjicalcWorkspace();

  return (
    <VStack align="start" display="block" overflowY="auto" h="100vh">
      <WorkspaceSymbolsStats />
      <HStack>
        <Input onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search..." value={searchTerm} />
      </HStack>
      {/* todo: ??? */}
      {workspace.symbols.map((symbol) => (
        <SymbolEditor key={symbol.id} workspace={workspace} symbol={symbol} />
      ))}
    </VStack>
  );
});
