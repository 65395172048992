import React, { ReactElement } from 'react';
import { EnjicalcSheet, EnjicalcWorkspace } from '../../../libenjc/enjc-workspace';
import { SheetHeaderView } from './SheetHeaderView';
import { EnjcWorkspaceSectionInput } from '../../../generated/graphql/types';

interface IProps {
  readonly workspace: EnjicalcWorkspace;
  readonly sheet: EnjicalcSheet;
  readonly updateSheet: (sheet: EnjcWorkspaceSectionInput) => void;
}

export const SheetHeaderF = ({ workspace, sheet, updateSheet }: IProps): ReactElement => {
  const setSheetCheckedName = React.useCallback(
    (value: string) => {
      updateSheet({
        id: sheet.id,
        sheetAuxMeta: { checkedName: value },
      });
    },
    [sheet.id, updateSheet],
  );

  const setSheetApprovedName = React.useCallback(
    (value: string) => {
      updateSheet({
        id: sheet.id,
        sheetAuxMeta: { approvedName: value },
      });
    },
    [sheet.id, updateSheet],
  );

  const setCheckedDate = React.useCallback(
    (value: Date | null) => {
      updateSheet({
        id: sheet.id,
        sheetAuxMeta: { checkedDate: value?.valueOf()?.toString() },
      });
    },
    [sheet.id, updateSheet],
  );

  const setApprovedDate = React.useCallback(
    (value: Date | null) => {
      updateSheet({
        id: sheet.id,
        sheetAuxMeta: { approvedDate: value?.valueOf()?.toString() },
      });
    },
    [sheet.id, updateSheet],
  );

  return (
    <SheetHeaderView
      workspaceTitle={workspace.title}
      sheet={sheet}
      setSheetCheckedName={setSheetCheckedName}
      setSheetApprovedName={setSheetApprovedName}
      setCheckedDate={setCheckedDate}
      setApprovedDate={setApprovedDate}
    />
  );
};

export const SheetHeader = React.memo(SheetHeaderF);
