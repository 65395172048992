import { getCursorPositionNode, IValueTreeViewContext } from '../../../enjc-value-view-ctx';
import { ValueTreeClientDelta } from '../../tree-editing';
import { ValueTreeKeyDownEvent } from '../model';
import {
  NavKeyArrowLeft,
  NavKeyArrowRight,
  NavKeyBackspace,
  NavKeyComma,
  NavKeyDelete,
  NavKeyEnter,
  NavKeyEscape,
  TNavKeyBracketRoundClose,
  TNavKeyBracketRoundOpen,
} from '../constants';
import { isNavKeyDraftChar, isNavKeyOperator } from '../utils';
import { handleTreeKeyComma } from './handleTreeKeyComma';
import { handleTreeKeyEnterEscape } from './handleTreeKeyEnterEscape';
import { handleTreeKeyBracketRoundOpen } from './handleTreeKeyBracketRoundOpen';
import { handleTreeKeyArrowHorizontal } from './handleTreeKeyArrowHorizontal';
import { handleTreeKeyRemove } from './handleTreeKeyRemove';
import { handleTreeKeyOperator } from './handleTreeKeyOperator';
import { handleTreeKeyDraftChar } from './handleTreeKeyDraftChar';

export const handleTreeKey = (
  vtvCtx: IValueTreeViewContext,
  keyDownEvent: ValueTreeKeyDownEvent,
): ValueTreeClientDelta => {
  // TODO: specify dedicated interface and replace with casting in handlers
  const valueCursorPositionNode = getCursorPositionNode(vtvCtx);
  if (!valueCursorPositionNode) {
    return {};
  }
  // FIXME: finish implementation
  switch (keyDownEvent.key) {
    // case NavKeyArrowUp:
    // case NavKeyArrowDown:
    case NavKeyComma:
      return handleTreeKeyComma(vtvCtx);
    case NavKeyEnter:
    case NavKeyEscape:
      return handleTreeKeyEnterEscape(vtvCtx, keyDownEvent.key === NavKeyEscape);
    case TNavKeyBracketRoundClose:
      // FIXME: implement
      return {};
    case TNavKeyBracketRoundOpen:
      return handleTreeKeyBracketRoundOpen(vtvCtx);
    case NavKeyArrowLeft:
    case NavKeyArrowRight:
      return handleTreeKeyArrowHorizontal(vtvCtx, keyDownEvent.key === NavKeyArrowRight);
    case NavKeyDelete:
    case NavKeyBackspace:
      return handleTreeKeyRemove(vtvCtx, keyDownEvent.key === NavKeyBackspace);
    default:
      if (isNavKeyOperator(keyDownEvent.key)) {
        return handleTreeKeyOperator(vtvCtx, keyDownEvent.key);
      }
      if (isNavKeyDraftChar(keyDownEvent.key)) {
        return handleTreeKeyDraftChar(vtvCtx, keyDownEvent.key);
      }
      console.warn(`Unknown ValueTree node navKey: '${keyDownEvent.key}' (${keyDownEvent.key.charCodeAt(0)})`);
      return {};
  }
};
