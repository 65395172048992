import { EnjicalcWorkspace, getEnjcSection, TSectionId } from '../../enjc-workspace';
import { WORKSPACE_CREATE_ID } from '../../enjc-client/constants';
import { EnjcWorkspaceSectionDelta, WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry, changeCreateSection } from '../utils';

export const editCreateSectionIn = (workspace: EnjicalcWorkspace, sectionId: TSectionId): WorkspaceEditHistoryEntry => {
  const existingSection = getEnjcSection(workspace, sectionId);

  const sectionDeltas: EnjcWorkspaceSectionDelta[] = [
    changeCreateSection(),
    {
      id: existingSection.id,
      items: [{ slicePosition: existingSection.items.length, sliceAfter: [WORKSPACE_CREATE_ID] }],
    },
  ];

  return mkWorkspaceEditHistoryEntry('editCreateSectionIn', {
    section: sectionDeltas,
  });
};
