import { IFunctionBinaryOperator } from '../../../enjc-value-operators';
import { getNodeKey, mkTreeFunction, ValueTreeNode } from '../../tree-node';
import { IOperatorsChain, IOperatorsChainLink } from '../interfaces';
import { findOperatorsChainOperandIndex, mkOperatorsChainLink } from '../utils';
import { generateEnjcTreeKeyNVTV } from '../../../enjc-value-view-ctx/utils-nvtv/generateEnjcTreeKeyNVTV';
import { IValueTreeViewContext } from '../../../enjc-value-view-ctx';

export const insertOperatorsChainLinkNVTV = (
  vtvCtx: IValueTreeViewContext,
  operatorsChain: IOperatorsChain,
  existingOperand: ValueTreeNode,
  operator: IFunctionBinaryOperator,
  createdOperand: ValueTreeNode,
  existingOperandRight: boolean,
): IOperatorsChain => {
  const createdOperatorNode = mkTreeFunction(
    generateEnjcTreeKeyNVTV(vtvCtx, [createdOperand]),
    operator.glyphPlaintext,
    operator.id,
    (existingOperandRight ? [createdOperand, existingOperand] : [createdOperand, existingOperand]).map(getNodeKey),
  );

  const existingOperandIndex = findOperatorsChainOperandIndex(operatorsChain, existingOperand);

  if (!existingOperandRight) {
    const chainLink = mkOperatorsChainLink(createdOperatorNode, createdOperand, operator);
    return {
      ...operatorsChain,
      body: [
        ...operatorsChain.body.slice(0, existingOperandIndex),
        chainLink,
        ...operatorsChain.body.slice(existingOperandIndex),
      ],
    };
  } else {
    const chainLink = mkOperatorsChainLink(createdOperatorNode, existingOperand, operator);
    if (existingOperandIndex === 0) {
      return {
        ...operatorsChain,
        head: createdOperand,
        body: [chainLink, ...operatorsChain.body],
      };
    } else {
      const modifiedChainLink: IOperatorsChainLink = {
        ...operatorsChain.body[existingOperandIndex - 1],
        operandNode: createdOperand,
      };
      return {
        ...operatorsChain,
        body: [
          ...operatorsChain.body.slice(0, existingOperandIndex - 1),
          modifiedChainLink,
          chainLink,
          ...operatorsChain.body.slice(existingOperandIndex),
        ],
      };
    }
  }
};
