import React, { ReactElement } from 'react';
import { TabPanel, TabPanels } from '@chakra-ui/react';

import { IEnjcItemTitled, IItemViewProps } from '../interfaces';
import { AppWelcome } from '../../../app/app-welcome';

interface IProps<TEnjcItem extends IEnjcItemTitled> {
  readonly items: ReadonlyArray<TEnjcItem>;
  readonly itemView: ({ item }: IItemViewProps<TEnjcItem>) => ReactElement;
}

const EnjcPanelsF = <TEnjcItem extends IEnjcItemTitled = IEnjcItemTitled>({
  items,
  itemView: EnjItemView,
}: IProps<TEnjcItem>): ReactElement => {
  return (
    <TabPanels
      bgColor="#F3F5FA"
      overflowY="scroll"
      h="calc(100vh - 35px)"
      mt="35px"
      borderTopWidth={1}
      borderTopColor="lightgray"
    >
      <TabPanel p="0px">
        <AppWelcome />
      </TabPanel>
      {items.map((item) => (
        <TabPanel key={item.id}>
          <EnjItemView item={item} />
        </TabPanel>
      ))}
    </TabPanels>
  );
};

export const EnjcPanels = EnjcPanelsF;
