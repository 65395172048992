const DRAFT_CHARS_OTHER = [' ', '"', '#', "'", '.', '_'];

export const isNavKeyDraftChar = (navKey: string): boolean => {
  return (
    navKey.length == 1 &&
    ((navKey >= '0' && navKey <= '9') ||
      (navKey >= 'a' && navKey <= 'z') ||
      (navKey >= 'A' && navKey <= 'Z') ||
      DRAFT_CHARS_OTHER.includes(navKey))
  );
};
