import { useSearchParams } from 'react-router-dom';
import React from 'react';

export interface ISheetsViewCtx {
  readonly showHidden: boolean;
}

const SEARCH_PARAMETER_KEY_OPEN = 'open';
const SEARCH_PARAMETER_KEY_ACTIVE = 'active';

export const useWorkspaceSheetsView = (): ISheetsViewCtx => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeDocumentId, openDocumentIds] = React.useMemo(
    () => [searchParams.get(SEARCH_PARAMETER_KEY_ACTIVE) || undefined, searchParams.getAll(SEARCH_PARAMETER_KEY_OPEN)],
    [searchParams],
  );

  // TODO: review and implement or remove
  return { showHidden: false };
};
