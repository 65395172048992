export type TGraphQlId = string;
export type TTimestamp = number;
export type EnjicalcId = TGraphQlId;
export type TUserId = EnjicalcId;
export type TWorkspaceId = EnjicalcId;
export type TSectionId = EnjicalcId;
export type TSymbolId = EnjicalcId;
export type TSectionItemId = TSectionId | TSymbolId;
export type TWorkspaceItemId = TSectionId | TSymbolId;
export type TEnjicalcEntityId = TWorkspaceId | TWorkspaceItemId;
export type TValueFunctionId = EnjicalcId;
export type TValueTreeNodeKey = string;

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: EnjicalcId; output: EnjicalcId };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Timestamp: { input: TTimestamp; output: TTimestamp };
  ValueTreeNodeKey: { input: TValueTreeNodeKey; output: TValueTreeNodeKey };
};

export enum ApiVersion {
  V01 = 'V01',
  V02 = 'V02',
  V03 = 'V03',
  V04 = 'V04',
  V05 = 'V05',
  V06 = 'V06',
  V07 = 'V07',
  V08 = 'V08',
  V09 = 'V09',
}

export type EnjcAllocatedId = {
  readonly __typename: 'EnjcAllocatedId';
  readonly id: Scalars['ID']['output'];
  readonly supplied: Scalars['String']['output'];
};

export type EnjcKeyListDeltaEntry = IEnjcOrderedDeltaEntry & {
  readonly __typename: 'EnjcKeyListDeltaEntry';
  readonly position: Scalars['Int']['output'];
  readonly sliceAfter: ReadonlyArray<Scalars['String']['output']>;
  readonly sliceAfterLength: Scalars['Int']['output'];
  readonly sliceBefore: ReadonlyArray<Scalars['String']['output']>;
  readonly sliceBeforeLength: Scalars['Int']['output'];
};

export type EnjcKeySetDelta = {
  readonly __typename: 'EnjcKeySetDelta';
  readonly subsetAfter: ReadonlyArray<Scalars['String']['output']>;
  readonly subsetAfterCount: Scalars['Int']['output'];
  readonly subsetBefore: ReadonlyArray<Scalars['String']['output']>;
  readonly subsetBeforeCount: Scalars['Int']['output'];
};

export type EnjcSection = IEnjcWorkspaceItem & {
  readonly __typename: 'EnjcSection';
  readonly created: Scalars['Timestamp']['output'];
  readonly id: Scalars['ID']['output'];
  readonly items: ReadonlyArray<UEnjcSectionItem>;
  readonly modified: Scalars['Timestamp']['output'];
  readonly originalId?: Maybe<Scalars['String']['output']>;
  readonly sheetAuxMeta?: Maybe<EnjcWorkspaceSheetAuxMeta>;
  readonly title: Scalars['String']['output'];
  readonly version: Scalars['Int']['output'];
  readonly visibility: EnjcWorkspaceItemVisibility;
};

export type EnjcStringDeltaEntry = IEnjcOrderedDeltaEntry & {
  readonly __typename: 'EnjcStringDeltaEntry';
  readonly position: Scalars['Int']['output'];
  readonly sliceAfter: Scalars['String']['output'];
  readonly sliceAfterLength: Scalars['Int']['output'];
  readonly sliceBefore: Scalars['String']['output'];
  readonly sliceBeforeLength: Scalars['Int']['output'];
};

export type EnjcSymbol = IEnjcMathIdentifier &
  IEnjcWorkspaceItem & {
    readonly __typename: 'EnjcSymbol';
    readonly aliases: ReadonlyArray<Scalars['String']['output']>;
    readonly comment: Scalars['String']['output'];
    readonly created: Scalars['Timestamp']['output'];
    readonly description: Scalars['String']['output'];
    readonly glyph: Scalars['String']['output'];
    readonly glyphPlaintext: Scalars['String']['output'];
    readonly id: Scalars['ID']['output'];
    readonly modified: Scalars['Timestamp']['output'];
    readonly originalId?: Maybe<Scalars['String']['output']>;
    readonly unit: Scalars['String']['output'];
    readonly valueTree: ValueTree;
    readonly version: Scalars['Int']['output'];
    readonly visibility: EnjcWorkspaceItemVisibility;
  };

export type EnjcUser = {
  readonly __typename: 'EnjcUser';
  readonly fullName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

export type EnjcWorkspace = IEnjcWorkspaceItem & {
  readonly __typename: 'EnjcWorkspace';
  readonly created: Scalars['Timestamp']['output'];
  readonly forkInfo?: Maybe<EnjcWorkspaceForkInfo>;
  readonly generation: Scalars['Int']['output'];
  readonly generationTimestamp: Scalars['Timestamp']['output'];
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['Timestamp']['output'];
  readonly owner: EnjcUser;
  readonly sections: ReadonlyArray<EnjcSection>;
  readonly symbols: ReadonlyArray<EnjcSymbol>;
  readonly title: Scalars['String']['output'];
  readonly version: Scalars['Int']['output'];
  readonly visibility: EnjcWorkspaceItemVisibility;
};

export type EnjcWorkspaceForkInfo = {
  readonly __typename: 'EnjcWorkspaceForkInfo';
  readonly generation: Scalars['Int']['output'];
  readonly workspaceId: Scalars['String']['output'];
};

export type EnjcWorkspaceInput = {
  readonly items: ReadonlyArray<EnjcWorkspaceUpdateInput>;
};

export enum EnjcWorkspaceItemVisibility {
  Deleted = 'DELETED',
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE',
}

export type EnjcWorkspaceMetaInput = {
  readonly title?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly visibility?: InputMaybe<EnjcWorkspaceItemVisibility>;
};

export type EnjcWorkspaceSectionInput = {
  readonly id: Scalars['String']['input'];
  readonly items?: InputMaybe<ReadonlyArray<KeyListDiffInput>>;
  readonly sheetAuxMeta?: InputMaybe<EnjcWorkspaceSheetAuxMetaInput>;
  readonly title?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly visibility?: InputMaybe<EnjcWorkspaceItemVisibility>;
};

export type EnjcWorkspaceSheetAuxMeta = {
  readonly __typename: 'EnjcWorkspaceSheetAuxMeta';
  readonly approvedDate?: Maybe<Scalars['Timestamp']['output']>;
  readonly approvedName: Scalars['String']['output'];
  readonly checkedDate?: Maybe<Scalars['Timestamp']['output']>;
  readonly checkedName: Scalars['String']['output'];
};

export type EnjcWorkspaceSheetAuxMetaInput = {
  readonly approvedDate?: InputMaybe<Scalars['String']['input']>;
  readonly approvedName?: InputMaybe<Scalars['String']['input']>;
  readonly checkedDate?: InputMaybe<Scalars['String']['input']>;
  readonly checkedName?: InputMaybe<Scalars['String']['input']>;
};

export type EnjcWorkspaceSymbolInput = {
  readonly comment?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly description?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly glyph?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly glyphPlaintext?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly id: Scalars['String']['input'];
  readonly unit?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly valueTree?: InputMaybe<ValueTreeInput>;
  readonly visibility?: InputMaybe<EnjcWorkspaceItemVisibility>;
};

export type EnjcWorkspaceUpdateInput = {
  readonly meta?: InputMaybe<ReadonlyArray<EnjcWorkspaceMetaInput>>;
  readonly section?: InputMaybe<ReadonlyArray<EnjcWorkspaceSectionInput>>;
  readonly symbol?: InputMaybe<ReadonlyArray<EnjcWorkspaceSymbolInput>>;
  readonly timestamp: Scalars['String']['input'];
  readonly title: Scalars['String']['input'];
};

export type EnjcWorkspaceUpdateResult = {
  readonly __typename: 'EnjcWorkspaceUpdateResult';
  readonly allocatedIds: ReadonlyArray<EnjcAllocatedId>;
  readonly sections: ReadonlyArray<EnjcSection>;
  readonly symbols: ReadonlyArray<EnjcSymbol>;
  readonly workspace: EnjcWorkspace;
};

export type IEnjcMathIdentifier = {
  readonly aliases: ReadonlyArray<Scalars['String']['output']>;
  readonly glyph: Scalars['String']['output'];
  readonly glyphPlaintext: Scalars['String']['output'];
};

export type IEnjcOrderedDeltaEntry = {
  readonly position: Scalars['Int']['output'];
  readonly sliceAfterLength: Scalars['Int']['output'];
  readonly sliceBeforeLength: Scalars['Int']['output'];
};

export type IEnjcWorkspaceItem = {
  readonly created: Scalars['Timestamp']['output'];
  readonly id: Scalars['ID']['output'];
  readonly modified: Scalars['Timestamp']['output'];
  readonly version: Scalars['Int']['output'];
  readonly visibility: EnjcWorkspaceItemVisibility;
};

export type KeyListDiffInput = {
  readonly count?: InputMaybe<Scalars['Int']['input']>;
  readonly position?: InputMaybe<Scalars['Int']['input']>;
  readonly value?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type KeySetDiffInput = {
  readonly add?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly clear?: InputMaybe<Scalars['Boolean']['input']>;
  readonly remove?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly createWorkspace: EnjcWorkspaceUpdateResult;
  readonly forkWorkspace: EnjcWorkspaceUpdateResult;
  readonly updateWorkspace: EnjcWorkspaceUpdateResult;
};

export type MutationCreateWorkspaceArgs = {
  diff?: InputMaybe<EnjcWorkspaceInput>;
};

export type MutationForkWorkspaceArgs = {
  diff?: InputMaybe<EnjcWorkspaceInput>;
  generation: Scalars['Int']['input'];
  workspace: Scalars['ID']['input'];
};

export type MutationUpdateWorkspaceArgs = {
  diff: EnjcWorkspaceInput;
  generation: Scalars['Int']['input'];
  workspace: Scalars['ID']['input'];
};

export type Query = {
  readonly __typename: 'Query';
  readonly apiVersion: ApiVersion;
  readonly functions: ReadonlyArray<ValueFunction>;
  readonly workspaceById: EnjcWorkspace;
  readonly workspaces: ReadonlyArray<EnjcWorkspace>;
};

export type QueryWorkspaceByIdArgs = {
  id: Scalars['ID']['input'];
};

export type StringDiffInput = {
  readonly count?: InputMaybe<Scalars['Int']['input']>;
  readonly position?: InputMaybe<Scalars['Int']['input']>;
  readonly value?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  readonly __typename: 'Subscription';
  readonly workspaceItemUpdate: UEnjcSectionItem;
};

export type SubscriptionWorkspaceItemUpdateArgs = {
  workspace: Scalars['ID']['input'];
};

export type UEnjcSectionItem = EnjcSection | EnjcSymbol;

export type UValueLiteral = ValueLiteralBoolean | ValueLiteralNumber | ValueLiteralString | ValueLiteralVoid;

export type ValueFunction = IEnjcMathIdentifier & {
  readonly __typename: 'ValueFunction';
  readonly aliases: ReadonlyArray<Scalars['String']['output']>;
  readonly defaultArgCount: Scalars['Int']['output'];
  readonly flagInternal: Scalars['Boolean']['output'];
  readonly glyph: Scalars['String']['output'];
  readonly glyphPlaintext: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

export type ValueLiteralBoolean = {
  readonly __typename: 'ValueLiteralBoolean';
  readonly bool: Scalars['Boolean']['output'];
};

export type ValueLiteralInput =
  | { readonly bool: Scalars['Boolean']['input']; readonly cause?: never; readonly numb?: never; readonly str?: never }
  | { readonly bool?: never; readonly cause: ValueLiteralVoidCause; readonly numb?: never; readonly str?: never }
  | { readonly bool?: never; readonly cause?: never; readonly numb: Scalars['Float']['input']; readonly str?: never }
  | { readonly bool?: never; readonly cause?: never; readonly numb?: never; readonly str: Scalars['String']['input'] };

export type ValueLiteralNumber = {
  readonly __typename: 'ValueLiteralNumber';
  readonly numb: Scalars['Float']['output'];
};

export type ValueLiteralString = {
  readonly __typename: 'ValueLiteralString';
  readonly str: Scalars['String']['output'];
};

export type ValueLiteralVoid = {
  readonly __typename: 'ValueLiteralVoid';
  readonly cause: ValueLiteralVoidCause;
};

export enum ValueLiteralVoidCause {
  Null = 'NULL',
}

export type ValueTree = {
  readonly __typename: 'ValueTree';
  readonly nodes: ReadonlyArray<ValueTreeNode>;
  readonly result: UValueLiteral;
  readonly rootNode?: Maybe<ValueTreeNode>;
};

export type ValueTreeInput = {
  readonly nodes?: InputMaybe<ReadonlyArray<ValueTreeNodeInput>>;
};

export type ValueTreeNode = {
  readonly __typename: 'ValueTreeNode';
  readonly arguments: ReadonlyArray<ValueTreeNode>;
  readonly draft: Scalars['String']['output'];
  readonly function?: Maybe<ValueFunction>;
  readonly key: Scalars['ValueTreeNodeKey']['output'];
  readonly literal: UValueLiteral;
  readonly mode: ValueTreeNodeMode;
  readonly result: UValueLiteral;
  readonly symbol?: Maybe<EnjcSymbol>;
  readonly tags: ReadonlyArray<Scalars['String']['output']>;
};

export type ValueTreeNodeInput = {
  readonly arguments?: InputMaybe<ReadonlyArray<KeyListDiffInput>>;
  readonly draft?: InputMaybe<ReadonlyArray<StringDiffInput>>;
  readonly function?: InputMaybe<Scalars['ID']['input']>;
  readonly key: Scalars['ValueTreeNodeKey']['input'];
  readonly literal?: InputMaybe<ValueLiteralInput>;
  readonly mode?: InputMaybe<ValueTreeNodeMode>;
  readonly remove?: InputMaybe<Scalars['Boolean']['input']>;
  readonly symbol?: InputMaybe<Scalars['ID']['input']>;
  readonly tags?: InputMaybe<KeySetDiffInput>;
};

export enum ValueTreeNodeMode {
  Function = 'FUNCTION',
  Literal = 'LITERAL',
  Symbol = 'SYMBOL',
}
