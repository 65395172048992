import React, { ReactElement } from 'react';
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EnjicalcSheetItem, isEnjcSection, isEnjcSymbol } from '../../../libenjc/enjc-workspace';
import { EnjicalcWorkspaceUpdateResult, WorkspaceEditHistoryEntry } from '../../../libenjc/enjc-workspace-editing';
import { useCtxEnjicalcWorkspace } from '../../../libenjc/enjc-react/enjc-react-context';
import { SymbolDocumentEditor } from '../symbol-document-editor';
import { SectionHeaderView } from './SectionHeaderView';
import { ESections } from 'src/components/editors/symbol-editor/SymbolEditor';

interface IProps {
  readonly index: number;
  readonly item: EnjicalcSheetItem;
  readonly modalSymbolId: string;
  readonly setModalSymbolId: (id: string, section?: ESections) => void;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateResult>;
}

const SheetDocumentEditorItemF = ({
  item,
  modalSymbolId,
  setModalSymbolId,
  performWorkspaceEdit,
  index,
}: IProps): ReactElement => {
  const { workspace } = useCtxEnjicalcWorkspace();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.itemData.id,
    animateLayoutChanges: defaultAnimateLayoutChanges,
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  return (
    <div ref={setNodeRef} style={{ position: 'relative', ...style }} {...attributes} aria-disabled={true}>
      {isEnjcSection(item.itemData) && (
        <SectionHeaderView
          index={index}
          section={item.itemData}
          performWorkspaceEdit={performWorkspaceEdit}
          dndListeners={listeners}
        />
      )}
      {isEnjcSymbol(item.itemData) && (
        <div>
          <SymbolDocumentEditor
            workspace={workspace}
            documentItemIndex={index}
            symbol={item.itemData}
            isSymbolModalOpen={modalSymbolId === item.itemData.id}
            setSymbolOpen={(state, section) => setModalSymbolId(state ? item.itemData.id : '', section)}
            dndListeners={listeners}
          />
        </div>
      )}
    </div>
  );
};

export const SheetDocumentEditorItem = React.memo(SheetDocumentEditorItemF);
