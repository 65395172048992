import { EnjcSectionFragment } from '../../enjicalc-graphql';
import { applyStringDelta, applyAtomDelta } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta } from '../model';
import { applySectionItemsDelta } from './applySectionItemsDelta';

export const applySectionDelta = (
  section: EnjcSectionFragment,
  delta: EnjcWorkspaceSectionDelta,
  getItemTypename: (id: string) => 'EnjcSymbol' | 'EnjcSection',
): EnjcSectionFragment => {
  return {
    ...section,
    title: delta.title ? applyStringDelta(section.title, delta.title) : section.title,
    items: delta.items ? applySectionItemsDelta(section.items, delta.items, getItemTypename) : section.items,
    visibility: delta.visibility ? applyAtomDelta(section.visibility, delta.visibility) : section.visibility,
  };
};
