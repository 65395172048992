import { EnjcWorkspaceItemVisibility, EnjicalcSymbol } from '../../enjc-workspace';
import { mkAtomDelta } from '../../enjc-delta';
import { EnjcWorkspaceSymbolDelta } from '../model';

export const changeSetSymbolVisibility = (
  existingSymbol: EnjicalcSymbol,
  visibility: EnjcWorkspaceItemVisibility,
): EnjcWorkspaceSymbolDelta | undefined => {
  return existingSymbol.visibility === visibility
    ? undefined
    : {
        id: existingSymbol.id,
        visibility: mkAtomDelta(existingSymbol.visibility, visibility),
      };
};
