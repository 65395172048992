import { EnjcSectionFragment, TSectionId } from '../../../libenjc/enjicalc-graphql';
import { EnjcWorkspaceDTO } from '../model';
import { getEnjcSectionOrUndefined } from './getEnjcSectionOrUndefined';

export const getEnjcSection = (enjicalcWorkspace: EnjcWorkspaceDTO, sectionId: TSectionId): EnjcSectionFragment => {
  const result = getEnjcSectionOrUndefined(enjicalcWorkspace, sectionId);
  if (result === undefined) {
    throw Error(`Section with id ${sectionId} not found`);
  }
  return result;
};
