import { ValueTreeNode } from '../../enjc-symbol-value-tree';
import { ValueTreeFragment } from '../../enjicalc-graphql';
import { TValueTreeNodeKey } from '../../enjc-symbol-value-tree/tree-node';
import { applyValueTreeNodeDelta } from './applyValueTreeNodeDelta';
import { mkLiteralVoidNull } from '../../enjc-literal';
import { EnjcValueTreeDelta } from '../model';
import { evaluateValueTree } from '../../enjc-value-math';
import { findValueTreeRootNode } from './findValueTreeRootNode';
import { EnjicalcWorkspace } from '../../enjc-workspace';

export const applyValueTreeDelta = (
  workspace: EnjicalcWorkspace,
  valueTree: ValueTreeFragment,
  delta: EnjcValueTreeDelta,
): ValueTreeFragment => {
  if (!delta || !delta.nodes) {
    return valueTree;
  }

  const modifiedNodes = delta.nodes.reduce(
    (acc, dNode) => {
      const currentNode = acc.get(dNode.key);
      const updatedNode = applyValueTreeNodeDelta(currentNode, dNode);
      if (updatedNode) {
        acc.set(dNode.key, updatedNode);
      } else {
        acc.delete(dNode.key);
      }
      return acc;
    },
    new Map<TValueTreeNodeKey, ValueTreeNode>(valueTree.nodes.map((n) => [n.key, n])),
  );

  const nextNodes = [...modifiedNodes.values()];
  const nextRootNode = findValueTreeRootNode(nextNodes);

  return evaluateValueTree(workspace, {
    __typename: 'ValueTree',
    rootNode: nextRootNode === undefined ? undefined : { __typename: 'ValueTreeNode', key: nextRootNode.key },
    nodes: nextNodes,
    result: mkLiteralVoidNull(),
  });
};
