import React, { ReactElement } from 'react';
import { MathMathMLAttributes } from '../../../libenjc/enjc-react-mathml/attributes';
import { EnjcGlyphMathmlAdapterView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-glyph-mathml-view';
import { defaultMathBlockProps } from '../../../config/mathml-props';

interface IProps extends MathMathMLAttributes {
  // TODO: review type
  readonly glyph: string;
  readonly mathProps?: MathMathMLAttributes;
}

export const EnjcGlyphMathBlockView = ({ glyph, mathProps, ...props }: IProps): ReactElement => {
  return (
    <math {...defaultMathBlockProps} {...mathProps}>
      <EnjcGlyphMathmlAdapterView glyphAsciiMathStr={glyph} {...props} />
    </math>
  );
};
