import { findWorkspaceItemContainers, getEnjcSection, TSectionId } from '../../enjc-workspace';
import { EnjcWorkspaceSectionDelta, WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry, changeMarkSectionHidden } from '../utils';
import { EnjcWorkspaceDTO } from '../../enjc-workspace/model';

export const editHideSection = (workspace: EnjcWorkspaceDTO, sectionId: TSectionId): WorkspaceEditHistoryEntry => {
  const existingSection = getEnjcSection(workspace, sectionId);
  const sectionContainers = findWorkspaceItemContainers(workspace, sectionId);
  // TODO: group by container id
  const containerDeltas: EnjcWorkspaceSectionDelta[] = sectionContainers.map((iCt) => {
    return {
      id: iCt.container.id,
      items: [
        {
          slicePosition: iCt.index,
          sliceBefore: [iCt.container.items[iCt.index].id],
          sliceAfter: existingSection.items.map((seIt) => seIt.id),
        },
      ],
    };
  });
  const sectionDelta = changeMarkSectionHidden(existingSection);
  const sectionDeltas = [...containerDeltas, ...(sectionDelta ? [sectionDelta] : [])];
  return mkWorkspaceEditHistoryEntry('editMarkSectionHidden', {
    section: sectionDeltas.length > 0 ? sectionDeltas : undefined,
  });
};
