import { EnjcWorkspaceDelta } from '../model';
import { invertSectionDelta } from './invertSectionDelta';
import { invertSymbolDelta } from './invertSymbolDelta';
import { invertWorkspaceMetaDelta } from './invertWorkspaceMetaDelta';

export const invertWorkspaceDelta = (delta: EnjcWorkspaceDelta): EnjcWorkspaceDelta => {
  return {
    meta: delta.meta && [...delta.meta].reverse().map((d) => invertWorkspaceMetaDelta(d)),
    section: delta.section && [...delta.section].reverse().map((d) => invertSectionDelta(d)),
    symbol: delta.symbol && [...delta.symbol].reverse().map((d) => invertSymbolDelta(d)),
  };
};
