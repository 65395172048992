import React, { ReactElement } from 'react';
import { isLiteralVoid, valueLiteralToString } from '../../../../enjc-literal';
import { isCursorNodeAtEnd, isCursorNodeAtStart } from '../../../../enjc-symbol-value-tree/tree-cursor';
import { ISymbolValueLiteralViewProps } from '../../enjc-symbol-value-composite-view/props';
import { SymbolValuePlainViewContainer } from './SymbolValuePlainViewContainer';
import { SymbolValuePlainCursor } from './SymbolValuePlainCursor';

export const SymbolValueLiteralPlainView = ({
  literal,
  cursor,
  onClick,
  onHintClick,
}: ISymbolValueLiteralViewProps): ReactElement => {
  const isCursorAtStartEnd = isCursorNodeAtStart(cursor) || isCursorNodeAtEnd(cursor);
  return (
    <SymbolValuePlainViewContainer onClick={onClick}>
      [
      {isLiteralVoid(literal) ? (
        <>
          <span style={isCursorAtStartEnd ? { background: 'black', color: 'white' } : undefined}>{'▢'}</span>
          {isCursorAtStartEnd && <SymbolValuePlainCursor qfixHideCursor onHintClick={onHintClick} />}
        </>
      ) : (
        <>
          {isCursorNodeAtStart(cursor) && <SymbolValuePlainCursor />}
          <span>{valueLiteralToString(literal)}</span>
          {isCursorNodeAtEnd(cursor) && <SymbolValuePlainCursor />}
        </>
      )}
      ]
    </SymbolValuePlainViewContainer>
  );
};
