import { assertNever } from '../../../../utils';
import { ValueTreeClientDelta } from '../../tree-editing';
import { IValueTreeViewContext, getCursorPositionNode } from '../../../enjc-value-view-ctx';
import { isTreePositionNode, isTreePositionNodeDraft } from '../../tree-position';
import { addFunctionArgumentAtNVTV } from '../../tree-navigation/actions-nvtv';

export const handleTreeKeyComma = (vtvCtx: IValueTreeViewContext): ValueTreeClientDelta => {
  const { cursorPosition, positionNode } = getCursorPositionNode(vtvCtx)!;

  if (isTreePositionNode(cursorPosition)) {
    return addFunctionArgumentAtNVTV(vtvCtx, positionNode, true); // cursorPosition.atEnd
  }

  if (isTreePositionNodeDraft(cursorPosition)) {
    return addFunctionArgumentAtNVTV(vtvCtx, positionNode, true); // cursorPosition.atEnd
    // TODO: make in configurable
    //return spliceNodeDraftNVTV(vtvCtx, positionNode, cursorPosition.at, 0, ',');
  }

  return assertNever(cursorPosition);
};
