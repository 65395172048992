import { EnjicalcWorkspace } from '../../enjc-workspace';
import { EnjcWorkspaceMetaDelta } from '../model';
import { applyStringDelta } from '../../enjc-delta';

export const applyWorkspaceMetaDelta = (
  workspace: EnjicalcWorkspace,
  delta: EnjcWorkspaceMetaDelta,
): EnjicalcWorkspace => {
  return {
    ...workspace,
    title: delta.title ? applyStringDelta(workspace.title, delta.title) : workspace.title,
    visibility: delta.visibility?.atomAfter ?? workspace.visibility,
  };
};
