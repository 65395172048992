import { EnjicalcSheet, EnjicalcWorkspace, isEnjcSectionRef } from '../../enjc-workspace';
import { EnjcWorkspaceUpdateInput, EnjcWorkspaceSectionInput, EnjcWorkspaceSymbolInput } from '../../enjicalc-graphql';
import { tmpPatchEnjcWorkspaceUpdateInput } from '../../enjc-client/utils';
import { parseSheetUpdateJson } from './parseSheetUpdateJson';
import { convertUpdateImportTreeNode } from './convertUpdateImportTreeNode';

export const convertSheetUpdate = (
  workspace: EnjicalcWorkspace,
  sheet: EnjicalcSheet,
  updateStr: string,
): EnjcWorkspaceUpdateInput => {
  const parsedUpdate = parseSheetUpdateJson(updateStr);

  const updateSymbols = parsedUpdate.sections.flatMap((se) => se.symbols);
  const symbolsInput = updateSymbols.map((sy) => {
    const existingSymbol = workspace.symbols.find((wSy) => wSy.originalId === 'lcr_' + sy.varname);

    return {
      id: existingSymbol?.id ?? 'lcr_' + sy.varname,
      glyph: existingSymbol?.glyph === sy.glyph ? undefined : [{ value: sy.glyph }],
      glyphPlaintext: existingSymbol?.glyphPlaintext === sy.varname ? undefined : [{ value: sy.varname }],
      valueTree: {
        nodes: [
          ...(existingSymbol?.valueTree?.nodes
            ?.filter((n) => sy.valueTree.find((inSyNd) => inSyNd.key === n.key) === undefined)
            ?.map((n) => ({
              key: n.key,
              remove: true,
            })) ?? []),
          ...sy.valueTree.map((n) => convertUpdateImportTreeNode(workspace, n)),
        ],
      },
      unit: existingSymbol?.unit === sy.unit ? undefined : [{ value: sy.unit }],
      comment: existingSymbol?.comment === sy.comment ? undefined : [{ value: sy.comment }],
      description: existingSymbol?.description === sy.description ? undefined : [{ value: sy.description }],
    } as EnjcWorkspaceSymbolInput;
  });

  const existingSections = sheet.items.filter(isEnjcSectionRef);
  const sectionsInput = parsedUpdate.sections.map((se, index) => {
    const existingSection =
      index < existingSections.length
        ? workspace.sections.find((wSe) => wSe.id === existingSections[index].id)
        : undefined;
    return {
      id: existingSection?.id ?? 'lcr_' + `section${index}`,
      title: existingSection?.title === se.title ? undefined : [{ value: se.title }],
      items: [
        {
          value: se.symbols.map((sy) => {
            const existingSymbol = workspace.symbols.find((wSy) => wSy.originalId === 'lcr_' + sy.varname);
            return existingSymbol?.id ?? 'lcr_' + sy.varname;
          }),
        },
      ],
    } as EnjcWorkspaceSectionInput;
  });

  const sheetInput: EnjcWorkspaceSectionInput = {
    id: sheet.id,
    title: sheet.title === parsedUpdate.title ? undefined : [{ value: parsedUpdate.title }],
    // items: [{ value: symbolsInput.map((syInput) => syInput.id) }],
    items: [{ value: sectionsInput.map((seInput) => seInput.id) }],
  };

  return tmpPatchEnjcWorkspaceUpdateInput({
    title: 'convertSheetUpdate',
    timestamp: Date.now(),
    symbol: symbolsInput,
    section: [...sectionsInput, sheetInput],
  });
};
