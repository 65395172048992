import { EnjcWorkspaceItemVisibility, EnjicalcSection } from '../../enjc-workspace';
import { mkAtomDelta } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta } from '../model';

export const changeMarkSectionHidden = (existingSection: EnjicalcSection): EnjcWorkspaceSectionDelta | undefined => {
  return existingSection.visibility === EnjcWorkspaceItemVisibility.Hidden
    ? undefined
    : {
        id: existingSection.id,
        visibility: mkAtomDelta(existingSection.visibility, EnjcWorkspaceItemVisibility.Hidden),
      };
};
