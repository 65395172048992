import { stringDeltaToInput } from '../../enjc-delta';
import { EnjcWorkspaceMetaDelta } from '../../enjc-workspace-editing';
import { EnjcWorkspaceMetaInput } from '../../enjicalc-graphql';

export const convertWorkspaceMetaDeltaToDiffInput = (delta: EnjcWorkspaceMetaDelta): EnjcWorkspaceMetaInput => {
  return {
    visibility: delta.visibility?.atomAfter,
    title: delta.title && delta.title.map((d) => stringDeltaToInput(d, false)),
  };
};
