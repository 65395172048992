import React from 'react';
import { useReactToPrint } from 'react-to-print';

interface IHook {
  ref: React.MutableRefObject<any>;
  print: () => void;
}

export const usePrint = (documentTitle: string): IHook => {
  const ref = React.useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle,
  });

  return {
    ref,
    print: handlePrint,
  };
};
