import { EnjcWorkspaceSymbolDelta } from '../model';
import { invertAtomDelta, invertStringDelta } from '../../enjc-delta';
import { invertValueTreeDelta } from './invertValueTreeDelta';

export const invertSymbolDelta = (delta: EnjcWorkspaceSymbolDelta): EnjcWorkspaceSymbolDelta => {
  return {
    id: delta.id,
    glyph: delta.glyph && invertStringDelta(delta.glyph),
    glyphPlaintext: delta.glyphPlaintext && invertStringDelta(delta.glyphPlaintext),
    unit: delta.unit && invertStringDelta(delta.unit),
    comment: delta.comment && invertStringDelta(delta.comment),
    description: delta.description && invertStringDelta(delta.description),
    visibility: delta.visibility && invertAtomDelta(delta.visibility),
    valueTree: delta.valueTree && invertValueTreeDelta(delta.valueTree),
  };
};
