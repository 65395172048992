import React, { ReactElement } from 'react';
import { isCursorNodeAtEnd, isCursorNodeAtStart } from '../../../../enjc-symbol-value-tree/tree-cursor';
import { ISymbolValueGroupViewProps } from '../../enjc-symbol-value-composite-view/props';
import { SymbolValuePlainViewContainer } from './SymbolValuePlainViewContainer';
import { SymbolValuePlainCursor } from './SymbolValuePlainCursor';

export const SymbolValueGroupPlainView = ({ prefix, children, cursor }: ISymbolValueGroupViewProps): ReactElement => {
  return (
    <SymbolValuePlainViewContainer>
      {isCursorNodeAtStart(cursor) && <SymbolValuePlainCursor />}
      <span>{prefix}</span>
      {children.length > 0 && (
        <>
          <span>{'('}</span>
          {children.flatMap((rn, index, arr) => (index < arr.length - 1 ? [rn, <span>{', '}</span>] : [rn]))}
          <span>{')'}</span>
        </>
      )}
      {isCursorNodeAtEnd(cursor) && <SymbolValuePlainCursor />}
    </SymbolValuePlainViewContainer>
  );
};
