import { ValueTreeNode } from '../../tree-node';
import { INodeParentInfoWithOperator } from '../../symbol-value-tree-review';
import { checkExpressionOperandImplicitParenthesis } from '../utils/checkExpressionOperandImplicitParenthesis';
import { getNodeParentInfoWithOperatorNVTV } from '../../../enjc-value-view-ctx/utils-nvtv/getNodeParentInfoWithOperatorNVTV';
import { IValueTreeViewContext } from '../../../enjc-value-view-ctx';

export const findOperatorsChainRootNVTV = (vtvCtx: IValueTreeViewContext, node: ValueTreeNode): ValueTreeNode => {
  const parentInfo = getNodeParentInfoWithOperatorNVTV(vtvCtx, node);

  // if (!isNodeRoot(parentInfo) && parentInfo.operator) {
  //   // const operandHasImplicitParenthesis = checkExpressionOperandImplicitParenthesis();
  //   const a = parentInfo.index;
  // }

  if (isNodeRoot(parentInfo)) {
    return node;
  }

  if (parentInfo && parentInfo.operator) {
    // Current node is operand
    const operandHasImplicitParenthesis = checkExpressionOperandImplicitParenthesis(node, parentInfo);
  }

  // FIXME: take into account implicit parenthesis
  return !parentInfo?.operator ? node : findOperatorsChainRootNVTV(vtvCtx, parentInfo.node);
};

export const isNodeRoot = (parentInfo: INodeParentInfoWithOperator | undefined): parentInfo is undefined => !parentInfo;
