import React, { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { EnjcSectionFragment } from '../../../libenjc/enjicalc-graphql';
import { SheetHeaderField } from './SheetHeaderField';
import { useUser } from '@clerk/clerk-react';
import { SheetHeaderDateField } from './SheetHeaderDateField';
import { enjcTimestampToDate } from '../../../utils';

interface IProps {
  readonly workspaceTitle: string;
  readonly sheet: EnjcSectionFragment;
  readonly setSheetCheckedName?: (value: string) => void;
  readonly setSheetApprovedName?: (value: string) => void;
  readonly setCheckedDate?: (value: Date | null) => void;
  readonly setApprovedDate?: (value: Date | null) => void;
}

const SheetHeaderViewF = ({
  workspaceTitle,
  sheet,
  setSheetCheckedName,
  setSheetApprovedName,
  setCheckedDate,
  setApprovedDate,
}: IProps): ReactElement => {
  const { user } = useUser();

  return (
    <Box pb="20px">
      <Flex h="fit-content" borderWidth="1px 0px" borderColor="lightgray">
        <Box w="50%">
          <SheetHeaderField name="Title" text={sheet.title} />
          <SheetHeaderField name="Workspace" text={workspaceTitle} />
          <SheetHeaderField name="Author" text={user?.fullName ? user?.fullName : 'Unknown'} />
          <SheetHeaderDateField name="Date" date={enjcTimestampToDate(sheet.modified)} />
        </Box>

        <Box w="50%" pl="5px">
          <SheetHeaderField
            name="Checked by"
            text={sheet.sheetAuxMeta?.checkedName || 'Not Checked'}
            onSubmit={setSheetCheckedName}
          />
          <SheetHeaderDateField
            name="Checked date"
            date={sheet.sheetAuxMeta?.checkedDate ? new Date(sheet.sheetAuxMeta.checkedDate) : null}
            onSubmit={setCheckedDate}
          />
          <SheetHeaderField
            name="Approved by"
            text={sheet.sheetAuxMeta?.approvedName || 'Not approved'}
            onSubmit={setSheetApprovedName}
          />
          <SheetHeaderDateField
            name="Approved date"
            date={sheet.sheetAuxMeta?.approvedDate ? new Date(sheet.sheetAuxMeta.approvedDate) : null}
            onSubmit={setApprovedDate}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export const SheetHeaderView = React.memo(SheetHeaderViewF);
