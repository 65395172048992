import { assertNever } from '../../../../utils';
import { isTreeNodeLiteral, ValueTreeNode } from '../../tree-node';
import { isLiteralVoid } from '../../../enjc-literal';
import { UValueTreePosition } from '../types';
import { isTreePositionNode } from './isTreePositionNode';
import { isTreePositionNodeDraft } from './isTreePositionNodeDraft';

export const isValueTreePositionAtStartEnd = (
  node: ValueTreeNode,
  position: UValueTreePosition,
  atEnd: boolean,
): boolean => {
  if (
    isTreePositionNode(position) &&
    isTreeNodeLiteral(node) &&
    isLiteralVoid(node.literal) &&
    node.draft.length === 0
  ) {
    return true;
  }
  if (isTreePositionNode(position)) {
    return position.atEnd === atEnd;
  }
  if (isTreePositionNodeDraft(position)) {
    return atEnd ? position.at === node.draft.length : position.at === 0;
  }
  return assertNever(position);
};
