import React, { ReactElement } from 'react';
import { EnjcGlyphDivision } from '../../../enjc-glyph/model';
import { EnjcGlyphSubSupPlainView } from './EnjcGlyphSubSupPlainView';

interface IProps {
  readonly glyphDivision: EnjcGlyphDivision;
}

export const EnjcGlyphDivisionPlainView = ({ glyphDivision }: IProps): ReactElement => {
  return (
    <>
      <EnjcGlyphSubSupPlainView glyphSubSup={glyphDivision.numerator} />
      {'/'}
      <EnjcGlyphSubSupPlainView glyphSubSup={glyphDivision.denominator} />
    </>
  );
};
