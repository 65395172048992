import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { WorkspaceContextLoader } from '../../libenjc/enjc-react/enjc-react-context';
import { ErrorMessage } from '../../components/errors';

export const WorkspaceRoute = () => {
  const { workspaceId } = useParams<{ workspaceId: string }>();

  if (!workspaceId) {
    return <ErrorMessage title={'WorkspaceRoute Error'} description={'undefined workspaceId'} />;
  }

  return (
    <WorkspaceContextLoader workspaceId={workspaceId}>
      <Outlet />
    </WorkspaceContextLoader>
  );
};
