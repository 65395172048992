import React from 'react';
import { EnjicalcWorkspace } from '../../../libenjc/enjc-workspace';
import {
  applyWorkspaceDelta,
  WorkspaceEditHistory,
  WorkspaceEditHistoryEntry,
  invertWorkspaceDelta,
} from '../../../libenjc/enjc-workspace-editing';
import { IWorkspaceEditHistoryConstructor } from './IWorkspaceEditHistoryConstructor';

export const useWorkspaceEditHistoryConstructor = (workspace: EnjicalcWorkspace): IWorkspaceEditHistoryConstructor => {
  const [currentWorkspace, setCurrentWorkspace] = React.useState(workspace);
  const [historyEntries, setHistoryEntries] = React.useState<WorkspaceEditHistoryEntry[]>([]);
  const [historyPosition, setHistoryPosition] = React.useState(0);

  const performUndo = React.useCallback(() => {
    if (historyPosition > 0) {
      // Apply undo input
      setCurrentWorkspace(
        applyWorkspaceDelta(currentWorkspace, invertWorkspaceDelta(historyEntries[historyPosition - 1].delta)),
      );
      // Move position pointer backward
      setHistoryPosition(historyPosition - 1);
    }
  }, [currentWorkspace, historyEntries, historyPosition]);

  const performRedo = React.useCallback(() => {
    if (historyPosition < historyEntries.length) {
      // Apply redo input
      setCurrentWorkspace(applyWorkspaceDelta(currentWorkspace, historyEntries[historyPosition].delta));
      // Move position pointer forward
      setHistoryPosition(historyPosition + 1);
    }
  }, [currentWorkspace, historyEntries, historyPosition]);

  const performEdit = React.useCallback(
    (editEntry: WorkspaceEditHistoryEntry) => {
      // Calculate undo input
      // * Clear remaining redo items (if any)
      // * Add entry to the end of `editHistory`
      // * Apply [redo] input to workspace
      // * Move position pointer forward
      setHistoryEntries([...historyEntries.slice(0, historyPosition), editEntry]);
      setCurrentWorkspace(applyWorkspaceDelta(currentWorkspace, editEntry.delta));
      setHistoryPosition(historyPosition + 1);
      //performRedo();
    },
    [currentWorkspace, historyEntries, historyPosition],
  );

  const performReset = React.useCallback(
    (keepRedoHistory: boolean) => {
      setHistoryEntries(keepRedoHistory ? historyEntries.slice(historyPosition) : []);
      setHistoryPosition(0);
    },
    [historyEntries, historyPosition],
  );

  const workspaceEditHistory = React.useMemo<WorkspaceEditHistory>(() => {
    return {
      currentState: currentWorkspace,
      historyEntries,
      historyPosition,
    };
  }, [currentWorkspace, historyEntries, historyPosition]);

  return {
    workspaceEditHistory,
    performEdit,
    performUndo,
    performRedo,
    performReset,
  };
};
