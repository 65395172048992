import { TValueFunctionId } from '../../../enjc-value-math';
import { TNavKeyOperator } from '../types';
import { assertNever } from '../../../../utils';

export const operatorKeyToFunctionId = (operatorKey: TNavKeyOperator): TValueFunctionId => {
  switch (operatorKey) {
    case '+':
      return 'fg.add';
    case '-':
      return 'fg.subtract';
    case '*':
      return 'fg.multiply';
    case '/':
      return 'fg.divide';
    case '^':
      return 'fg.pow';
    case '=':
      return 'fg.eq';
    case '!':
      return 'fg.ne';
    case '>':
      return 'fg.gt';
    case '<':
      return 'fg.lt';
    // case '&':
    //   return 'fg.and';
    // case '|':
    //   return 'fg.or';
    default:
      return assertNever(operatorKey);
  }
};
