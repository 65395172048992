import { assertNever } from '../../../utils';
import { EnjcSectionFragment, EnjcSymbolFragment } from '../../../libenjc/enjicalc-graphql';
import { EnjcWorkspaceDTO } from '../model';
import { isEnjcSectionRef } from './isEnjcSectionRef';
import { isEnjcSymbolRef } from './isEnjcSymbolRef';
import { getEnjcSection } from './getEnjcSection';
import { getEnjcSymbol } from './getEnjcSymbol';

type TEnjcSectionItemFragment = EnjcSectionFragment | EnjcSymbolFragment;

export const collectSectionItems = (
  workspace: EnjcWorkspaceDTO,
  section: EnjcSectionFragment,
): ReadonlyArray<TEnjcSectionItemFragment> =>
  section.items.flatMap((sectionItem) => {
    if (isEnjcSectionRef(sectionItem)) {
      const currentSection = getEnjcSection(workspace, sectionItem.id);
      if (!currentSection) throw Error(`EnjcSection with id ${sectionItem.id} not found`);
      return [currentSection, ...collectSectionItems(workspace, currentSection)];
    } else if (isEnjcSymbolRef(sectionItem)) {
      const currentSymbol = getEnjcSymbol(workspace, sectionItem.id);
      if (!currentSymbol) throw Error(`EnjcSymbol with id ${sectionItem.id} not found`);
      return [currentSymbol];
    } else {
      return assertNever(sectionItem);
    }
  });
