import { IValueTreeViewContext, collectTreeNodeDescendantsNVTV } from '../../../enjc-value-view-ctx';
import { isTreeNodeFunction, ValueTreeNode } from '../../tree-node';
import { EnjcValueFunction } from '../../../enjc-value-math';
import { createPlaceholderNodeNVTV } from './createPlaceholderNodeNVTV';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { mkTreeCursorNode } from '../../tree-cursor';
import { ValueTreeNodeMode } from '../../../../generated/graphql/types';
import { ValueTreeClientDelta } from '../model';
import { mkNodeRemovalDelta } from './mkNodeRemovalDelta';
import { calculateNodeDeltaNVTV } from './calculateNodeDeltaNVTV';

export const changeTreeNodeFunction = (
  vtvCtx: IValueTreeViewContext,
  node: ValueTreeNode,
  funcSpec: EnjcValueFunction,
): ValueTreeClientDelta => {
  if (isTreeNodeFunction(node)) {
    const placeholderNodes =
      node.arguments.length >= funcSpec.defaultArgCount
        ? []
        : [...new Array(funcSpec.defaultArgCount - node.arguments.length)].reduce(
            (acc: ValueTreeNode[]) => [...acc, createPlaceholderNodeNVTV(vtvCtx, acc)],
            [],
          );

    const positionNodeDelta: EnjcValueTreeNodeDelta = {
      key: node.key,
      function: { atomBefore: node.function?.id, atomAfter: funcSpec.id },
      arguments:
        placeholderNodes.length === 0
          ? undefined
          : [
              {
                slicePosition: node.arguments.length,
                sliceAfter: placeholderNodes.map((n) => n.key),
              },
            ],
    };

    const nodesDelta = [...placeholderNodes.map((nUp) => calculateNodeDeltaNVTV(vtvCtx, nUp)), positionNodeDelta];
    return {
      delta: { nodes: nodesDelta },
      cursor:
        node.arguments.length + placeholderNodes.length > 0
          ? mkTreeCursorNode(node.arguments.length > 0 ? node.arguments[0].key : placeholderNodes[0].key, false)
          : mkTreeCursorNode(node.key, true),
    };
  } else {
    const placeholderNodes = [...new Array(funcSpec.defaultArgCount)].reduce(
      (acc: ValueTreeNode[]) => [...acc, createPlaceholderNodeNVTV(vtvCtx, acc)],
      [],
    );
    const positionNodeDelta: EnjcValueTreeNodeDelta = {
      key: node.key,
      mode: { atomBefore: node.mode, atomAfter: ValueTreeNodeMode.Function },
      function: { atomBefore: node.function?.id, atomAfter: funcSpec.id },
      arguments: [
        {
          slicePosition: undefined,
          sliceBefore: node.arguments.length > 0 ? node.arguments.map((a) => a.key) : undefined,
          sliceAfter: placeholderNodes.length > 0 ? placeholderNodes.map((n) => n.key) : undefined,
        },
      ],
    };
    const nodesDelete = collectTreeNodeDescendantsNVTV(vtvCtx, node, false);
    const nodesDelta = [
      ...placeholderNodes.map((nUp) => calculateNodeDeltaNVTV(vtvCtx, nUp)),
      positionNodeDelta,
      ...nodesDelete.map((n) => mkNodeRemovalDelta(n)),
    ];
    return {
      delta: { nodes: nodesDelta },
      cursor:
        placeholderNodes.length > 0
          ? mkTreeCursorNode(placeholderNodes[0].key, false)
          : mkTreeCursorNode(node.key, true),
    };
  }
};
