export enum EOperatorPriority {
  Comma,
  Ternary,
  LogicalOr,
  LogicalAnd,
  Equality,
  Compare,
  AddSubtract,
  MultiplyDivide,
  Exponentiation,
  Uminus,
}
