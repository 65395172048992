import { assertNever } from '../../../utils';
import { mkTreeLiteralVoid, ValueTreeNode } from '../../enjc-symbol-value-tree/tree-node';
import {
  isTreePositionNode,
  isTreePositionNodeDraft,
  UValueTreePosition,
} from '../../enjc-symbol-value-tree/tree-position';
import { generateValueTreeNodeKey } from '../../enjc-symbol-value-tree/tree-editing';
import { updateNodeDraft } from '../../enjc-symbol-value-tree/tree-navigation/utils/updateNodeDraft';
import { IValueTreeViewContext } from '../model';

export const prepareNewOperatorOperandsNVTV = (
  vtvCtx: IValueTreeViewContext,
  positionNode: ValueTreeNode,
  position: UValueTreePosition,
  existingOperandRight: boolean,
): [ValueTreeNode, ValueTreeNode] => {
  const otherOperandKey = generateValueTreeNodeKey(vtvCtx.symbol.valueTree.nodes.map((n) => n.key));

  if (isTreePositionNode(position)) {
    const existingOperand = positionNode;
    const otherOperand = mkTreeLiteralVoid(otherOperandKey, '');

    return [existingOperand, otherOperand];
  }

  if (isTreePositionNodeDraft(position)) {
    const draftLeftSlice = positionNode.draft.slice(0, position.at);
    const draftRightSlice = positionNode.draft.slice(position.at);

    const existingOperand = updateNodeDraft(positionNode, existingOperandRight ? draftRightSlice : draftLeftSlice);
    const otherOperand = updateNodeDraft(
      mkTreeLiteralVoid(otherOperandKey, ''),
      existingOperandRight ? draftLeftSlice : draftRightSlice,
    );

    return [existingOperand, otherOperand];
  }

  return assertNever(position);
};
