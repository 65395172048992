import { TValueFunctionId } from '../../enjc-value-math';
import { EOperatorAssociativity, EOperatorPriority } from '../enums';
import { IFunctionBinaryOperator } from '../interfaces';
import { mkFunctionBinaryOperator } from './mkFunctionBinaryOperator';

export const binaryOperatorsMap = new Map<TValueFunctionId, IFunctionBinaryOperator>(
  [
    mkFunctionBinaryOperator('fg.add', '+', '+', EOperatorPriority.AddSubtract),
    mkFunctionBinaryOperator('fg.subtract', '-', '-', EOperatorPriority.AddSubtract),
    // '×' \u00D7
    // '⋅' \u22C5
    mkFunctionBinaryOperator('fg.multiply', '\u22C5', '*', EOperatorPriority.MultiplyDivide),
    // '÷' \u00F7
    mkFunctionBinaryOperator('fg.divide', '\u00F7', '/', EOperatorPriority.MultiplyDivide),

    // FIXME: review (is it operator?)
    mkFunctionBinaryOperator('fg.pow', '^', '**', EOperatorPriority.Exponentiation, EOperatorAssociativity.Right),

    mkFunctionBinaryOperator('fg.eq', '=', '==', EOperatorPriority.Equality),
    // '≠' \u2260
    mkFunctionBinaryOperator('fg.ne', '\u2260', '!=', EOperatorPriority.Equality),
    mkFunctionBinaryOperator('fg.lt', '<', '<', EOperatorPriority.Compare),
    // '≤' \u2264
    mkFunctionBinaryOperator('fg.le', '\u2264', '<=', EOperatorPriority.Compare),
    mkFunctionBinaryOperator('fg.gt', '>', '>', EOperatorPriority.Compare),
    // '≥' \u2265
    mkFunctionBinaryOperator('fg.ge', '\u2265', '>=', EOperatorPriority.Compare),
    // '∧' \u2227
    mkFunctionBinaryOperator('fg.and', '\u2227', '&&', EOperatorPriority.LogicalAnd),
    // '∨' \u2228
    mkFunctionBinaryOperator('fg.or', '\u2228', '||', EOperatorPriority.LogicalOr),
    // '⊻' \u22BB
    // mkFunctionBinaryOperator('fg.xor', '\u22BB ', '^', EOperatorPriority.X),

    // NOTE: Conditional / Ternary operator has right-to-left associativity
  ].map((operator) => [operator.id, operator]),
);

export const getFunctionBinaryOperatorById = (functionId: TValueFunctionId): IFunctionBinaryOperator | undefined =>
  binaryOperatorsMap.get(functionId);
