import { assertNever } from '../../../utils';
import {
  isLiteralBoolean,
  isLiteralNumber,
  isLiteralString,
  isLiteralVoid,
  UValueLiteral,
  ValueLiteralVoidCause,
} from '../../enjc-literal';
import { ValueLiteralInput } from '../../enjicalc-graphql';

export const literalToInput = (literal: UValueLiteral): ValueLiteralInput => {
  if (isLiteralVoid(literal)) {
    return { cause: literal?.cause ?? ValueLiteralVoidCause.Null };
  } else if (isLiteralBoolean(literal)) {
    return { bool: literal.bool };
  } else if (isLiteralNumber(literal)) {
    return { numb: literal.numb };
  } else if (isLiteralString(literal)) {
    return { str: literal.str };
  } else {
    return assertNever(literal);
  }
};
