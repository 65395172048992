import { EnjcWorkspaceMetaDelta } from '../model';
import { invertAtomDelta, invertStringDelta } from '../../enjc-delta';

export const invertWorkspaceMetaDelta = (delta: EnjcWorkspaceMetaDelta): EnjcWorkspaceMetaDelta => {
  return {
    id: delta.id,
    title: delta.title && invertStringDelta(delta.title),
    visibility: delta.visibility && invertAtomDelta(delta.visibility),
  };
};
