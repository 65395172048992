import { xxHash32 } from 'js-xxhash';

export const getStringColor = (str: string) => {
  let textEncoder = new TextEncoder(); // Note TextEncoder is experimental
  let hashNum = xxHash32(textEncoder.encode(str), 42);

  const valueH = Math.floor(((hashNum & 0xffff) / 0x10000) * 360);
  const deltaS = Math.floor((((hashNum << 8) & 0xffff) / 0x10000) * 40);
  const deltaL = Math.floor((((hashNum << 16) & 0xffff) / 0x10000) * 40);

  // https://www.w3schools.com/css/css_colors_hsl.asp
  return `hsl(${valueH},${40 + deltaS}%,${50 + deltaL}%)`;
};
