import {
  isTreeNodeFunction,
  isTreeNodeLiteral,
  mkTreeLiteral,
  ValueTreeNode,
} from '../../enjc-symbol-value-tree/tree-node';
import { processLiteralDraft } from '../../enjc-symbol-value-tree/tree-editing';
import { mkValueFunctionRef } from '../../enjc-workspace';
import { getValueFunctionByDraftNVTV } from './getValueFunctionByDraftNVTV';
import { IValueTreeViewContext } from '../model';

export const updateNodeDraftNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: ValueTreeNode,
  nextDraft: string,
): ValueTreeNode => {
  if (isTreeNodeLiteral(node)) {
    return mkTreeLiteral(node.key, nextDraft, processLiteralDraft(nextDraft));
  } else if (isTreeNodeFunction(node)) {
    if (nextDraft.length === 0) {
      return { ...node, draft: nextDraft, function: mkValueFunctionRef('fg.noop') };
    } else {
      const draftFunction = getValueFunctionByDraftNVTV(vtvCtx, nextDraft);
      if (draftFunction && node.arguments.length === draftFunction.defaultArgCount) {
        return {
          ...node,
          draft: nextDraft,
          function: mkValueFunctionRef(draftFunction.id),
        };
      } else {
        return {
          ...node,
          draft: nextDraft,
          function: mkValueFunctionRef('fg.stub'),
        };
      }
    }
  } else {
    return {
      ...node,
      draft: nextDraft,
    };
  }
};
