import React, { ReactElement } from 'react';
import { WorkspacesListContextLoader } from '../libenjc/enjc-react/enjc-react-context';
import { WorkspaceDashboard } from '../app/workspace-dashboard';

export const WorkspacesDashboardRoute = (): ReactElement => {
  return (
    <WorkspacesListContextLoader>
      <WorkspaceDashboard />
    </WorkspacesListContextLoader>
  );
};
