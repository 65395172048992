import React, { ReactElement, ReactNode } from 'react';
import { TWorkspaceId, mkEnjicalcWorkspace } from '../../../enjc-workspace';
import { useEnjcMath, useEnjcWorkspace } from '../../enjc-react-client';
import { LoadingPlaceholder } from '../../../../components/misc';
import { ApolloErrorMessage, ErrorMessage } from '../../../../components/errors';
import { WorkspaceContextProvider } from './WorkspaceContextProvider';
import { useUpdateWorkspaceMutation } from '../../../../generated/graphql/apollo';
import { EnjcWorkspaceInput } from '../../../enjicalc-graphql';
import { EnjcWorkspaceUpdateResultFragment } from '../../../../generated/graphql/operations';

interface IProps {
  readonly workspaceId: TWorkspaceId;
  readonly children: ReactNode;
}

const WorkspaceContextLoaderF = ({ workspaceId, children }: IProps): ReactElement => {
  const { loading: mLoading, error: mError, math: enjcMath } = useEnjcMath();
  const {
    loading: wLoading,
    error: wError,
    workspace: enjcWorkspace,
    refetch: wRefetch,
  } = useEnjcWorkspace(workspaceId);
  const [updateWorkspaceM] = useUpdateWorkspaceMutation();

  // FIXME: review unused parts
  const updateWorkspace = React.useCallback<(diff: EnjcWorkspaceInput) => Promise<EnjcWorkspaceUpdateResultFragment>>(
    (diff) => {
      return updateWorkspaceM({ variables: { workspace: workspaceId, generation: -1, diff } })
        .then((mutationResult) => mutationResult.data?.updateWorkspace || Promise.reject())
        .then((r) => {
          wRefetch();
          return r;
        });
    },
    [updateWorkspaceM, wRefetch, workspaceId],
  );

  // console.log('Workspace Context:', enjcWorkspace);

  const enjicalcWorkspace = React.useMemo(() => {
    return enjcWorkspace && enjcMath ? mkEnjicalcWorkspace(enjcWorkspace, enjcMath.enjcFunctions) : undefined;
  }, [enjcWorkspace, enjcMath]);

  if (wLoading || mLoading) return <LoadingPlaceholder name={'Workspace'} />;
  if (wError) return <ApolloErrorMessage error={wError} />;
  if (mError) return <ApolloErrorMessage error={mError} />;
  if (!enjicalcWorkspace)
    return <ErrorMessage description={'WorkspaceContextLoader: workspace or/and functions undefined'} />;

  return <WorkspaceContextProvider workspace={enjicalcWorkspace}>{children}</WorkspaceContextProvider>;
};

export const WorkspaceContextLoader = React.memo(WorkspaceContextLoaderF);
