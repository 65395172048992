import React, { ReactElement } from 'react';
import {
  TreeFunctionSqrtView,
  TreeFunctionCndView,
  TreeFunctionFracView,
  TreeFunctionPowView,
  TreeFunctionUminusView,
} from '../../../../enjc-react-mathml/components';
import { IValueFunctionSpecialViewProps } from '../../enjc-symbol-value-composite-view/interfaces';

export const TreeFunctionSpecialView = ({
  funcSpecName,
  argReactNodes,
  onNodeClick,
}: IValueFunctionSpecialViewProps): ReactElement | undefined => {
  if (funcSpecName === 'fg.sqrt' && argReactNodes.length === 1) {
    return <TreeFunctionSqrtView argValue={argReactNodes[0]} />;
  }

  if (funcSpecName === 'fg.uminus' && argReactNodes.length === 1) {
    return <TreeFunctionUminusView argValue={argReactNodes[0]} />;
  }

  if (funcSpecName === 'fg.cnd' && argReactNodes.length === 3) {
    return <TreeFunctionCndView argCnd={argReactNodes[0]} argTrue={argReactNodes[1]} argFalse={argReactNodes[2]} />;
  }

  if (funcSpecName === 'fg.divide' && argReactNodes.length === 2) {
    return <TreeFunctionFracView argNum={argReactNodes[0]} argDen={argReactNodes[1]} />;
  }

  if (funcSpecName === 'fg.pow' && argReactNodes.length === 2) {
    return <TreeFunctionPowView argBase={argReactNodes[0]} argExpo={argReactNodes[1]} />;
  }

  return undefined;
};
