import React from 'react';
import FocusLock from 'react-focus-lock';
import { ValueInput } from './ValueInput';
import { Text } from '@chakra-ui/react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/shadcn';

interface IProps {
  initialValue: string;
  onSave?: (v: string) => void;
  children: React.ReactNode;
}

export const TextPopover = ({ initialValue, onSave, children }: IProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const firstFieldRef = React.useRef(null);

  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Popover open={!!onSave && isOpen} onOpenChange={handleToggle}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="p-0" width="400px">
        <FocusLock returnFocus persistentFocus={false} className="rounded-lg">
          <ValueInput
            initialValue={initialValue}
            firstFieldRef={firstFieldRef}
            onCancel={handleToggle}
            onSave={(v) => {
              onSave && onSave(v);
              handleToggle();
            }}
            isHidden={!isOpen}
            validator={(v) => (v.length <= 100 && !/^\s*$/.test(v) ? '' : 'Invalid Entry')}
            inputWidth="500px"
          >
            {(value) => (
              <Text
                pos="absolute"
                zIndex="5"
                bottom="0px"
                left="22px"
                w="fit-content"
                h="fit-content"
                p="0px 7px"
                color="#999"
                fontSize="9px"
                bg="#fff"
              >
                {value.length + '/100'}
              </Text>
            )}
          </ValueInput>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
