import React, { ReactElement } from 'react';
import { assertNever } from '../../../../utils';
import { EnjcGlyphItem } from '../../../enjc-glyph/model';
import { MathMathMLAttributes } from '../../../enjc-react-mathml/attributes';
import { EnjcGlyphSubSupMathmlView } from './EnjcGlyphSubSupMathmlView';
import { EnjcGlyphDivisionMathmlView } from './EnjcGlyphDivisionMathmlView';

interface IProps extends MathMathMLAttributes {
  readonly enjcGlyphItem: EnjcGlyphItem;
}

export const EnjcGlyphItemMathmlView = ({ enjcGlyphItem, ...mathProps }: IProps): ReactElement => {
  switch (enjcGlyphItem.__typename) {
    case 'EnjcGlyphSubSup':
      return <EnjcGlyphSubSupMathmlView glyphSubSup={enjcGlyphItem} {...mathProps} />;
    case 'EnjcGlyphDivision':
      return <EnjcGlyphDivisionMathmlView glyphDivision={enjcGlyphItem} {...mathProps} />;
    default:
      return assertNever(enjcGlyphItem);
  }
};
