import { TSymbolId } from '../types';
import { EnjcSymbolFragment } from '../../../libenjc/enjicalc-graphql';
import { EnjcWorkspaceDTO } from '../model';

export const getEnjcSymbolOrUndefined = (
  enjicalcWorkspace: EnjcWorkspaceDTO,
  symbolId: TSymbolId,
): EnjcSymbolFragment | undefined => {
  return enjicalcWorkspace.symbols.find((item) => item.id === symbolId);
};
