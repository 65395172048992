import { TValueFunctionId } from '../../enjc-value-math';
import { EOperatorPriority, EOperatorAssociativity } from '../enums';
import { IFunctionBinaryOperator } from '../interfaces';

export const mkFunctionBinaryOperator = (
  functionId: TValueFunctionId,
  glyph: string,
  glyphPlaintext: string,
  priority: EOperatorPriority,
  associativity: EOperatorAssociativity = EOperatorAssociativity.Left,
): IFunctionBinaryOperator => ({
  id: functionId,
  glyph,
  glyphPlaintext,
  priority,
  associativity,
});
