import { IValueTreeViewContext, getCursorPositionNode } from '../../../enjc-value-view-ctx';
import { ValueTreeClientDelta } from '../../tree-editing';
import {
  isValueTreePositionAtStartEnd,
  isTreePositionNode,
  isTreePositionNodeDraft,
  mkTreePositionNode,
  ValueTreeNodePosition,
} from '../../tree-position';
import { findNodePositionNeighbourNVTV } from '../../tree-position/utils/findNodePositionNeighbourNVTV';
import { mkTreeCursor, mkTreeCursorNode, mkTreeCursorNodeDraft } from '../../tree-cursor';
import { checkTreeNodeDraftPositionIsValid } from '../../tree-utils';
import { isTreeNodeFunction, isTreeNodeSymbol } from '../../tree-node';

export const handleTreeKeyArrowHorizontal = (
  vtvCtx: IValueTreeViewContext,
  moveForward: boolean,
): ValueTreeClientDelta => {
  const { cursorPosition, positionNode } = getCursorPositionNode(vtvCtx)!;

  // Cursor moves from node
  if (isValueTreePositionAtStartEnd(positionNode, cursorPosition, moveForward)) {
    // Special case: editing draft of a function
    //  * When cursor is at the end of the draft, and user moves it forward
    //  *  the cursor moves to first argument as if the cursor was at the beginning of cursor node
    const searchPosition: ValueTreeNodePosition =
      isTreeNodeFunction(positionNode) && isTreePositionNodeDraft(cursorPosition) && moveForward
        ? mkTreePositionNode(cursorPosition.nodeKey, !moveForward)
        : mkTreePositionNode(cursorPosition.nodeKey, moveForward);
    const neighbourPosition = findNodePositionNeighbourNVTV(vtvCtx, searchPosition, moveForward);
    if (!neighbourPosition) {
      return { cursor: undefined };
    }
    return {
      cursor: mkTreeCursor(neighbourPosition),
    };
  }

  // Node cursor moves over/into node
  if (isTreePositionNode(cursorPosition) && isValueTreePositionAtStartEnd(positionNode, cursorPosition, !moveForward)) {
    if (isTreeNodeSymbol(positionNode)) {
      return { cursor: mkTreeCursorNode(positionNode.key, moveForward) };
    }
    if (isTreeNodeFunction(positionNode)) {
      const neighbourPosition = findNodePositionNeighbourNVTV(vtvCtx, cursorPosition, moveForward);
      if (!neighbourPosition) {
        return { cursor: undefined };
      }
      return {
        cursor: mkTreeCursor(neighbourPosition),
      };
    }
  }

  const draftPosition = isTreePositionNode(cursorPosition)
    ? cursorPosition.atEnd
      ? positionNode.draft.length
      : 0
    : cursorPosition.at;
  const nextNodeDraftPosition = draftPosition + (moveForward ? 1 : -1);
  const draftPositionIsValid = checkTreeNodeDraftPositionIsValid(positionNode, nextNodeDraftPosition);
  if (!draftPositionIsValid) {
    // TODO: check this branch is reachable
    return {
      cursor: undefined,
    };
  }
  return {
    cursor: mkTreeCursorNodeDraft(cursorPosition.nodeKey, nextNodeDraftPosition),
  };
};
