import React, { ReactElement } from 'react';
import { ISymbolValueDraftViewProps } from '../../enjc-symbol-value-composite-view/props';
import { isDraftPositionValid } from '../../enjc-symbol-value-composite-view/utils';
import { SymbolValuePlainViewContainer } from './SymbolValuePlainViewContainer';
import { SymbolValuePlainCursor } from './SymbolValuePlainCursor';

export const SymbolValueDraftPlainView = ({
  draft,
  position,
  onClick,
  onHintClick,
}: ISymbolValueDraftViewProps): ReactElement => {
  return position !== undefined && isDraftPositionValid({ draft, position }) ? (
    <SymbolValuePlainViewContainer onClick={onClick}>
      {position > 0 && <span>{draft.substring(0, position)}</span>}
      <SymbolValuePlainCursor onHintClick={onHintClick} />
      {position < draft.length && <span>{draft.substring(position)}</span>}
    </SymbolValuePlainViewContainer>
  ) : (
    <span>{draft}</span>
  );
};
