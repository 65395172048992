import { IOperatorsChain } from '../interfaces';
import { getNodeKey, TValueTreeNodeOrKey } from '../../tree-node';

/**
 * Finds the index of an operand in an operators chain.
 * @param operatorsChain - The operators chain.
 * @param operand - The operand to search for.
 * @returns - The index of the operand in the operators chain.
 * @throws {Error} - Throws an error if the operand is not found in the operators chain.
 */
export const findOperatorsChainOperandIndex = (
  operatorsChain: IOperatorsChain,
  operand: TValueTreeNodeOrKey,
): number => {
  const operandKey = getNodeKey(operand);
  if (operatorsChain.head.key === operandKey) {
    return 0;
  }
  const operandIndex = operatorsChain.body.findIndex((n) => n.operandNode.key === operandKey);
  if (operandIndex < 0) {
    throw new Error(`Operand with key '${operandKey}' not found in operators chain`);
  }
  return operandIndex + 1;
};
