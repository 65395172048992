import { TValueFunctionId } from '../../enjc-value-math/model/TValueFunctionId';
import { ValueFunctionRefFragment } from '../../../generated/graphql/operations';

export const mkValueFunctionRef = (functionId: TValueFunctionId | undefined): ValueFunctionRefFragment | undefined => {
  return functionId === undefined
    ? functionId
    : {
        __typename: 'ValueFunction',
        id: functionId,
      };
};
