export type PossibleTypesResultData = {
  possibleTypes: {
    IEnjcMathIdentifier: ['EnjcSymbol', 'ValueFunction'];
    IEnjcOrderedDeltaEntry: ['EnjcKeyListDeltaEntry', 'EnjcStringDeltaEntry'];
    IEnjcWorkspaceItem: ['EnjcSection', 'EnjcSymbol', 'EnjcWorkspace'];
    UEnjcSectionItem: ['EnjcSection', 'EnjcSymbol'];
    UValueLiteral: ['ValueLiteralBoolean', 'ValueLiteralNumber', 'ValueLiteralString', 'ValueLiteralVoid'];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    IEnjcMathIdentifier: ['EnjcSymbol', 'ValueFunction'],
    IEnjcOrderedDeltaEntry: ['EnjcKeyListDeltaEntry', 'EnjcStringDeltaEntry'],
    IEnjcWorkspaceItem: ['EnjcSection', 'EnjcSymbol', 'EnjcWorkspace'],
    UEnjcSectionItem: ['EnjcSection', 'EnjcSymbol'],
    UValueLiteral: ['ValueLiteralBoolean', 'ValueLiteralNumber', 'ValueLiteralString', 'ValueLiteralVoid'],
  },
};
export default result;
