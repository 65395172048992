import React, { ReactNode } from 'react';

export const TreeFunctionUminusView = ({ argValue }: { argValue: ReactNode }) => {
  return (
    <mrow>
      <mo>{'-'}</mo>
      {argValue}
    </mrow>
  );
};
