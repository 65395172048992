import { EnjcSymbolFragment } from '../../enjicalc-graphql';
import { applyStringDelta } from '../../enjc-delta';
import { EnjcWorkspaceSymbolDelta } from '../model';
import { applyValueTreeDelta } from './applyValueTreeDelta';
import { EnjicalcWorkspace } from '../../enjc-workspace';

export const applySymbolDelta = (
  workspace: EnjicalcWorkspace,
  symbol: EnjcSymbolFragment,
  delta: EnjcWorkspaceSymbolDelta,
): EnjcSymbolFragment => {
  return {
    ...symbol,
    glyph: delta.glyph ? applyStringDelta(symbol.glyph, delta.glyph) : symbol.glyph,
    glyphPlaintext: delta.glyphPlaintext
      ? applyStringDelta(symbol.glyphPlaintext, delta.glyphPlaintext)
      : symbol.glyphPlaintext,
    unit: delta.unit ? applyStringDelta(symbol.unit, delta.unit) : symbol.unit,
    comment: delta.comment ? applyStringDelta(symbol.comment, delta.comment) : symbol.comment,
    description: delta.description ? applyStringDelta(symbol.description, delta.description) : symbol.description,
    visibility: delta.visibility?.atomAfter ?? symbol.visibility,
    valueTree: delta.valueTree ? applyValueTreeDelta(workspace, symbol.valueTree, delta.valueTree) : symbol.valueTree,
  };
};
