import React, { ReactElement } from 'react';
import { EnjcGlyphSubSup } from '../../../enjc-glyph/model';
import { EnjcGlyphPrimitivePlainView } from './EnjcGlyphPrimitivePlainView';

interface IProps {
  readonly glyphSubSup: EnjcGlyphSubSup;
}

export const EnjcGlyphSubSupPlainView = ({ glyphSubSup }: IProps): ReactElement => {
  if (glyphSubSup.subscript && glyphSubSup.superscript) {
    return (
      <>
        <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.base} />
        <span>{'_'}</span>
        <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.subscript} />
        <span>{'^'}</span>
        <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.superscript} />
      </>
    );
  }

  if (glyphSubSup.subscript && !glyphSubSup.superscript) {
    return (
      <>
        <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.base} />
        <sub>
          <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.subscript} />
        </sub>
      </>
    );
  }

  if (!glyphSubSup.subscript && glyphSubSup.superscript) {
    return (
      <>
        <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.base} />
        <sup>
          <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.superscript} />
        </sup>
      </>
    );
  }

  return <EnjcGlyphPrimitivePlainView glyphPrimitive={glyphSubSup.base} />;
};
