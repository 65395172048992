import React, { ReactElement } from 'react';
import { isTreeNodeFunction, isTreeNodeLiteral, isTreeNodeSymbol } from '../../../../enjc-symbol-value-tree/tree-node';
import { isLiteralVoid, mkLiteralVoidNull } from '../../../../enjc-literal';
import { getSymbolValueCursorAsNode, getValueSymbolNVTV } from '../../../../enjc-value-view-ctx';
import { useValueTreeViewContext } from '../../../enjc-react-ui';
import { getNodeCursorPositionAsDraft } from '../utils';
import { ISymbolValueNodeCompositeViewProps } from '../props';
import { SymbolValueNodeFunctionCompositeView } from './SymbolValueNodeFunctionCompositeView';

export const SymbolValueNodeCompositeView = ({
  node,
  viewComponents,
  viewOptions,
  onNodeClick,
  onHintClick,
}: ISymbolValueNodeCompositeViewProps): ReactElement => {
  const vtvCtx = useValueTreeViewContext();

  const { draftViewComp: DraftView, literalViewComp: LiteralView, glyphViewComp: GlyphView } = viewComponents;
  const nodeDraftPosition = getNodeCursorPositionAsDraft(node, vtvCtx.valueTreeCursor);
  const nodeViewCursor = getSymbolValueCursorAsNode(vtvCtx, node);

  // const onNodeClick = React.useCallback(() => {}, []);

  if (isTreeNodeLiteral(node)) {
    if ((viewOptions.literalsAsDrafts || isLiteralVoid(node.literal)) && node.draft.length > 0) {
      return (
        <DraftView
          draft={node.draft}
          position={nodeDraftPosition}
          onClick={() => onNodeClick(node.key)}
          onHintClick={onHintClick}
        />
      );
    } else {
      return (
        <LiteralView
          literal={node.literal}
          cursor={nodeViewCursor}
          onClick={() => onNodeClick(node.key)}
          onHintClick={onHintClick}
        />
      );
    }
  } else if (isTreeNodeSymbol(node)) {
    // TODO: check handling of unset symbol id
    if (!viewOptions.numerical) {
      const symbolGlyph = ((node.symbol?.id && getValueSymbolNVTV(vtvCtx, node.symbol.id)) || null)?.glyph || '';
      return <GlyphView glyph={symbolGlyph} cursor={nodeViewCursor} onClick={() => onNodeClick(node.key)} />;
    } else {
      const symbolLiteral =
        node.symbol?.id === undefined
          ? mkLiteralVoidNull()
          : getValueSymbolNVTV(vtvCtx, node.symbol.id).valueTree.result;
      return <LiteralView literal={symbolLiteral} cursor={nodeViewCursor} onClick={() => onNodeClick(node.key)} />;
    }
  } else if (isTreeNodeFunction(node)) {
    return (
      <SymbolValueNodeFunctionCompositeView
        node={node}
        viewComponents={viewComponents}
        viewOptions={viewOptions}
        onNodeClick={onNodeClick}
        onHintClick={onHintClick}
      />
    );
  } else {
    // TODO: report unknown node in the symbol value tree
    return <>{`Error (Unknown node '${node?.mode}'`}</>;
  }
};
