import { isTreeNodeFunction, TValueTreeNodeOrKey } from '../../tree-node';
import { getNodeFunctionBinaryOperator } from '../../../enjc-value-operators';
import { ValueTreeNodePosition } from '../model';
import { mkTreePositionNode } from './mkTreePositionNode';
import { IValueTreeViewContext, resolveTreeNodeNVTV } from '../../../enjc-value-view-ctx';
import { findNodePositionNVTV } from './findNodePositionNVTV';

export const findNodePositionChildNeighbourNVTV = (
  vtvCtx: IValueTreeViewContext,
  nodeOrKey: TValueTreeNodeOrKey,
  moveForward: boolean,
): ValueTreeNodePosition | undefined => {
  const positionNode = resolveTreeNodeNVTV(vtvCtx, nodeOrKey);
  if (!positionNode) {
    // TODO: review and report error
    return undefined;
  }

  return !isTreeNodeFunction(positionNode) ||
    getNodeFunctionBinaryOperator(positionNode) ||
    positionNode.arguments.length === 0
    ? mkTreePositionNode(positionNode.key, moveForward)
    : findNodePositionNVTV(
        vtvCtx,
        moveForward ? positionNode.arguments[0].key : positionNode.arguments[positionNode.arguments.length - 1].key,
        moveForward,
      );
};
