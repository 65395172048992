import { getNodeKey, TValueTreeNodeOrKey, ValueTreeNode } from '../../enjc-symbol-value-tree/tree-node';
import { INodeParentInfo } from '../../enjc-symbol-value-tree/tree-core';
import { IValueTreeViewContext } from '../model';

export const getTreeNodeParentInfoOrUndefinedNVTV = (
  vtvCtx: IValueTreeViewContext,
  nodeOrKey: TValueTreeNodeOrKey,
): INodeParentInfo | undefined => {
  const parentNode = vtvCtx.symbol.valueTree.nodes.find((p: ValueTreeNode) =>
    p.arguments.find((a) => a.key === getNodeKey(nodeOrKey)),
  );
  if (!parentNode) {
    return undefined;
  }
  const argumentPosition = parentNode.arguments.findIndex((a) => a.key === getNodeKey(nodeOrKey));
  return {
    node: parentNode,
    index: argumentPosition,
  };
};
