import { INodeParentInfo } from '../../enjc-symbol-value-tree/tree-core';
import { ValueTreeNode } from '../../enjc-symbol-value-tree/tree-node';
import { isNodeOperator } from '../../enjc-value-operators';
import { getTreeNodeParentInfoOrUndefinedNVTV } from './getTreeNodeParentInfoOrUndefinedNVTV';
import { IValueTreeViewContext } from '../model';

export const findNonOperatorParentInfoNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: ValueTreeNode,
): INodeParentInfo | undefined => {
  const nodeParentInfo = getTreeNodeParentInfoOrUndefinedNVTV(vtvCtx, node);

  return !nodeParentInfo
    ? undefined
    : !isNodeOperator(nodeParentInfo.node)
      ? nodeParentInfo
      : findNonOperatorParentInfoNVTV(vtvCtx, nodeParentInfo.node);
};
