import {
  EnjcWorkspaceItemVisibility,
  findWorkspaceItemContainers,
  findWorkspaceSymbolNodes,
  getEnjcSymbol,
  TSymbolId,
} from '../../enjc-workspace';
import {
  EnjcWorkspaceSectionDelta,
  EnjcWorkspaceSymbolDelta,
  WorkspaceEditHistoryEntry,
} from '../../enjc-workspace-editing';
import { EnjcWorkspaceDTO } from '../../enjc-workspace/model';
import { mkAtomDelta } from '../../enjc-delta';
import { mkWorkspaceEditHistoryEntry, changeSetSymbolVisibility } from '../utils';

export const editDeleteSymbol = (workspace: EnjcWorkspaceDTO, symbolId: TSymbolId): WorkspaceEditHistoryEntry => {
  const existingSymbol = getEnjcSymbol(workspace, symbolId);
  const symbolContainers = findWorkspaceItemContainers(workspace, symbolId);
  const symbolNodes = findWorkspaceSymbolNodes(workspace, symbolId);
  const containerDeltas: EnjcWorkspaceSectionDelta[] = symbolContainers.map((iCt) => {
    return {
      id: iCt.container.id,
      items: [{ slicePosition: iCt.index, sliceBefore: [iCt.container.items[iCt.index].id] }],
    };
  });
  const symbolDelta = changeSetSymbolVisibility(existingSymbol, EnjcWorkspaceItemVisibility.Deleted);
  // TODO: Change mode of symbol nodes if any
  const symbolsNodeDeltas = symbolNodes.map((syNo) => {
    return { id: syNo.symbolId, valueTree: { nodes: [{ key: syNo.nodeKey, symbol: mkAtomDelta(symbolId, '') }] } };
  });
  const symbolCombinedDeltas: EnjcWorkspaceSymbolDelta[] = [
    ...(symbolDelta ? [symbolDelta] : []),
    ...symbolsNodeDeltas,
  ];
  return mkWorkspaceEditHistoryEntry('editDeleteSymbol', {
    section: containerDeltas.length > 0 ? containerDeltas : undefined,
    symbol: symbolCombinedDeltas.length > 0 ? symbolCombinedDeltas : undefined,
  });
};
