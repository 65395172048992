import React, { ReactElement } from 'react';
import { Button, Divider, HStack, IconButton, VStack } from '@chakra-ui/react';
import { LiaRedoSolid, LiaUndoSolid } from 'react-icons/lia';
import { MathMathMLAttributes } from '../../../libenjc/enjc-react-mathml/attributes';
import { EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { EnjicalcSymbol, EnjicalcWorkspace, getEnjcSymbol } from '../../../libenjc/enjc-workspace';
import { EnjcValueTreeDelta, EnjcWorkspaceSymbolDelta } from '../../../libenjc/enjc-workspace-editing';
import { convertWorkspaceEditHistoryEntryToDiffInput } from '../../../libenjc/enjc-client';
import { useEnjcRawWorkspaceMutations } from '../../../libenjc/enjc-react/enjc-react-client';
import { useWorkspaceEditHistoryConstructor } from './useWorkspaceEditHistoryConstructor';
import { SymbolValueSplitEditor } from '../symbol-value-split-editor';
import { SymbolTextAreaFieldEditor } from './SymbolTextAreaFieldEditor';
import { SymbolTextLineFieldEditor } from './SymbolTextLineFieldEditor';
import { CloseSymbolEditorDialog } from './CloseSymbolEditorDialog';
import { AccordionItem, AccordionTrigger, Accordion, AccordionContent } from 'src/shadcn';
import {
  ALPHA,
  BETA,
  GAMMA,
  QUICK_START_TOUR_STEP_COUNT,
  QUICK_START_TOUR_STEP_NAMES,
  useQuickStartTour,
} from 'src/hooks';

export enum ESections {
  calculations = 'calculations',
  comment = 'comment',
}

interface IProps {
  readonly workspace: EnjicalcWorkspace;
  readonly symbol: EnjicalcSymbol;

  readonly isOpen?: boolean;
  readonly onClose?: (isSaved: boolean) => void;
  readonly onConfirm?: () => void;
  readonly onSaveButtonStateChange?: (isEnabled: boolean) => void;
  readonly onCloseModal?: () => void;
  readonly section?: ESections;
}

export const SymbolEditor = ({
  workspace,
  symbol,
  onClose,
  onConfirm,
  isOpen,
  onSaveButtonStateChange,
  onCloseModal,
  section,
}: IProps): ReactElement => {
  const mathProps: MathMathMLAttributes = { style: { fontFamily: 'Latin Modern Math', fontSize: '22px' } };
  const { updateWorkspace } = useEnjcRawWorkspaceMutations();

  const { workspaceEditHistory, performEdit, performUndo, performRedo, performReset } =
    useWorkspaceEditHistoryConstructor(workspace);

  const { setCurrentStep, isAlpha, isBeta, currentStep } = useQuickStartTour();

  const currentSymbol = getEnjcSymbol(workspaceEditHistory.currentState, symbol.id);

  const isDisabledSaveButton = workspaceEditHistory.historyPosition === 0;

  const [activeSection, setActiveSection] = React.useState<ESections>(section || ESections.calculations);

  const handleAccordionSectionChange = (sectionValue: ESections) => setActiveSection(sectionValue);

  const saveSymbol = React.useCallback(() => {
    updateWorkspace(workspace.id, -1, {
      items: workspaceEditHistory.historyEntries
        .slice(0, workspaceEditHistory.historyPosition)
        .map((he) => convertWorkspaceEditHistoryEntryToDiffInput(he)),
    }).then(() => {
      if (isAlpha) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddSymbolBetta);
        return;
      }

      if (currentStep === QUICK_START_TOUR_STEP_COUNT.AddValueForSymbolGamma) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.Print);
      } else {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddSymbolGamma);
      }
    });
    performReset(true);
    onCloseModal && onCloseModal();
  }, [
    currentStep,
    isAlpha,
    performReset,
    updateWorkspace,
    workspace.id,
    onCloseModal,
    workspaceEditHistory.historyEntries,
    workspaceEditHistory.historyPosition,
    setCurrentStep,
  ]);

  const updateSymbol = React.useCallback(
    (title: string, symbolDelta: EnjcWorkspaceSymbolDelta) => {
      performEdit({
        title,
        timestamp: Date.now(),
        delta: {
          symbol: [symbolDelta],
        },
      });
    },
    [performEdit],
  );

  const updateSymbolGlyph = React.useCallback(
    (delta: EnjcStringDeltaEntry) => {
      updateSymbol('Edit Symbol Glyph', {
        id: symbol.id,
        glyph: [delta],
      });
    },
    [symbol.id, updateSymbol],
  );

  const updateSymbolUnit = React.useCallback(
    (delta: EnjcStringDeltaEntry) =>
      updateSymbol('Edit Symbol Unit', {
        id: symbol.id,
        unit: [delta],
      }),
    [symbol.id, updateSymbol],
  );

  const updateSymbolDescription = React.useCallback(
    (delta: EnjcStringDeltaEntry) =>
      updateSymbol('Edit Symbol Description', {
        id: symbol.id,
        description: [delta],
      }),
    [symbol.id, updateSymbol],
  );

  const updateSymbolComment = React.useCallback(
    (delta: EnjcStringDeltaEntry) =>
      updateSymbol('Edit Symbol Comment', {
        id: symbol.id,
        comment: [delta],
      }),
    [symbol.id, updateSymbol],
  );

  const updateSymbolValueTree = React.useCallback(
    (delta: EnjcValueTreeDelta) =>
      updateSymbol('Edit Symbol Value', {
        id: symbol.id,
        valueTree: delta,
      }),
    [symbol.id, updateSymbol],
  );

  React.useEffect(() => {
    if (isAlpha) {
      if (currentSymbol.glyph === ALPHA) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddValueForSymbol);
      }
    } else if (isBeta) {
      if (currentSymbol.glyph === BETA) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddValueForSymbolBetta);
      }
    } else {
      if (currentSymbol.glyph === GAMMA) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddValueForSymbolGamma);
      }
    }
  }, [currentSymbol.glyph, setCurrentStep, isAlpha, isBeta]);

  React.useEffect(() => {
    if (isAlpha) {
      // @ts-ignore
      if (currentSymbol.valueTree.nodes[0].literal?.numb === 1) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.SaveSymbol);
      }
    } else if (isBeta) {
      // @ts-ignore
      if (currentSymbol.valueTree.nodes[0].literal?.numb === 2) {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.SaveSymbolBetta);
      }
    }
  }, [currentSymbol, setCurrentStep, isAlpha, isBeta]);

  React.useEffect(() => {
    onSaveButtonStateChange && onSaveButtonStateChange(!isDisabledSaveButton);
  }, [onSaveButtonStateChange, isDisabledSaveButton]);

  return (
    <>
      <VStack flex={1} align={'start'}>
        <CloseSymbolEditorDialog
          isOpen={isOpen!}
          onClose={() => onClose && onClose(isDisabledSaveButton)}
          onConfirm={onConfirm!}
        />

        <HStack>
          <Button
            as={IconButton}
            aria-label="Symbol Options"
            icon={<LiaUndoSolid />}
            isDisabled={workspaceEditHistory.historyPosition === 0}
            onClick={performUndo}
          />
          <Button
            as={IconButton}
            aria-label="Symbol Options"
            icon={<LiaRedoSolid />}
            isDisabled={workspaceEditHistory.historyPosition === workspaceEditHistory.historyEntries.length}
            onClick={performRedo}
          />
        </HStack>
        <Divider />

        <Accordion
          type="single"
          collapsible
          value={activeSection}
          onValueChange={handleAccordionSectionChange}
          className="w-full"
        >
          <AccordionItem value={ESections.calculations}>
            <AccordionTrigger>Calculations:</AccordionTrigger>
            <AccordionContent>
              <VStack gap={2} align={'start'} pt="4px">
                <VStack
                  align={'start'}
                  alignSelf={'start'}
                  w="100%"
                  className={
                    isAlpha
                      ? QUICK_START_TOUR_STEP_NAMES.WriteSymbolName
                      : isBeta
                        ? QUICK_START_TOUR_STEP_NAMES.WriteSymbolNameBetta
                        : QUICK_START_TOUR_STEP_NAMES.WriteSymbolNameGamma
                  }
                >
                  <SymbolTextLineFieldEditor
                    name={'Symbol Name'}
                    text={currentSymbol.glyph}
                    onTextDelta={updateSymbolGlyph}
                  />
                </VStack>

                <VStack
                  className={
                    isAlpha
                      ? QUICK_START_TOUR_STEP_NAMES.AddValueForSymbol
                      : isBeta
                        ? QUICK_START_TOUR_STEP_NAMES.AddValueForSymbolBetta
                        : ''
                  }
                >
                  <SymbolValueSplitEditor
                    workspace={workspaceEditHistory.currentState}
                    symbol={currentSymbol}
                    showGlyph
                    showResult
                    mathProps={mathProps}
                    onSymbolValueDelta={updateSymbolValueTree}
                    performUndo={performUndo}
                    performRedo={performRedo}
                  />
                </VStack>

                <VStack align={'start'} alignSelf={'start'} w="100%">
                  <SymbolTextLineFieldEditor name={'Unit'} text={currentSymbol.unit} onTextDelta={updateSymbolUnit} />
                </VStack>
              </VStack>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value={ESections.comment}>
            <AccordionTrigger>Description & Comment:</AccordionTrigger>
            <AccordionContent>
              <VStack gap={2} align={'start'} pt="4px">
                <SymbolTextAreaFieldEditor
                  name={'Description'}
                  text={currentSymbol.description}
                  onTextDelta={updateSymbolDescription}
                />

                <SymbolTextAreaFieldEditor
                  name={'Comment'}
                  text={currentSymbol.comment}
                  onTextDelta={updateSymbolComment}
                />
              </VStack>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </VStack>

      <HStack position="absolute" bottom={5} right={5}>
        <Button
          w="120px"
          onClick={onCloseModal}
          variant="outline"
          colorScheme="blackAlpha"
          style={{ color: 'black' }}
          _active={{ opacity: '0.95', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 24px 48px' }}
        >
          {'Cancel'}
        </Button>
        <Button
          w="120px"
          color={'white'}
          bg={'var(--app-color)'}
          _hover={{ bg: 'var(--app-color)' }}
          isDisabled={workspaceEditHistory.historyPosition === 0}
          onClick={saveSymbol}
          className={
            isAlpha ? QUICK_START_TOUR_STEP_NAMES.SaveSymbol : isBeta ? QUICK_START_TOUR_STEP_NAMES.SaveSymbolBetta : ''
          }
        >
          {'Save'}
        </Button>
      </HStack>
    </>
  );
};
