import './workspace-sheets-document.css';

import React, { ReactElement } from 'react';
import { HStack } from '@chakra-ui/react';

import { isNotUndefined } from '../../utils';
import { useCtxEnjicalcWorkspace } from '../../libenjc/enjc-react/enjc-react-context';
import { TSectionId, isWorkspaceItemVisible, getEnjcSection, EnjicalcSection } from '../../libenjc/enjc-workspace';
import { SideBarContext } from '../../context/SideBarContext';
import { useEnjcRawWorkspaceMutations } from '../../libenjc/enjc-react/enjc-react-client';
import {
  WorkspaceEditHistoryEntry,
  EnjicalcWorkspaceUpdateResult,
  editCreateSheet,
} from '../../libenjc/enjc-workspace-editing';
import { convertWorkspaceEditHistoryEntryToDiffInput } from '../../libenjc/enjc-client';
import { extractUpdateCreatedSection } from '../../libenjc/enjc-client/utils';
import { SidebarContainer } from '../sidebar-container';
import { useWorkspaceSheetsView } from './useWorkspaceSheetsView';
import { WorkspaceSheetsSidebarPrimary } from './WorkspaceSheetsSidebarPrimary';
import { WorkspaceSheetsSidebarSecondary } from './WorkspaceSheetsSidebarSecondary';
import { WorkspaceSheetsTabs } from './WorkspaceSheetsTabs';
import { useSearchParamsSheetDocumentTabs } from './useSearchParamsSheetDocumentTabs';
import { track } from '@vercel/analytics/react';
import { useUser } from '@clerk/clerk-react';
import { QUICK_START_TOUR_STEP_COUNT, useQuickStartTour } from 'src/hooks';

type TSheet = EnjicalcSection;

const WorkspaceSheetsF = (): ReactElement => {
  const { user } = useUser();
  const { workspace } = useCtxEnjicalcWorkspace();
  const { updateWorkspace } = useEnjcRawWorkspaceMutations();

  const { setCurrentStep } = useQuickStartTour();

  const performWorkspaceEdit = React.useCallback(
    (editEntry: WorkspaceEditHistoryEntry): Promise<EnjicalcWorkspaceUpdateResult> => {
      return updateWorkspace(workspace.id, -1, { items: [convertWorkspaceEditHistoryEntryToDiffInput(editEntry)] });
    },
    [updateWorkspace, workspace.id],
  );

  const createEnjcSection = React.useCallback((): Promise<EnjicalcSection> => {
    track('create-sheet', { emailAddress: `${user?.primaryEmailAddress}`, id: `${user?.id}` });
    const hEntry = editCreateSheet();
    return performWorkspaceEdit(hEntry).then((updateResult) => {
      return extractUpdateCreatedSection(updateResult);
    });
  }, [performWorkspaceEdit, user]);

  const { showHidden } = useWorkspaceSheetsView();
  const { selectedSheetId, openSheetsIds, setSheetTabsState } = useSearchParamsSheetDocumentTabs();

  const { isShow } = React.useContext(SideBarContext);

  const selectedSheet = React.useMemo<TSheet | undefined>(
    () => (selectedSheetId ? getEnjcSection(workspace, selectedSheetId) : undefined),
    [selectedSheetId, workspace],
  );
  const openSheets = React.useMemo<ReadonlyArray<TSheet>>(
    () => openSheetsIds.map((tabSheetId) => getEnjcSection(workspace, tabSheetId)).filter(isNotUndefined),
    [openSheetsIds, workspace],
  );

  const openSheetTab = React.useCallback(
    (sheetId: TSectionId) => {
      if (openSheetsIds.indexOf(sheetId) >= 0) {
        setSheetTabsState(openSheetsIds, sheetId);
      } else {
        if (!!workspace.sheets.find((v) => v.id === sheetId)) {
          setSheetTabsState([...openSheetsIds, sheetId], sheetId);
        }
      }
    },
    [openSheetsIds, setSheetTabsState, workspace.sheets],
  );

  const selectSheetTab = React.useCallback(
    (sheetId: TSectionId | undefined) => {
      if (sheetId) {
        openSheetTab(sheetId);
      } else {
        setSheetTabsState(openSheetsIds, undefined);
      }
    },
    [openSheetTab, openSheetsIds, setSheetTabsState],
  );

  const closeSheetTab = React.useCallback(
    (sheetId: TSectionId) => {
      // TODO: implement nextSelectedSheetId
      // const closedTabIndex = openSheetsIds.indexOf(sheetId);
      // const nextSelectedSheetId = selectedSheetId === sheetId ? : selectedSheetId;
      const nextSheetTabs = openSheetsIds.filter((tabSheetId) => tabSheetId !== sheetId);
      setSheetTabsState(nextSheetTabs, nextSheetTabs.indexOf(sheetId) >= 0 ? sheetId : undefined);
    },
    [openSheetsIds, setSheetTabsState],
  );

  const handleSheetCreate = React.useCallback(() => {
    createEnjcSection()
      .then((createdSheet) => setTimeout(() => openSheetTab(createdSheet.id)))
      .then(() => {
        setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSheet);
      });
  }, [createEnjcSection, openSheetTab, setCurrentStep]);

  const handleSheetListClick = React.useCallback(
    (sheetSectionId: TSectionId) => {
      openSheetTab(sheetSectionId);
    },
    [openSheetTab],
  );

  const handleSheetTabSelect = React.useCallback(
    (sheetSectionId: TSectionId | undefined) => {
      selectSheetTab(sheetSectionId);
    },
    [selectSheetTab],
  );

  const handleSheetTabClose = React.useCallback(
    (sheetSectionId: TSectionId) => {
      closeSheetTab(sheetSectionId);
    },
    [closeSheetTab],
  );

  const visibleSheets = React.useMemo(
    () => workspace.sheets.filter((sheet) => showHidden || isWorkspaceItemVisible(sheet)),
    [workspace.sheets, showHidden],
  );

  return (
    <HStack gap={'0'} alignItems="stretch">
      {isShow && (
        <SidebarContainer position={'left'}>
          <WorkspaceSheetsSidebarPrimary
            sheets={visibleSheets}
            openSheets={openSheets}
            activeSheet={selectedSheet}
            onSheetOpen={handleSheetListClick}
            onSheetCreate={handleSheetCreate}
            performWorkspaceEdit={performWorkspaceEdit}
          />
        </SidebarContainer>
      )}

      <WorkspaceSheetsTabs
        openSheets={openSheets}
        activeSheet={selectedSheet}
        onSheetSelect={handleSheetTabSelect}
        onSheetClose={handleSheetTabClose}
      />

      {false && (
        <SidebarContainer position={'right'}>
          <WorkspaceSheetsSidebarSecondary sheet={selectedSheet} performWorkspaceEdit={performWorkspaceEdit} />
        </SidebarContainer>
      )}
    </HStack>
  );
};

export const WorkspaceSheets = React.memo(WorkspaceSheetsF);
