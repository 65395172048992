import React, { ReactElement } from 'react';
import { EnjcGlyph } from '../../../enjc-glyph';
import { MathMathMLAttributes } from '../../../enjc-react-mathml/attributes';
import { EnjcGlyphItemMathmlView } from './EnjcGlyphItemMathmlView';

interface IProps extends MathMathMLAttributes {
  readonly enjcGlyph: EnjcGlyph;
}

export const EnjcGlyphMathmlView = ({ enjcGlyph, ...mathProps }: IProps): ReactElement => {
  return (
    <mrow {...mathProps}>
      {enjcGlyph.map((glyi, index) => (
        <EnjcGlyphItemMathmlView key={index} enjcGlyphItem={glyi} />
      ))}
    </mrow>
  );
};
