import { createContext } from 'react';

interface ISettingsContext {
  debugShowDebugSymbolRow: boolean;
}

const EnjicalcSettingsContext = createContext<ISettingsContext>({
  debugShowDebugSymbolRow: false,
});

export default EnjicalcSettingsContext;
