import { EnjicalcWorkspace, TSymbolId } from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry, changeMoveSymbolBeforeAfter } from '../utils';

export const editMoveSymbolBeforeAfter = (
  workspace: EnjicalcWorkspace,
  symbolToMoveId: TSymbolId,
  symbolBeforeAfterId: TSymbolId,
  addAfter: boolean,
): WorkspaceEditHistoryEntry => {
  const sectionDeltas = changeMoveSymbolBeforeAfter(workspace, symbolToMoveId, symbolBeforeAfterId, addAfter);
  return mkWorkspaceEditHistoryEntry('editMoveSymbolBeforeAfter', {
    section: sectionDeltas.length > 0 ? sectionDeltas : undefined,
  });
};
