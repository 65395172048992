import React, { ReactElement } from 'react';
import { MathMathMLAttributes } from '../../../libenjc/enjc-react-mathml/attributes';
import { defaultMathBlockProps } from '../../../config/mathml-props';
import { EnjicalcSymbol } from '../../../libenjc/enjc-workspace';
import { EnjcSymbolValueMathmlView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-symbol-value-mathml-view';
import { EnjcGlyphMathmlAdapterView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-glyph-mathml-view';
import { SymbolResultMathView } from './SymbolResultMathView';

interface IProps {
  readonly symbol: EnjicalcSymbol;
  // TODO: review type
  readonly mathProps?: MathMathMLAttributes;
  readonly showGlyph?: boolean;
  readonly showSymbolic?: boolean;
  readonly showNumeric?: boolean;
  readonly showResult?: boolean;
}

export const SymbolValueMathBlockViewF = ({
  symbol,
  mathProps,
  showGlyph,
  showSymbolic,
  showNumeric,
  showResult,
}: IProps): ReactElement => {
  return (
    <mrow {...defaultMathBlockProps} {...mathProps}>
      {showGlyph && <EnjcGlyphMathmlAdapterView glyphAsciiMathStr={symbol.glyph} />}
      {showSymbolic && showGlyph && <mo className="p-2">=</mo>}
      {showSymbolic && <EnjcSymbolValueMathmlView viewOptions={{ literalsAsDrafts: true }} />}
      {showNumeric && (showGlyph || showSymbolic) && <mo className="p-2">=</mo>}
      {showNumeric && <EnjcSymbolValueMathmlView viewOptions={{ numerical: true, literalsAsDrafts: true }} />}
      {showResult && (showGlyph || showSymbolic || showNumeric) && <mo className="p-2">=</mo>}
      {showResult && <SymbolResultMathView literal={symbol.valueTree.result} />}
    </mrow>
  );
};

export const SymbolValueMathBlockView = React.memo(SymbolValueMathBlockViewF);
