import { ValueTreeNode } from '../../enjc-symbol-value-tree';
import { IValueTreeViewContext } from '../model';
import { getNodeByKeyNVTV } from './getNodeByKeyNVTV';

export const collectTreeNodeDescendantsNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: ValueTreeNode,
  includeNode: boolean = false,
): ValueTreeNode[] => {
  const argNodes = node.arguments.map((argNode) => getNodeByKeyNVTV(vtvCtx, argNode.key));
  return [
    ...[includeNode ? [node] : []],
    argNodes,
    ...argNodes.map((argNode) => collectTreeNodeDescendantsNVTV(vtvCtx, argNode)),
  ].flat();
};
