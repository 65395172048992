import React, { ReactElement } from 'react';
import { assertNever } from '../../../../utils';
import { EnjcGlyphPrimitive } from '../../../enjc-glyph/model';
import { EnjcGlyphGroupPlainView } from './EnjcGlyphGroupPlainView';

interface IProps {
  readonly glyphPrimitive: EnjcGlyphPrimitive;
}

export const EnjcGlyphPrimitivePlainView = ({ glyphPrimitive }: IProps): ReactElement => {
  switch (glyphPrimitive.__typename) {
    case 'EnjcGlyphGroup':
      return <EnjcGlyphGroupPlainView glyphGroup={glyphPrimitive} />;
    case 'EnjcGlyphV':
      return <span>{glyphPrimitive.value}</span>;
    case 'EnjcGlyphCmd':
      return (
        <>
          {glyphPrimitive.args.map((cmdArg) => (
            <EnjcGlyphPrimitivePlainView glyphPrimitive={cmdArg} />
          ))}
        </>
      );
    default:
      return assertNever(glyphPrimitive);
  }
};
