export const NavKeyEnter = 'Enter';
export const NavKeyEscape = 'Escape';
export const NavKeyDelete = 'Delete';
export const NavKeyBackspace = 'Backspace';

export const NavKeyArrowLeft = 'ArrowLeft';
export const NavKeyArrowRight = 'ArrowRight';
export const NavKeyArrowUp = 'ArrowUp';
export const NavKeyArrowDown = 'ArrowDown';

export const NavKeySpace = ' ';
export const NavKeyComma = ',';

export const TNavKeyOperatorPlus = '+';
export const TNavKeyOperatorMinus = '-';
export const TNavKeyOperatorMultiply = '*';
export const TNavKeyOperatorDivide = '/';
export const TNavKeyOperatorPower = '^';

export const TNavKeyOperatorEqual = '=';
export const TNavKeyOperatorNotEqual = '!';
export const TNavKeyOperatorGreater = '>';
export const TNavKeyOperatorLess = '<';

export const TNavKeyBracketRoundOpen = '(';
export const TNavKeyBracketRoundClose = ')';
