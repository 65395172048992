import { getNodeKey, TValueTreeNodeOrKey, ValueTreeNode, mkValueTreeNodeRef, mkTreeFunction } from '../../tree-node';
import { IValueTreeViewContext, getTreeNodeParentInfoOrUndefinedNVTV } from '../../../enjc-value-view-ctx';
import { TValueFunctionId } from '../../../enjc-value-math';
import { generateEnjcTreeKeyNVTV } from '../../../enjc-value-view-ctx/utils-nvtv/generateEnjcTreeKeyNVTV';
import { ValueTreeClientDelta } from '../model';
import { calculateNodeDeltaNVTV } from './calculateNodeDeltaNVTV';

export const encloseValueTreeNode = (
  vtvCtx: IValueTreeViewContext,
  nodeOrKey: TValueTreeNodeOrKey,
  functionId: TValueFunctionId = 'fg.noop',
  draft: string = '',
): ValueTreeClientDelta => {
  // TODO: check if there is special method
  const newNode = mkTreeFunction(generateEnjcTreeKeyNVTV(vtvCtx, []), draft, functionId, [getNodeKey(nodeOrKey)]);

  const parentInfo = getTreeNodeParentInfoOrUndefinedNVTV(vtvCtx, nodeOrKey);
  const parentUpsert: ReadonlyArray<ValueTreeNode> = parentInfo
    ? [
        {
          ...parentInfo.node,
          arguments: [
            ...parentInfo.node.arguments.slice(0, parentInfo.index),
            mkValueTreeNodeRef(newNode.key),
            ...parentInfo.node.arguments.slice(parentInfo.index + 1),
          ],
        },
      ]
    : [];

  const nodesUpsert = [newNode, ...parentUpsert];
  const nodesDelta = nodesUpsert.map((nUp) => calculateNodeDeltaNVTV(vtvCtx, nUp));
  return {
    delta: { nodes: nodesDelta },
  };
};
