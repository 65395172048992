import { EnjcKeySetDelta } from '../model';

export const calculateKeySetDelta = (
  keySetBefore: ReadonlyArray<string>,
  keySetAfter: ReadonlyArray<string>,
): EnjcKeySetDelta | undefined => {
  const subsetBefore = keySetBefore.filter((t) => !keySetAfter.includes(t));
  const subsetAfter = keySetAfter.filter((t) => !keySetBefore.includes(t));
  const delta: EnjcKeySetDelta = {
    subsetBefore: subsetBefore.length > 0 ? subsetBefore : undefined,
    subsetAfter: subsetAfter.length > 0 ? subsetAfter : undefined,
  };
  return delta.subsetBefore || delta.subsetAfter ? delta : undefined;
};
