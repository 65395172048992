import { ValueTreeNode } from '../../tree-node';
import { ValueTreeFunctionInvalidArgumentIndexError } from '../../tree-errors';
import { IValueTreeViewContext, getNodeByKeyNVTV, collectTreeNodeDescendantsNVTV } from '../../../enjc-value-view-ctx';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { calculateNodeDeltaNVTV, mkNodeRemovalDelta } from '../utils';

export const deleteFunctionArgumentNVTV = (
  vtvCtx: IValueTreeViewContext,
  treeFunction: ValueTreeNode,
  index: number,
): ReadonlyArray<EnjcValueTreeNodeDelta> => {
  if (index < 0 || index >= treeFunction.arguments.length) {
    throw new ValueTreeFunctionInvalidArgumentIndexError(index, treeFunction.key, treeFunction.arguments.length);
  }

  const deletedArgument = getNodeByKeyNVTV(vtvCtx, treeFunction.arguments[index].key);
  const argBranchNodes = collectTreeNodeDescendantsNVTV(vtvCtx, deletedArgument, true);

  const nextTreeFunction: ValueTreeNode = {
    ...treeFunction,
    arguments: [...treeFunction.arguments.slice(0, index), ...treeFunction.arguments.slice(index + 1)],
  };

  const nodesUpsert = [nextTreeFunction];
  return [
    ...nodesUpsert.map((n) => calculateNodeDeltaNVTV(vtvCtx, n)),
    ...argBranchNodes.map((n) => mkNodeRemovalDelta(n)),
  ];
};
