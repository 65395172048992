import { createContext } from 'react';
import { EnjcSymbol } from '../../../../generated/graphql/types';
import { EnjicalcWorkspace } from '../../../enjc-workspace';
import { IValueTreeViewContext } from '../../../enjc-value-view-ctx';

export const ValueTreeViewContext = createContext<IValueTreeViewContext>({
  // TODO: review casting
  workspace: <EnjicalcWorkspace>{},
  // TODO: review casting
  symbol: <EnjcSymbol>{
    __typename: 'EnjcSymbol',
    id: '',
  },
  valueTreeCursor: { position: undefined },
  hasFocus: false,
  showValueHints: false,
});
