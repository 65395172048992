import React, { ReactElement, ReactNode } from 'react';
import { useWorkspacesList } from '../../enjc-react-client/hooks/useWorkspacesList';
import { LoadingPlaceholder } from '../../../../components/misc';
import { ApolloErrorMessage, ErrorMessage } from '../../../../components/errors';
import { WorkspacesListContextProvider } from './WorkspacesListContextProvider';

interface IProps {
  readonly children: ReactNode;
}

type FComp = (props: IProps) => ReactElement;

export const WorkspacesListContextLoader = React.memo<FComp>(({ children }) => {
  const { loading, error, workspaces, refetch } = useWorkspacesList();

  if (loading) return <LoadingPlaceholder name={'Workspaces List'} />;
  if (error) return <ApolloErrorMessage error={error} />;
  if (!workspaces) return <ErrorMessage description={'WorkspacesListContextLoader: workspaces is undefined'} />;

  return (
    <WorkspacesListContextProvider workspaces={workspaces} refetch={refetch}>
      {children}
    </WorkspacesListContextProvider>
  );
});
