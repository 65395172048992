import React, { ChangeEvent } from 'react';
import { Flex, Input } from '@chakra-ui/react';
import { calculateStringDeltaEntry, EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';

interface IProps {
  readonly value: string;
  readonly isInvalid?: boolean;
  readonly onValueDelta: (delta: EnjcStringDeltaEntry) => void;
}

export const SymbolTextLineDeltaInput = ({ value, onValueDelta }: IProps) => {
  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const enjcStringDelta = calculateStringDeltaEntry(value, event.target.value);
      enjcStringDelta && onValueDelta(enjcStringDelta);
    },
    [value, onValueDelta],
  );

  return (
    <Flex>
      <Input
        w="700px"
        fontFamily="monospace"
        fontSize="20px"
        onChange={handleChange}
        size="md"
        value={value}
        placeholder={''}
      />
    </Flex>
  );
};
