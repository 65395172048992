import { TValueTreeNodeKey, ValueTreeNode } from '../../enjc-symbol-value-tree/tree-node';
import { getTreeNodeByKeyOrUndefined } from '../../enjc-symbol-value-tree/tree-methods';
import { IValueTreeViewContext } from '../model';

export const getNodeByKeyOrUndefinedNVTV = (
  vtvCtx: IValueTreeViewContext,
  nodeKey: TValueTreeNodeKey,
): ValueTreeNode | undefined => {
  return getTreeNodeByKeyOrUndefined(vtvCtx.symbol.valueTree, nodeKey);
};
