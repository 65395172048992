import { EnjcValueTreeDelta } from '../../../../enjc-workspace-editing/model';
import { ValueTreeCursor } from '../../../../enjc-symbol-value-tree/tree-cursor';
import { ValueTreeClientDelta } from '../../../../enjc-symbol-value-tree/tree-editing';

export const processValueTreeClientDelta = (
  treeClientDelta: ValueTreeClientDelta,
  onSymbolValueDelta: (delta: EnjcValueTreeDelta) => void,
  setValueTreeCursor: (cursor: ValueTreeCursor) => void,
) => {
  if (treeClientDelta.delta) {
    onSymbolValueDelta(treeClientDelta.delta);
  }
  if (treeClientDelta.cursor) {
    setValueTreeCursor(treeClientDelta.cursor);
  }
};

// export const processValueTreeNavigationResult: FProcessValueTreeNavigationResult = (
//   { valueTreeNodesDiff, valueTreeCursor },
//   { updateValueTree, setValueTreeCursor },
// ) => {
//   if (updateValueTree && valueTreeNodesDiff) {
//     updateValueTree(valueTreeNodesDiff).then((r) => {
//       if (setValueTreeCursor && valueTreeCursor) {
//         setValueTreeCursor(valueTreeCursor);
//       }
//     });
//   } else {
//     if (setValueTreeCursor && valueTreeCursor) {
//       setValueTreeCursor(valueTreeCursor);
//     }
//   }
// };
