import { EnjcGlyphV, EnjcGlyphCmd, EnjcGlyphPrimitive } from '../model';

export const mkGlyphCmd = (cmd: string, args: EnjcGlyphPrimitive[]): EnjcGlyphCmd => {
  return {
    __typename: 'EnjcGlyphCmd',
    cmd,
    args,
  };
};

export const mkGlyphV = (value: string, knownString?: string): EnjcGlyphV => {
  return {
    __typename: 'EnjcGlyphV',
    value,
    knownString,
  };
};
