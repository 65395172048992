import { IOperatorsChain, IOperatorsChainAssemblyResult } from '../interfaces';
import { reduceOperatorsChainBody } from './reduceOperatorsChainBody';

export const assembleOperatorsChain = (operatorsChain: IOperatorsChain): IOperatorsChainAssemblyResult => {
  const reducedChain = reduceOperatorsChainBody({ operatorsChain, updatedNodes: [] });
  return {
    rootNode: reducedChain.operatorsChain.head,
    updatedNodes: reducedChain.updatedNodes,
  };
};
