import React, { ReactElement, useCallback } from 'react';
import { Popover, PopoverContent, PopoverTrigger, Calendar } from 'src/shadcn';
import { Box, Text } from '@chakra-ui/react';

interface IProps {
  name: string;
  date: Date | null;
  onSubmit?: (value: Date | null) => void;
}

export const SheetHeaderDateFieldPopoverF = ({ name, date, onSubmit }: IProps): ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(date);

  const handleSubmit = useCallback(
    (currentDate: Date | null) => {
      setSelectedDate(currentDate);
      onSubmit && onSubmit(selectedDate);
      setIsPopoverOpen(false);
    },
    [onSubmit, selectedDate],
  );

  return (
    <>
      <Box className="sheetHeader" w="100%" display="flex" h={'24px'} cursor="pointer">
        <Box as="span">{`${name}:`}</Box>

        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Text as="span" w="auto" pl="5px" color="black">
              {selectedDate ? selectedDate.toLocaleDateString() : 'N/A'}
            </Text>
          </PopoverTrigger>
          <PopoverContent
            className="w-auto p-0"
            align="start"
            style={{ transform: 'scale(0.85)', position: 'absolute', top: -30, left: -60 }}
          >
            <Calendar mode="single" selected={selectedDate as any} onSelect={handleSubmit as any} initialFocus />
          </PopoverContent>
        </Popover>
      </Box>
    </>
  );
};

export const SheetHeaderDateFieldPopover = React.memo(SheetHeaderDateFieldPopoverF);
