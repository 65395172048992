import { assertNever } from '../../../utils';
import {
  EnjcGlyphCmd,
  EnjcGlyphDivision,
  EnjcGlyphGroup,
  EnjcGlyphItem,
  EnjcGlyphPrimitive,
  EnjcGlyphSubSup,
  EnjcGlyphV,
} from '../model';
import { processParsedGlyphBracket, processParsedGlyphKnownString } from './processParsedGlyphKnownString';

export const processParsedGlyph = (parsedGlyph: ReadonlyArray<EnjcGlyphItem>): ReadonlyArray<EnjcGlyphItem> => {
  return parsedGlyph.map((glit) => {
    switch (glit.__typename) {
      case 'EnjcGlyphSubSup':
        return processParsedGlyphSubSup(glit);
      case 'EnjcGlyphDivision':
        return processParsedGlyphDivision(glit);
      default:
        return assertNever(glit);
    }
  });
};

export const processParsedGlyphSubSup = (glit: EnjcGlyphSubSup): EnjcGlyphSubSup => {
  return {
    __typename: 'EnjcGlyphSubSup',
    base: processParsedGlyphPrimitive(glit.base),
    subscript: glit.subscript && processParsedGlyphPrimitive(glit.subscript),
    superscript: glit.superscript && processParsedGlyphPrimitive(glit.superscript),
  };
};

export const processParsedGlyphDivision = (glit: EnjcGlyphDivision): EnjcGlyphDivision => {
  return {
    __typename: 'EnjcGlyphDivision',
    numerator: processParsedGlyphSubSup(glit.numerator),
    denominator: processParsedGlyphSubSup(glit.denominator),
  };
};

export const processParsedGlyphPrimitive = (glit: EnjcGlyphPrimitive): EnjcGlyphPrimitive => {
  switch (glit.__typename) {
    case 'EnjcGlyphGroup':
      return processParsedGlyphGroup(glit);
    case 'EnjcGlyphV':
      return processParsedGlyphV(glit);
    case 'EnjcGlyphCmd':
      return processParsedGlyphCmd(glit);
    default:
      return assertNever(glit);
  }
};

export const processParsedGlyphGroup = (glit: EnjcGlyphGroup): EnjcGlyphGroup => {
  return {
    __typename: 'EnjcGlyphGroup',
    items: processParsedGlyph(glit.items),
    lb: glit.lb && processParsedGlyphBracket(glit.lb),
    rb: glit.rb && processParsedGlyphBracket(glit.rb),
  };
};

export const processParsedGlyphV = (glit: EnjcGlyphV): EnjcGlyphV => {
  const processedValue = glit.knownString ? processParsedGlyphKnownString(glit.knownString) : glit.value;
  return {
    __typename: 'EnjcGlyphV',
    value: processedValue,
    knownString: glit.knownString,
  };
};

export const processParsedGlyphCmd = (glit: EnjcGlyphCmd): EnjcGlyphCmd => {
  return {
    __typename: 'EnjcGlyphCmd',
    cmd: glit.cmd,
    args: glit.args.map((cmdArg) => processParsedGlyphPrimitive(cmdArg)),
  };
};
