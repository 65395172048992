import { EnjcStringDeltaEntry } from '../model';

export const applyStringDeltaEntry = (value: string, deltaEntry: EnjcStringDeltaEntry): string => {
  const diffPosition = deltaEntry.slicePosition ?? 0;
  const diffCount = deltaEntry.sliceBefore?.length ?? 0;
  const diffValue = deltaEntry.sliceAfter ?? '';

  if (diffPosition > value.length) {
    console.error(`Invalid diffPosition: ${diffPosition} ${value.length}`, value, deltaEntry);
    // TODO: report error
  }

  if (diffCount < 0 || (diffPosition < 0 && diffCount > 0) || diffPosition + diffCount > value.length) {
    console.error(`Invalid diffPosition or diffCount: ${diffPosition} ${diffCount} ${value.length}`, value, deltaEntry);
    // TODO: report error
  }

  if (diffPosition < 0) {
    return diffValue;
  }

  return value.slice(0, diffPosition) + diffValue + value.slice(diffPosition + diffCount);
};
