import React, { ReactElement } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { IoCloseSharp, IoCreateOutline } from 'react-icons/io5';
import { AiOutlineInsertRowAbove, AiOutlineInsertRowBelow } from 'react-icons/ai';
import { VscKebabVertical } from 'react-icons/vsc';
import { isTreeNodeLiteral, TValueTreeNodeKey } from '../../../libenjc/enjc-symbol-value-tree/tree-node';
import { useEnjcRawWorkspaceMutations } from '../../../libenjc/enjc-react/enjc-react-client';
import { isLiteralVoid, mkLiteralNumber } from '../../../libenjc/enjc-literal';
import {
  EnjcWorkspaceItemVisibility,
  EnjicalcSymbol,
  EnjicalcWorkspace,
  getEnjcSymbolOrUndefined,
} from '../../../libenjc/enjc-workspace';
import { IValueTreeViewContext } from '../../../libenjc/enjc-value-view-ctx';
import { mkTreeCursor } from '../../../libenjc/enjc-symbol-value-tree/tree-cursor';
import { getTreeNodeByKey } from '../../../libenjc/enjc-symbol-value-tree/tree-methods';
import { ValueTreeViewContext } from '../../../libenjc/enjc-react/enjc-react-ui';
import {
  editCreateSymbolBeforeAfter,
  editDeleteSymbol,
  editHideSymbol,
  editMoveSymbolVertical,
  editSetSymbolValueToLiteral,
  WorkspaceEditHistoryEntry,
} from '../../../libenjc/enjc-workspace-editing';
import { DocumentItemIndexView, TextPopover } from '../../misc';
import { EnjcGlyphMathBlockView, EnjcUnitMathBlockView } from '../symbol-math-view';
import { SymbolValueTreeView } from './SymbolValueTreeView';
import { SymbolResultView } from './SymbolResultView';
import { convertWorkspaceEditHistoryEntryToDiffInput } from '../../../libenjc/enjc-client';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { RiDraggable } from 'react-icons/ri';
import { SymbolResultViewDraftEditable } from './SymbolResultViewDraftEditable';
import { WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { ValueTreeNodeMode } from '../../../generated/graphql/types';
import { useCtxEnjicalcSheet } from '../../../libenjc/enjc-react/enjc-react-context';
import { calculateStringDeltaEntry, EnjcStringDeltaEntry } from 'src/libenjc/enjc-delta';
import { ESections } from 'src/components/editors/symbol-editor/SymbolEditor';
import { QUICK_START_TOUR_STEP_COUNT, QUICK_START_TOUR_STEP_NAMES, useQuickStartTour } from 'src/hooks';

export interface ISymbolDocumentViewProps {
  readonly workspace: EnjicalcWorkspace;
  readonly symbol: EnjicalcSymbol;
  readonly documentItemIndex: number;
  readonly isSymbolModalOpen: boolean;
  readonly setSymbolOpen: (state: boolean, section?: ESections) => void;
  readonly dndListeners: SyntheticListenerMap | undefined;
}

export const SymbolDocumentView = ({
  workspace,
  symbol,
  documentItemIndex,
  setSymbolOpen,
  dndListeners,
}: ISymbolDocumentViewProps): ReactElement => {
  const { setCurrentStep, isAlpha } = useQuickStartTour();

  const { updateWorkspace } = useEnjcRawWorkspaceMutations();
  const { sheet } = useCtxEnjicalcSheet();

  const { glyph: symbolGlyph, unit: symbolUnit, description: symbolDescription, comment: symbolComment } = symbol;

  const rootNode =
    (symbol.valueTree.rootNode?.key && getTreeNodeByKey(symbol.valueTree, symbol.valueTree.rootNode.key)) || undefined;
  const symbolIsSolidLiteral = rootNode && isTreeNodeLiteral(rootNode);

  const [showOverlayButtons, setShowOverlayButtons] = React.useState(false);
  const [highlightSymbolRow, setHighlightSymbolRow] = React.useState(false);

  const handleValueTreeNodeSelected = React.useCallback((nodeKey: TValueTreeNodeKey) => {
    // FIXME: implement
  }, []);

  const sheetSymbolIndex = sheet.symbols.findIndex((sy) => sy.id === symbol.id);
  const usedSymbols = symbol.valueTree.nodes
    .filter((n) => n.mode === ValueTreeNodeMode.Symbol)
    .map((node) => node.symbol?.id ?? '')
    .filter((syId) => syId.length > 0);

  const errorOrder = usedSymbols.find((uSy) => {
    return sheet.symbols.findIndex((sSy) => sSy.id === uSy) >= sheetSymbolIndex;
  });

  const errorOrderName = errorOrder ? sheet.symbols.find((sSy) => sSy.id === errorOrder)?.glyph : null;

  // FIXME: review (looks like archived nodes are converted to literals)
  const symbolValueNodeArchivedSymbols =
    symbol.valueTree.nodes.find(
      (n) =>
        n.symbol?.id !== undefined &&
        n.mode === ValueTreeNodeMode.Symbol &&
        getEnjcSymbolOrUndefined(workspace, n.symbol?.id)?.visibility !== EnjcWorkspaceItemVisibility.Visible,
    ) !== undefined;
  const isLiteralVoidSymbols = isLiteralVoid(symbol.valueTree.result);

  const saveWorkspace = React.useCallback(
    (hEntry: WorkspaceEditHistoryEntry) => {
      updateWorkspace(workspace.id, -1, {
        items: [convertWorkspaceEditHistoryEntryToDiffInput(hEntry)],
      });
    },
    [updateWorkspace, workspace.id],
  );

  const handleArchiveSymbol = React.useCallback(() => {
    const hEntry = editHideSymbol(workspace, symbol.id);
    saveWorkspace(hEntry);
  }, [saveWorkspace, symbol.id, workspace]);

  const handleDeleteSymbol = React.useCallback(() => {
    const hEntry = editDeleteSymbol(workspace, symbol.id);
    saveWorkspace(hEntry);
  }, [saveWorkspace, symbol.id, workspace]);

  const moveSymbolUp = React.useCallback(() => {
    const hEntry = editMoveSymbolVertical(workspace, symbol.id, true);
    saveWorkspace(hEntry);
  }, [saveWorkspace, symbol.id, workspace]);

  const moveSymbolDown = React.useCallback(() => {
    const hEntry = editMoveSymbolVertical(workspace, symbol.id, false);
    saveWorkspace(hEntry);
  }, [saveWorkspace, symbol.id, workspace]);

  const addSymbolAboveBelow = React.useCallback(
    (addBelow: boolean) => {
      const workspaceDelta = editCreateSymbolBeforeAfter(workspace, symbol.id, addBelow);
      updateWorkspace(workspace.id, -1, {
        items: [convertWorkspaceEditHistoryEntryToDiffInput(workspaceDelta)],
      });
    },
    [symbol.id, updateWorkspace, workspace],
  );

  const handleLiteralChange = React.useCallback(
    (n: number) => {
      const hEntry = editSetSymbolValueToLiteral(symbol, mkLiteralNumber(n), undefined);
      saveWorkspace(hEntry);
    },
    [saveWorkspace, symbol],
  );

  const handleLiteralSave = React.useCallback(
    (draft: string) => {
      const vFloat = Number(draft);
      const literalNumber = mkLiteralNumber(vFloat);
      const hEntry = editSetSymbolValueToLiteral(symbol, literalNumber, draft);
      saveWorkspace(hEntry);
    },
    [saveWorkspace, symbol],
  );

  const handleSaveSymbolPart = React.useCallback(
    (part: 'glyph' | 'unit', newValue: string) => {
      const delta = calculateStringDeltaEntry(symbol[part], newValue) || ({} as EnjcStringDeltaEntry);

      const item = {
        title: `Edit Symbol ${part.charAt(0).toUpperCase() + part.slice(1)}`,
        timestamp: Date.now(),
        delta: {
          symbol: [{ id: symbol.id, [part]: [delta] }],
        },
      };

      updateWorkspace(workspace.id, -1, {
        items: [item].slice(0, delta.slicePosition).map((he) => convertWorkspaceEditHistoryEntryToDiffInput(he)),
      });
    },
    [symbol, updateWorkspace, workspace.id],
  );

  const handleSaveSymbolName = (symbolName: string) => {
    handleSaveSymbolPart('glyph', symbolName);
  };

  const handleSaveUnit = (symbolUnitValue: string) => {
    handleSaveSymbolPart('unit', symbolUnitValue);
  };

  const valueTreeViewCtx: IValueTreeViewContext = React.useMemo(
    () => ({
      workspace,
      symbol,
      valueTreeCursor: mkTreeCursor(undefined),
      valueHintItem: undefined,
      hasFocus: false,
      showValueHints: false,
    }),
    [symbol, workspace],
  );

  return (
    <Flex
      direction="column"
      bg={highlightSymbolRow ? '#f4f8fa' : undefined}
      onMouseOver={() => setShowOverlayButtons(true)}
      onMouseLeave={() => setShowOverlayButtons(false)}
    >
      <DocumentItemIndexView index={documentItemIndex} />
      {showOverlayButtons && (
        <RiDraggable {...dndListeners} size={17} color="black" style={{ position: 'absolute', top: 5, left: -75 }} />
      )}
      <Box
        className="noPrint"
        as="span"
        pos="relative"
        left="780px"
        w="0px"
        h="0px"
        display="flex"
        onMouseOver={() => setHighlightSymbolRow(true)}
        onMouseLeave={() => setHighlightSymbolRow(false)}
      >
        <HStack position={'absolute'} left={0}>
          <Menu>
            <MenuButton
              onClick={(e) => {
                e.stopPropagation();
                if (isAlpha) {
                  setCurrentStep(QUICK_START_TOUR_STEP_COUNT.ModifySymbol);
                } else {
                  setCurrentStep(QUICK_START_TOUR_STEP_COUNT.ModifySymbolBetta);
                }
              }}
              as={IconButton}
              aria-label="Symbol Options"
              icon={<VscKebabVertical />}
              w={4}
              h={25}
              m={0}
              variant={'ghost'}
              _hover={{ bg: 'none' }}
              _active={{ bg: 'none' }}
              className={
                documentItemIndex === 0
                  ? QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenu
                  : documentItemIndex === 1
                    ? QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuBetta
                    : QUICK_START_TOUR_STEP_NAMES.OpenSymbolMenuGamma
              }
            />
            <MenuList
              className={
                documentItemIndex === 0
                  ? QUICK_START_TOUR_STEP_NAMES.ModifySymbol
                  : documentItemIndex === 1
                    ? QUICK_START_TOUR_STEP_NAMES.ModifySymbolBetta
                    : QUICK_START_TOUR_STEP_NAMES.ModifySymbolGamma
              }
            >
              <MenuItem
                fontSize={'14px'}
                pr={'20px'}
                icon={<IoCreateOutline size={17} color="black" />}
                onClick={() => {
                  setSymbolOpen(true);
                  if (isAlpha) {
                    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolName);
                  } else if (documentItemIndex === 1) {
                    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolNameBetta);
                  } else {
                    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.WriteSymbolNameGamma);
                  }
                }}
              >
                {'Modify Symbol'}
              </MenuItem>
              {/* TODO: return when un-archive feature is available */}
              {/* <MenuItem
                fontSize={'14px'}
                pr={'20px'}
                icon={<IoCloseSharp size={17} color="black" />}
                onClick={handleArchiveSymbol}
              >
                {'Archive Symbol'}
              </MenuItem> */}
              {/* FIXME: review menu style, implement confirmation dialog */}
              <MenuItem
                fontSize={'14px'}
                pr={'20px'}
                icon={<IoCloseSharp size={17} color="red" />}
                onClick={handleDeleteSymbol}
              >
                {'Delete Symbol'}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                fontSize={'14px'}
                pr={'20px'}
                icon={<AiOutlineInsertRowAbove size={17} color="black" />}
                onClick={() => addSymbolAboveBelow(false)}
              >
                {'Create Symbol Above'}
              </MenuItem>
              <MenuItem
                fontSize={'14px'}
                pr={'20px'}
                icon={<AiOutlineInsertRowBelow size={17} color="black" />}
                onClick={() => addSymbolAboveBelow(true)}
              >
                {'Create Symbol Below'}
              </MenuItem>
            </MenuList>
          </Menu>
          {errorOrder && (
            <Tooltip maxW="150px" label={`Move symbol "${errorOrderName}" before the current symbol`}>
              <WarningTwoIcon className="symbolError" />
            </Tooltip>
          )}
          {(symbolValueNodeArchivedSymbols || isLiteralVoidSymbols) && (
            <Tooltip maxW="150px" label="Check if symbol inside is archived">
              <WarningIcon
                color="rgba(255, 132, 0)"
                className="symbolError"
                style={{ scrollMarginBottom: window.innerHeight / 2 }}
              />
            </Tooltip>
          )}
        </HStack>
      </Box>

      <Box as="span">
        <Flex className="tableRow">
          <Text className="showIndex hideIndex">{documentItemIndex}.</Text>
          <div className={'emptyCell noPrint'} />
          <div className={'description editableDescription'} onClick={() => setSymbolOpen(true, ESections.comment)}>
            {symbolDescription}
          </div>

          <TextPopover initialValue={symbolGlyph} onSave={handleSaveSymbolName}>
            <div className={'symbol editableSymbolName'}>
              <EnjcGlyphMathBlockView glyph={symbolGlyph} />
            </div>
          </TextPopover>

          {symbolIsSolidLiteral ? (
            <SymbolResultViewDraftEditable
              draft={rootNode?.draft ?? ''}
              literal={symbol.valueTree.result}
              onValueSave={handleLiteralSave}
            />
          ) : (
            <SymbolResultView literal={symbol.valueTree.result} />
          )}

          <TextPopover initialValue={symbolUnit} onSave={handleSaveUnit}>
            <div className={`unit`}>
              <EnjcUnitMathBlockView unit={symbolUnit} optional={true} />
            </div>
          </TextPopover>

          <div className={'comment editableComment'} onClick={() => setSymbolOpen(true, ESections.comment)}>
            {symbolComment || ' '}
          </div>
        </Flex>

        {!symbolIsSolidLiteral && (
          <Flex onClick={() => setSymbolOpen(true)}>
            <div className={'formula editableFormula'}>
              <Flex m={'15px'}>
                <ValueTreeViewContext.Provider value={valueTreeViewCtx}>
                  <SymbolValueTreeView symbol={symbol} onValueTreeNodeSelected={handleValueTreeNodeSelected} />
                </ValueTreeViewContext.Provider>
              </Flex>
            </div>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
