export const processParsedGlyphKnownString = (knownString: string): string => {
  const unicodeString = parsedGlyphKnownStrings.find(
    (pgks) =>
      pgks.aliases.includes(knownString) || (knownString[0] === '\\' && pgks.aliases.includes(knownString.slice(1))),
  );

  return unicodeString ? unicodeString.unicodeString : knownString;
};

export const mkKnownString = (unicodeString: string, aliases: ReadonlyArray<string>): GlyphKnownStringSpec => {
  return {
    unicodeString,
    aliases,
  };
};

export const parsedGlyphKnownStrings = [
  mkKnownString('+', ['+']),
  mkKnownString('−', ['-']),
  mkKnownString('⋅', ['*', 'cdot']),
  mkKnownString('*', ['**', 'ast']),
  mkKnownString('⋆', ['***', 'star']),
  mkKnownString('/', ['//']),
  mkKnownString('\\', ['\\\\', 'backslash', 'setminus']),
  mkKnownString('×', ['xx', 'times']),
  mkKnownString('÷', ['-:', 'div']),
  mkKnownString('⋉', ['|><', 'ltimes']),
  mkKnownString('⋊', ['><|', 'rtimes']),
  mkKnownString('⋈', ['|><|', 'bowtie']),
  mkKnownString('∘', ['@', 'circ']),
  mkKnownString('⊕', ['o+', 'oplus']),
  mkKnownString('⊗', ['ox', 'otimes']),
  mkKnownString('⊙', ['o.', 'odot']),
  mkKnownString('∑', ['sum']),
  mkKnownString('∏', ['prod']),
  mkKnownString('∧', ['^^', 'wedge']),
  mkKnownString('⋀', ['^^^', 'bigwedge']),
  mkKnownString('∨', ['vv', 'vee']),
  mkKnownString('⋁', ['vvv', 'bigvee']),
  mkKnownString('∩', ['nn', 'cap']),
  mkKnownString('⋂', ['nnn', 'bigcap']),
  mkKnownString('∪', ['uu', 'cup']),
  mkKnownString('⋃', ['uuu', 'bigcup']),

  mkKnownString('∫', ['int']),
  mkKnownString('∮', ['oint']),
  mkKnownString('∂', ['del', 'partial']),
  mkKnownString('∇', ['grad', 'nabla']),
  mkKnownString('±', ['+-', 'pm']),
  mkKnownString('∅', ['O/', 'emptyset']),
  mkKnownString('∞', ['oo', 'infty']),
  mkKnownString('ℵ', ['aleph']),
  mkKnownString('∴', [':.', 'therefore']),
  mkKnownString('∵', [":'", 'because']),
  // TODO: review if latex alt is supported by the parser
  mkKnownString('|...|', ['|...|', '|ldots|']),
  mkKnownString('|⋯|', ['|cdots|']),
  mkKnownString('⋮', ['vdots']),
  mkKnownString('⋱', ['ddots']),
  // TODO: review next two
  mkKnownString('| |', []),
  mkKnownString('|  |', []),

  mkKnownString('∠', ['/_', 'angle']),
  mkKnownString('⌢', ['frown']),
  mkKnownString('△', ['/_\\', 'triangle']),
  mkKnownString('⋄', ['diamond']),
  mkKnownString('□', ['square']),
  mkKnownString('⌊', ['|__', 'lfloor']),
  mkKnownString('⌋', ['__|', 'rfloor']),
  mkKnownString('⌈', ['|~', 'lceiling']),
  mkKnownString('⌉', ['~|', 'rceiling']),
  mkKnownString('C', ['CC']),
  mkKnownString('N', ['NN']),
  mkKnownString('Q', ['QQ']),
  mkKnownString('R', ['RR']),
  mkKnownString('Z', ['ZZ']),

  mkKnownString('=', ['=']),
  mkKnownString('≠', ['!=', 'ne']),
  mkKnownString('<', ['<', 'lt']),
  mkKnownString('>', ['>', 'gt']),
  mkKnownString('≤', ['<=', 'le']),
  mkKnownString('≥', ['>=', 'ge']),
  mkKnownString('m<', ['mlt', 'll']),
  mkKnownString('m>', ['mgt', 'gg']),
  mkKnownString('≺', ['-<', 'prec']),
  mkKnownString('⪯', ['-<=', 'preceq']),
  mkKnownString('≻', ['>-', 'succ']),
  mkKnownString('⪰', ['>-=', 'succeq']),
  mkKnownString('∈', ['in']),
  mkKnownString('∉', ['!in', 'notin']),
  mkKnownString('⊂', ['sub', 'subset']),
  mkKnownString('⊃', ['sup', 'supset']),
  mkKnownString('⊆', ['sube', 'subseteq']),
  mkKnownString('⊇', ['supe', 'supseteq']),
  mkKnownString('≡', ['-=', 'equiv']),
  mkKnownString('≅', ['~=', 'cong']),
  mkKnownString('≈', ['~~', 'approx']),
  mkKnownString('∝', ['prop', 'propto']),

  mkKnownString('and', ['and']),
  mkKnownString('or', ['or']),
  mkKnownString('¬', ['not', 'neg']),
  mkKnownString('⇒', ['=>', 'implies']),
  mkKnownString('if', ['if']),
  mkKnownString('⇔', ['<=>', 'iff']),
  mkKnownString('∀', ['AA', 'forall']),
  mkKnownString('∃', ['EE', 'exists']),
  mkKnownString('⊥', ['_|_', 'bot']),
  mkKnownString('⊤', ['TT', 'top']),
  mkKnownString('⊢', ['|--', 'vdash']),
  mkKnownString('⊨', ['|==', 'models']),

  mkKnownString('↑', ['uarr', 'uparrow']),
  mkKnownString('↓', ['darr', 'downarrow']),
  mkKnownString('→', ['rarr', 'rightarrow']),
  mkKnownString('→', ['->', 'to']),
  mkKnownString('↣', ['>->', 'rightarrowtail']),
  mkKnownString('↠', ['->>', 'twoheadrightarrow']),
  mkKnownString('⤖', ['>->>', 'twoheadrightarrowtail']),
  mkKnownString('↦', ['|->', 'mapsto']),
  mkKnownString('←', ['larr', 'leftarrow']),
  mkKnownString('↔', ['harr', 'leftrightarrow']),
  mkKnownString('⇒', ['rArr', 'Rightarrow']),
  mkKnownString('⇐', ['lArr', 'Leftarrow']),
  mkKnownString('⇔', ['hArr', 'Leftrightarrow']),

  mkKnownString('α', ['alpha']),
  mkKnownString('β', ['beta']),
  mkKnownString('γ', ['gamma']),
  mkKnownString('δ', ['delta']),
  mkKnownString('ε', ['epsilon']),
  mkKnownString('ɛ', ['varepsilon']),
  mkKnownString('ζ', ['zeta']),
  mkKnownString('η', ['eta']),
  mkKnownString('θ', ['theta']),
  mkKnownString('ϑ', ['vartheta']),
  mkKnownString('ι', ['iota']),
  mkKnownString('κ', ['kappa']),
  // mkKnownString('ϰ', ['varkappa']),
  mkKnownString('λ', ['lambda']),
  mkKnownString('μ', ['mu']),
  mkKnownString('ν', ['nu']),
  mkKnownString('ξ', ['xi']),
  // mkKnownString('ο', ['omicron']),
  mkKnownString('π', ['pi']),
  // mkKnownString('ϖ', ['varpi']),
  mkKnownString('ρ', ['rho']),
  // mkKnownString('ρ', ['varrho']),
  mkKnownString('σ', ['sigma']),
  // mkKnownString('𝜍', ['varsigma']),
  mkKnownString('τ', ['tau']),
  mkKnownString('υ', ['upsilon']),
  mkKnownString('ϕ', ['phi']),
  mkKnownString('φ', ['varphi']),
  mkKnownString('χ', ['chi']),
  mkKnownString('ψ', ['psi']),
  mkKnownString('ω', ['omega']),
  mkKnownString('Γ', ['Gamma']),
  mkKnownString('Δ', ['Delta']),
  mkKnownString('Θ', ['Theta']),
  mkKnownString('Λ', ['Lambda']),
  mkKnownString('Ξ', ['Xi']),
  mkKnownString('Π', ['Pi']),
  mkKnownString('Σ', ['Sigma']),
  // mkKnownString('Υ', ['Upsilon']),
  mkKnownString('Φ', ['Phi']),
  mkKnownString('Ψ', ['Psi']),
  mkKnownString('Ω', ['Omega']),
];

export interface GlyphKnownStringSpec {
  unicodeString: string;
  aliases: ReadonlyArray<string>;
}

export const processParsedGlyphBracket = (bracketString: string): string => {
  const unicodeString = parsedGlyphBrackets.find((pgks) => pgks.aliases.includes(bracketString));
  return unicodeString ? unicodeString.unicodeString : bracketString;
};

export const parsedGlyphBrackets = [
  mkKnownString('(', ['(']),
  mkKnownString(')', [')']),
  mkKnownString('[', ['[']),
  mkKnownString(']', [']']),
  mkKnownString('{', ['{']),
  mkKnownString('}', ['}']),
  mkKnownString('⟨', ['(:']),
  mkKnownString('⟩', [':)']),
  mkKnownString('⟨', ['<<']),
  mkKnownString('⟩', ['>>']),
  mkKnownString('', ['{:']),
  mkKnownString('', [':}']),
];
