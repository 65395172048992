import { isTreeNodeLiteral, mkTreeLiteral, ValueTreeNode } from '../../tree-node';
import { processLiteralDraft } from '../../tree-editing';

export const updateNodeDraft = (node: ValueTreeNode, nextDraft: string): ValueTreeNode => {
  if (isTreeNodeLiteral(node)) {
    return mkTreeLiteral(node.key, nextDraft, processLiteralDraft(nextDraft));
  } else {
    return {
      ...node,
      draft: nextDraft,
    };
  }
};
