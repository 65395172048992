import { EnjcValueFunction } from '../../enjc-value-math';
import { IValueTreeViewContext } from '../model';
import { getValueFunctionsListNVTV } from './getValueFunctionsListNVTV';

export const getValueFunctionByDraftNVTV = (
  vtvCtx: IValueTreeViewContext,
  draft: string,
): EnjcValueFunction | undefined => {
  return getValueFunctionsListNVTV(vtvCtx).find((item) => item.glyph === draft);
};
