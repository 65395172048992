import React, { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardBody, HStack, Icon, Input, Text, Tooltip, useDisclosure, VStack } from '@chakra-ui/react';
import { getStringColor } from '../../../utils/getStringColor';
import { EnjcWorkspaceItemVisibility, EnjicalcWorkspaceMeta } from '../../../libenjc/enjc-workspace';
import {
  editSetWorkspaceTitle,
  EnjicalcWorkspaceUpdateResult,
  WorkspaceEditHistoryEntry,
} from '../../../libenjc/enjc-workspace-editing';
import { WorkspaceMenu } from './WorkspaceMenu';
import { EnjcTimeAgo } from './EnjcTimeAgo';
import { TbArchiveFilled } from 'react-icons/tb';
import { QUICK_START_TOUR_STEP_NAMES } from 'src/hooks';

interface IProps {
  readonly workspace: EnjicalcWorkspaceMeta;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateResult>;
}

const WorkspaceCardF = ({ workspace, performWorkspaceEdit }: IProps): ReactElement => {
  const navigate = useNavigate();
  const { id: workspaceId, title } = workspace;
  const workspaceColor = getStringColor(workspaceId);

  const { isOpen: isOpenRenameTitle, onOpen: onOpenRenameTitle, onClose: onCloseRenameTitle } = useDisclosure();

  const [titleValue, setTitleValue] = useState<string>(title);

  const setEnjcWorkspaceTitle = React.useCallback(
    (nextTitle: string) => {
      const hEntry = editSetWorkspaceTitle(workspace, nextTitle);
      performWorkspaceEdit(hEntry);
    },
    [performWorkspaceEdit, workspace],
  );

  const handleNavigate = useCallback(() => navigate(`/workspace/${workspaceId}`), [navigate, workspaceId]);

  const handleTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setTitleValue(e.target.value), []);

  const handleTitleKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setEnjcWorkspaceTitle(titleValue);
        onCloseRenameTitle();
      }
    },
    [titleValue, setEnjcWorkspaceTitle, onCloseRenameTitle],
  );

  const handleInputClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }, []);

  const renderTitle = () => {
    if (isOpenRenameTitle) {
      return (
        <Input
          placeholder="Empty"
          value={titleValue}
          onKeyDown={handleTitleKeyDown}
          onChange={handleTitleChange}
          variant="flushed"
          focusBorderColor={workspaceColor}
          bg="#F3F5FA"
          paddingX={0}
          h="100%"
          fontSize="14px"
          autoFocus
          onClick={handleInputClick}
        />
      );
    }

    if (titleValue) {
      return (
        <Text isTruncated color="black">
          {titleValue}
        </Text>
      );
    }

    return <Text>Untitled</Text>;
  };

  return (
    <Card
      w={300}
      h={200}
      onClick={handleNavigate}
      cursor="pointer"
      bg="#F3F5FA"
      mr="50px"
      mb="50px"
      borderColor="black"
      variant="outline"
      _hover={{
        boxShadow: 'base',
      }}
      className={QUICK_START_TOUR_STEP_NAMES.OpenWorkspace}
    >
      <CardBody>
        <VStack align="stretch" justify="space-between" h="100%">
          <VStack align="stretch">
            <HStack justify="space-between">
              <HStack>
                <Box w="18px" h="18px" bg={workspaceColor} />
                <Box maxW="180px" flex={1} fontSize="14px">
                  {renderTitle()}
                </Box>
                {workspace.visibility !== EnjcWorkspaceItemVisibility.Visible && (
                  <Tooltip label={'This workspace is archived'}>
                    <Icon>
                      <TbArchiveFilled size={24} />
                    </Icon>
                  </Tooltip>
                )}
              </HStack>
              <WorkspaceMenu
                workspace={workspace}
                onOpenRenameTitle={onOpenRenameTitle}
                performWorkspaceEdit={performWorkspaceEdit}
              />
            </HStack>
          </VStack>

          <VStack align="stretch">
            <Text fontSize="14px">
              <EnjcTimeAgo date={workspace.created} />
            </Text>
          </VStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export const WorkspaceCard = React.memo(WorkspaceCardF);
