import React, { ReactElement } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { SymbolTextLineDeltaInput } from './SymbolTextLineDeltaInput';

interface IProps {
  readonly name: string;
  readonly text: string;
  readonly onTextDelta: (delta: EnjcStringDeltaEntry) => void;
  readonly isMandatory?: boolean;
}

export const SymbolTextLineFieldEditor = ({ name, text, onTextDelta, isMandatory }: IProps): ReactElement => {
  return (
    <HStack>
      <HStack gap={0}>
        <Text align="right" w="149px">
          {name}
        </Text>
        {isMandatory && <Text color="red">*</Text>}
        <Text>:</Text>
      </HStack>

      <SymbolTextLineDeltaInput value={text} onValueDelta={onTextDelta} />
    </HStack>
  );
};
