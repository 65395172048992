import { ValueFunctionFragment } from '../../enjicalc-graphql';
import { EnjcWorkspaceDTO, EnjicalcWorkspace } from '../model';
import { findSheetSections } from './findSheetSections';

export const mkEnjicalcWorkspace = (
  workspace: EnjcWorkspaceDTO,
  functions: ReadonlyArray<ValueFunctionFragment>,
): EnjicalcWorkspace => {
  const sheets = findSheetSections(workspace.sections);
  return {
    ...workspace,
    functions,
    sheets,
  };
};
