import { EnjcGlyphItem, EnjcGlyphGroup } from '../model';

export const mkGlyphGroup = (items: ReadonlyArray<EnjcGlyphItem>, lb: string, rb: string): EnjcGlyphGroup => {
  return {
    __typename: 'EnjcGlyphGroup',
    items,
    lb,
    rb,
  };
};
