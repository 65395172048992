import { EnjicalcWorkspaceMeta } from '../../enjc-workspace/model';
import { calculateStringDelta } from '../../enjc-delta';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry } from '../utils';

export const editSetWorkspaceTitle = (workspace: EnjicalcWorkspaceMeta, title: string): WorkspaceEditHistoryEntry => {
  const metaDeltaPart = calculateStringDelta(workspace.title, title);
  return mkWorkspaceEditHistoryEntry('editSetWorkspaceTitle', {
    meta: metaDeltaPart && [{ id: workspace.id, title: metaDeltaPart }],
  });
};
