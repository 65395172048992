import { EnjcSectionFragment } from '../../enjicalc-graphql';
import { EnjicalcSheet, EnjicalcSheetItem, EnjicalcWorkspace } from '../model';
import { collectSectionSymbols } from './collectSectionSymbols';
import { collectSectionItems } from './collectSectionItems';
import { isEnjcSectionRef } from './isEnjcSectionRef';
import { isEnjcSymbolRef } from './isEnjcSymbolRef';
import { assertNever } from '../../../utils';

export const mkEnjicalcSheet = (workspace: EnjicalcWorkspace, enjcSection: EnjcSectionFragment): EnjicalcSheet => {
  const symbols = collectSectionSymbols(workspace, enjcSection);
  const sheetItems = collectSectionItems(workspace, enjcSection).reduce(
    (acc, sectionItem) => {
      if (isEnjcSectionRef(sectionItem)) {
        return {
          items: [...acc.items, { itemData: sectionItem, itemOffset: null }],
          counter: acc.counter,
        };
      } else if (isEnjcSymbolRef(sectionItem)) {
        return {
          items: [...acc.items, { itemData: sectionItem, itemOffset: acc.counter }],
          counter: acc.counter + 1,
        };
      }
      return assertNever(sectionItem);
    },
    {
      items: [] as EnjicalcSheetItem[],
      counter: 0,
    },
  ).items;
  return { ...enjcSection, symbols, sheetItems };
};
