import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserButton } from '@clerk/clerk-react';
import { Flex, IconButton, VStack } from '@chakra-ui/react';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { RxDashboard } from 'react-icons/rx';
import { SlDocs } from 'react-icons/sl';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import { useEnjcRawWorkspaceMutations } from '../../libenjc/enjc-react/enjc-react-client';
import { MenuSettings } from './menus';
import { SideBarContext } from '../../context/SideBarContext';

interface IProps {}

const AppActivityBarF = ({}: IProps): ReactElement => {
  const navigate = useNavigate();

  const { createWorkspace } = useEnjcRawWorkspaceMutations();

  const { isShow, onToggle } = React.useContext(SideBarContext);

  const handleCreateWorkspace = React.useCallback(() => {
    createWorkspace().then((updateResult) => {
      navigate(`/workspace/${updateResult.workspace.id}`);
    });
  }, [createWorkspace, navigate]);

  return (
    <VStack
      alignItems="center"
      gap="3"
      w={'50px'}
      minW={50}
      h="100vh"
      bg="white"
      borderRightWidth={1}
      borderRightColor="lightgray"
    >
      <Flex align="center" justify="space-between" direction="column" h="99%">
        <VStack>
          <IconButton
            mt="4px"
            aria-label="dashboard"
            icon={<RxDashboard size={25} color="gray" />}
            variant="ghost"
            onClick={() => navigate('/dashboard')}
          />

          <IconButton
            aria-label="toggle sidebar"
            icon={isShow ? <GoSidebarExpand size={25} color="gray" /> : <GoSidebarCollapse size={25} color="gray" />}
            variant="ghost"
            onClick={onToggle}
          />

          {false && (
            <IconButton
              aria-label="create workspace"
              icon={<SlDocs size={25} color="gray" />}
              variant="ghost"
              onClick={handleCreateWorkspace}
            />
          )}
        </VStack>

        <VStack mb="15px">
          {/*{currentUser && currentUser.roles.find((role) => role == 'ADMIN') && (*/}
          {false && (
            <IconButton
              aria-label="admin"
              icon={<HiOutlineWrenchScrewdriver size={25} />}
              onClick={() => navigate('/admin')}
            />
          )}
          {false && <MenuSettings />}
          <UserButton userProfileUrl={'/profile'} />
        </VStack>
      </Flex>
    </VStack>
  );
};

export const AppActivityBar = React.memo(AppActivityBarF);
