import { TNode } from '../../../../enjc-symbol-value-tree/tree-core/types';
import { ValueTreeCursor } from '../../../../enjc-symbol-value-tree/tree-cursor';
import { isTreePositionNode } from '../../../../enjc-symbol-value-tree/tree-position';

export const getNodeCursorPositionAsDraft = (node: TNode, cursor: ValueTreeCursor | undefined): number | undefined => {
  if (!cursor?.position || cursor.position.nodeKey !== node.key) {
    return undefined;
  }

  if (isTreePositionNode(cursor.position)) {
    return cursor.position.atEnd ? node.draft.length : 0;
  } else {
    return cursor.position.at;
  }
};
