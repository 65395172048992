import { assertNever, formatNumerical } from '../../../utils';
import { UValueLiteral } from '../model';

export const valueLiteralToString = (literal: UValueLiteral | undefined | null): string => {
  switch (literal?.__typename) {
    case null:
    case undefined:
    case 'ValueLiteralVoid':
      return 'NaN';
    case 'ValueLiteralBoolean':
      return literal.bool ? 'true' : 'false';
    case 'ValueLiteralNumber':
      return formatNumerical(literal.numb);
    case 'ValueLiteralString':
      return `"${literal.str}"`;
    default:
      return assertNever(literal);
  }
};
