import React, { ReactElement } from 'react';
import { ISymbolDocumentViewProps, SymbolDocumentView } from './SymbolDocumentView';

interface IProps extends ISymbolDocumentViewProps {}

const SymbolDocumentEditorF = ({ symbol, ...props }: IProps): ReactElement => {
  return <SymbolDocumentView symbol={symbol} {...props} />;
};

export const SymbolDocumentEditor = React.memo(SymbolDocumentEditorF);
