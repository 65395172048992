import React, { ReactElement } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { EnjcStringDeltaEntry } from '../../../libenjc/enjc-delta';
import { SymbolTextAreaDeltaInput } from './SymbolTextAreaDeltaInput';

interface IProps {
  readonly name: string;
  readonly text: string;
  readonly onTextDelta: (delta: EnjcStringDeltaEntry) => void;
}

export const SymbolTextAreaFieldEditor = ({ name, text, onTextDelta }: IProps): ReactElement => {
  return (
    <HStack>
      <Text align="right" w="153px" alignSelf={'start'}>
        {name + ':'}
      </Text>
      <SymbolTextAreaDeltaInput value={text} onValueDelta={onTextDelta} />
    </HStack>
  );
};
