import React, { ReactElement } from 'react';
import { EnjcGlyphSubSup } from '../../../enjc-glyph/model';
import { MathMathMLAttributes } from '../../../enjc-react-mathml/attributes';
import { EnjcGlyphPrimitiveMathmlView } from './EnjcGlyphPrimitiveMathmlView';

interface IProps extends MathMathMLAttributes {
  readonly glyphSubSup: EnjcGlyphSubSup;
}

export const EnjcGlyphSubSupMathmlView = ({ glyphSubSup, ...mathProps }: IProps): ReactElement => {
  if (glyphSubSup.subscript && glyphSubSup.superscript) {
    return (
      <msubsup {...mathProps}>
        <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.base} />
        <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.subscript} />
        <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.superscript} />
      </msubsup>
    );
  }

  if (glyphSubSup.subscript && !glyphSubSup.superscript) {
    return (
      <msub {...mathProps}>
        <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.base} />
        <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.subscript} />
      </msub>
    );
  }

  if (!glyphSubSup.subscript && glyphSubSup.superscript) {
    return (
      <msup {...mathProps}>
        <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.base} />
        <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.superscript} />
      </msup>
    );
  }

  return (
    <mrow {...mathProps}>
      <EnjcGlyphPrimitiveMathmlView glyphPrimitive={glyphSubSup.base} />
    </mrow>
  );
};
