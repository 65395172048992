import React, { ReactElement, ReactNode } from 'react';
import { HStack } from '@chakra-ui/react';
import { ResizeDivider } from './ResizeDivider';

interface IProps {
  readonly position: 'left' | 'right';
  readonly children: ReactNode;
}

const INITIAL_SIDEBAR_WIDTH = 250;
const MINIMAL_SIDEBAR_WIDTH = 250;

const SidebarContainerF = ({ position, children }: IProps): ReactElement => {
  const [sidebarWidth, setSidebarWidth] = React.useState<number>(INITIAL_SIDEBAR_WIDTH);
  const [currentResizeDelta, setCurrentResizeDelta] = React.useState<number>(0);

  const handleResizeUpdate = React.useCallback((resizeDelta: number) => {
    setCurrentResizeDelta(resizeDelta);
  }, []);

  const handleResizeEnd = React.useCallback(
    (resizeDelta: number) => {
      setSidebarWidth((w) => w + resizeDelta * (position === 'left' ? 1 : -1));
      setCurrentResizeDelta(0);
    },
    [position],
  );

  return (
    <HStack
      w={sidebarWidth + currentResizeDelta * (position === 'left' ? 1 : -1)}
      minW={`${MINIMAL_SIDEBAR_WIDTH}px`}
      h={'100vh'}
      align={'stretch'}
      gap={0}
      borderRightWidth={1}
      borderRightColor="lightgray"
    >
      {position === 'right' && <ResizeDivider onResizeUpdate={handleResizeUpdate} onResizeEnd={handleResizeEnd} />}
      {children}
      {position === 'left' && <ResizeDivider onResizeUpdate={handleResizeUpdate} onResizeEnd={handleResizeEnd} />}
    </HStack>
  );
};

export const SidebarContainer = React.memo(SidebarContainerF);
