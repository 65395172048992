import { EnjicalcWorkspace } from '../../enjc-workspace';
import { EnjcWorkspaceDelta } from '../model';
import { applySectionDelta } from './applySectionDelta';
import { applySymbolDelta } from './applySymbolDelta';
import { applyWorkspaceMetaDelta } from './applyWorkspaceMetaDelta';

const getSectionItemTypename = (workspace: EnjicalcWorkspace, itemId: string) => {
  if (workspace.sections.find((s) => s.id === itemId)) return 'EnjcSection';
  if (workspace.symbols.find((s) => s.id === itemId)) return 'EnjcSymbol';
  throw new Error('Unknown section item type');
};

export const applyWorkspaceDelta = (workspace: EnjicalcWorkspace, delta: EnjcWorkspaceDelta): EnjicalcWorkspace => {
  // FIXME: implement new section / symbol creation

  const workspaceStage1 = (delta.meta ?? []).reduce((acc, d) => applyWorkspaceMetaDelta(acc, d), workspace);

  // FIXME: add support for multiple symbol delta by evaluating symbols in correct order (dependency graph)
  const workspaceStage2 = (delta.symbol ?? []).reduce(
    (acc, d) => ({ ...acc, symbols: acc.symbols.map((s) => (s.id === d.id ? applySymbolDelta(acc, s, d) : s)) }),
    workspaceStage1,
  );

  const getItemTypename = (itemId: string) => {
    return getSectionItemTypename(workspaceStage2, itemId);
  };

  const workspaceStage3 = (delta.section ?? []).reduce(
    (acc, d) => ({
      ...acc,
      sections: acc.sections.map((s) => (s.id === d.id ? applySectionDelta(s, d, getItemTypename) : s)),
    }),
    workspaceStage2,
  );

  return workspaceStage3;
};
