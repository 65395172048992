import { EnjcKeyListDeltaEntry } from '../model';
import { calculateKeyListDeltaEntry } from './calculateKeyListDeltaEntry';

export const calculateKeyListDelta = (
  keyListBefore: ReadonlyArray<string>,
  keyListAfter: ReadonlyArray<string>,
): ReadonlyArray<EnjcKeyListDeltaEntry> | undefined => {
  const deltaEntry = calculateKeyListDeltaEntry(keyListBefore, keyListAfter);
  return deltaEntry && [deltaEntry];
};
