import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Container, Heading, Text } from '@chakra-ui/react';

export const ErrorRoute: React.FC = () => {
  const error = useRouteError();
  console.error('Error route visited', error);

  if (isRouteErrorResponse(error)) {
    return (
      <Container>
        <Heading>Oops!</Heading>
        <Text>Error at {window.location.href} has occurred</Text>
        <Text>
          Status: {error.status} ({error.statusText})
        </Text>
        {error.data?.message && <Text>{error.data.message}</Text>}
      </Container>
    );
  } else {
    return (
      <Container>
        <Text>Unknown error has occurred</Text>
      </Container>
    );
  }
};
