import React, { ReactElement } from 'react';
import TimeAgo, { Formatter } from 'react-timeago';

interface IProps {
  readonly date: number;
}

const timeFormatter: Formatter = (value, unit, suffix, epochMilliseconds, nextFormatter) => {
  // Formatter reference: <https://github.com/nmn/react-timeago#formatter-optional>
  // TODO: review
  // return `${value} ${unit} ${suffix}`;
  return (nextFormatter && nextFormatter(value, unit, suffix, epochMilliseconds, undefined)) || '';
};

export const EnjcTimeAgo = ({ date }: IProps): ReactElement => {
  return <TimeAgo date={date} formatter={timeFormatter} />;
};
