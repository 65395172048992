import { assertNever } from '../../../../utils';
import { ValueTreeNode } from '../../tree-node';
import { UValueTreePosition } from '../types';
import { isTreePositionNode } from './isTreePositionNode';
import { isTreePositionNodeDraft } from './isTreePositionNodeDraft';

// TODO: review and probably remove
export const getValueTreePositionAtStartEnd = (
  node: ValueTreeNode,
  position: UValueTreePosition,
): undefined | 'start' | 'end' => {
  if (isTreePositionNode(position)) {
    return position.atEnd ? 'end' : 'start';
  }
  if (isTreePositionNodeDraft(position)) {
    return position.at === 0 ? 'start' : position.at === node.draft.length ? 'end' : undefined;
  }
  return assertNever(position);
};
