import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { WorkspaceContextLoader } from '../../libenjc/enjc-react/enjc-react-context';
import { ErrorMessage } from '../../components/errors';
import { WorkspaceSheets } from '../../app/enjc-workspace-sheets';

export const WorkspaceSheetsRoute = (): ReactElement => {
  const { workspaceId } = useParams<{ workspaceId: string }>();

  if (!workspaceId) {
    return <ErrorMessage title={'WorkspaceSymbolsRoute Error'} description={'undefined workspaceId'} />;
  }

  return (
    <WorkspaceContextLoader workspaceId={workspaceId}>
      <WorkspaceSheets />
    </WorkspaceContextLoader>
  );
};
