import React, { ReactElement, ReactNode } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { AppActivityBar } from '../app-activity-bar';

interface IProps {
  readonly children: ReactNode;
}

const AppPanelManagerF = ({ children }: IProps): ReactElement => {
  return (
    <HStack gap={'0'} alignItems={'stretch'} h={'100%'}>
      <AppActivityBar />
      <Box flexGrow={'1'}>{children}</Box>
    </HStack>
  );
};

export const AppPanelManager = React.memo(AppPanelManagerF);
