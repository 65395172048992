import { ValueTreeNode } from '../../enjc-symbol-value-tree/tree-node';
import { mkTreeCursorNodeDraft } from '../../enjc-symbol-value-tree/tree-cursor';
import { ValueTreeClientDelta } from '../../enjc-symbol-value-tree/tree-editing';
import { calculateNodeDeltaNVTV } from '../../enjc-symbol-value-tree/tree-editing/utils/calculateNodeDeltaNVTV';
import { updateNodeDraftNVTV } from './updateNodeDraftNVTV';
import { IValueTreeViewContext } from '../model';

export const spliceNodeDraftNVTV = (
  vtvCtx: IValueTreeViewContext,
  node: ValueTreeNode,
  at: number,
  len: number,
  text: string,
): ValueTreeClientDelta => {
  const nextDraft = node.draft.slice(0, at) + text + node.draft.slice(at + len);
  const nextNode = updateNodeDraftNVTV(vtvCtx, node, nextDraft);

  return {
    delta: {
      nodes: [calculateNodeDeltaNVTV(vtvCtx, nextNode)],
    },
    cursor: mkTreeCursorNodeDraft(node, at + text.length),
  };
};
