import React, { useEffect } from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';

interface IProps {
  title?: string;
  description?: string;
}

export const ErrorMessage: React.FC<IProps> = ({ title, description }) => {
  useEffect(() => console.error(`${title || 'ErrorMessage'}: ${description}`));
  return (
    <Alert status="error">
      <AlertIcon />
      {title && <AlertTitle>{title}</AlertTitle>}
      {description && <AlertDescription>{description}</AlertDescription>}
      {!description && !title && <AlertDescription>Error</AlertDescription>}
    </Alert>
  );
};
