import React from 'react';
import { formatApolloError } from '../../utils/formatApolloError';
import { ApolloError } from '@apollo/client';
import { ErrorMessage } from './ErrorMessage';

interface IProps {
  title?: string;
  error: ApolloError;
}

export const ApolloErrorMessage: React.FC<IProps> = ({ title, error }) => (
  <ErrorMessage title={title || 'Apollo Error'} description={formatApolloError(error)} />
);
