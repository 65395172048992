import { stringDeltaToInput } from '../../enjc-delta';
import { EnjcWorkspaceSymbolDelta } from '../../enjc-workspace-editing';
import { EnjcWorkspaceSymbolInput } from '../../enjicalc-graphql';
import { convertValueTreeNodeDeltaToDiffInput } from './convertValueTreeNodeDeltaToDiffInput';

export const convertWorkspaceSymbolDeltaToDiffInput = (delta: EnjcWorkspaceSymbolDelta): EnjcWorkspaceSymbolInput => {
  return {
    id: delta.id,
    visibility: delta.visibility?.atomAfter,
    glyph: delta.glyph && delta.glyph.map((d) => stringDeltaToInput(d, false)),
    glyphPlaintext: delta.glyphPlaintext && delta.glyphPlaintext.map((d) => stringDeltaToInput(d, false)),
    unit: delta.unit && delta.unit.map((d) => stringDeltaToInput(d, false)),
    comment: delta.comment && delta.comment.map((d) => stringDeltaToInput(d, false)),
    description: delta.description && delta.description.map((d) => stringDeltaToInput(d, false)),
    valueTree: delta.valueTree?.nodes && {
      nodes: delta.valueTree.nodes.map((d) => convertValueTreeNodeDeltaToDiffInput(d)),
    },
  };
};
