import React, { ReactElement } from 'react';
import {
  Box,
  Button,
  Code,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useCtxEnjicalcSheet, useCtxEnjicalcWorkspace } from '../../../libenjc/enjc-react/enjc-react-context';
import {
  EnjcWorkspaceItemVisibility,
  EnjicalcSheetItem,
  getEnjcSymbolOrUndefined,
  TSymbolId,
} from '../../../libenjc/enjc-workspace';
import { useEnjcRawWorkspaceMutations } from '../../../libenjc/enjc-react/enjc-react-client';
import { convertSheetUpdate, exportSheet, exportWorkspace } from '../../../libenjc/enjc-workspace-import-export';
import { EnjcWorkspaceSectionInput, ValueTreeNodeMode } from '../../../libenjc/enjicalc-graphql';
import {
  editMoveSymbolBeforeAfter,
  EnjicalcWorkspaceUpdateResult,
  WorkspaceEditHistoryEntry,
  editCreateSectionIn,
  editCreateSymbolIn,
} from '../../../libenjc/enjc-workspace-editing';
import { convertWorkspaceEditHistoryEntryToDiffInput } from '../../../libenjc/enjc-client';
import { extractUpdateCreatedSymbol } from '../../../libenjc/enjc-client/utils';
import { SymbolModalView } from '../symbol-document-editor/SymbolModalView';
import { useSearchParamsSheetDocumentModalSymbolId } from './useSearchParamsSheetDocumentModalSymbolId';
import { SheetHeader } from './SheetHeader';
import { SheetDocumentEditorItem } from './SheetDocumentEditorItem';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { QUICK_START_TOUR_STEP_COUNT, QUICK_START_TOUR_STEP_NAMES, usePrint, useQuickStartTour } from '../../../hooks';
import { PrintButton } from '../../buttons/PrintButton';
import { IoSettingsOutline } from 'react-icons/io5';
import { createPortal } from 'react-dom';
import { WarningIcon } from '@chakra-ui/icons';
import { LiaRedoSolid, LiaUndoSolid } from 'react-icons/lia';
import { isLiteralVoid } from 'src/libenjc/enjc-literal';
import { useLocation } from 'react-router-dom';
import { isNodeEnvDevelopment } from '../../../utils';
import { track } from '@vercel/analytics/react';
import { useUser } from '@clerk/clerk-react';

const SheetDocumentEditorF = (): ReactElement => {
  const { user } = useUser();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryActiveSheet = searchParams.get('active');

  const { setCurrentStep, isBeta, isAlpha, setIsOpen } = useQuickStartTour();

  const { workspace } = useCtxEnjicalcWorkspace();
  const { sheet } = useCtxEnjicalcSheet();
  const { updateWorkspace } = useEnjcRawWorkspaceMutations();

  const { ref, print } = usePrint(sheet.title);

  const { modalSymbolId, setModalSymbolId, section } = useSearchParamsSheetDocumentModalSymbolId();

  const [showImportSheetUpdateModal, setShowImportSheetUpdateModal] = React.useState(false);
  const [showExportWorkspaceModal, setShowExportWorkspaceModal] = React.useState(false);
  const [showExportSheetModal, setShowExportSheetModal] = React.useState(false);
  const [localSheetItems, setLocalSheetItems] = React.useState<EnjicalcSheetItem[]>(
    sheet.sheetItems as EnjicalcSheetItem[],
  );
  const [updateJson, setUpdateJson] = React.useState('');
  const [currentElementErrorIndex, setCurrentElementErrorIndex] = React.useState<number>(0);

  React.useEffect(() => {
    setLocalSheetItems(sheet.sheetItems as EnjicalcSheetItem[]);
  }, [sheet.sheetItems]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const performWorkspaceEdit = React.useCallback(
    (editEntry: WorkspaceEditHistoryEntry): Promise<EnjicalcWorkspaceUpdateResult> => {
      return updateWorkspace(workspace.id, -1, { items: [convertWorkspaceEditHistoryEntryToDiffInput(editEntry)] });
    },
    [updateWorkspace, workspace.id],
  );

  const handleSheetSectionCreate = React.useCallback(() => {
    const hEntry = editCreateSectionIn(workspace, sheet.id);
    performWorkspaceEdit(hEntry);
  }, [performWorkspaceEdit, sheet.id, workspace]);

  const handleSheetSymbolCreate = React.useCallback(() => {
    track('create-symbol', { emailAddress: `${user?.primaryEmailAddress}`, id: `${user?.id}` });
    const hEntry = editCreateSymbolIn(workspace, sheet.id);
    performWorkspaceEdit(hEntry)
      .then((updateResult) => {
        return extractUpdateCreatedSymbol(updateResult);
      })
      .then(() => {
        if (isAlpha) {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSymbolMenu);
        } else if (isBeta) {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSymbolMenuBetta);
        } else {
          setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenSymbolMenuGamma);
        }
      });
  }, [isAlpha, isBeta, user?.primaryEmailAddress, user?.id, workspace, sheet.id, performWorkspaceEdit, setCurrentStep]);

  const importSheetUpdate = React.useCallback(() => {
    const item = convertSheetUpdate(workspace, sheet, updateJson);
    updateWorkspace(workspace.id, -1, { items: [item] }).then((mutationResult) =>
      console.debug('[SheetDocumentEditor] importSheetUpdate:updateEnjcWorkspace mutationResult', mutationResult),
    );
  }, [sheet, updateWorkspace, updateJson, workspace]);

  const modalSymbol = React.useMemo(() => {
    return modalSymbolId && queryActiveSheet === sheet.id
      ? getEnjcSymbolOrUndefined(workspace, modalSymbolId)
      : undefined;
  }, [modalSymbolId, queryActiveSheet, sheet.id, workspace]);

  const moveSymbolBeforeAfter = React.useCallback(
    (symbolToMoveId: TSymbolId, symbolBeforeAfterId: TSymbolId, addAfter: boolean) => {
      const hEntry = editMoveSymbolBeforeAfter(workspace, symbolToMoveId, symbolBeforeAfterId, addAfter);
      performWorkspaceEdit(hEntry);
    },
    [performWorkspaceEdit, workspace],
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeIndex = sheet.sheetItems.findIndex((si) => si.itemData.id === active.id);
    const overIndex = sheet.sheetItems.findIndex((si) => si.itemData.id === over.id);

    if (overIndex !== -1 && activeIndex !== overIndex) {
      const symbolToMoveId = sheet.sheetItems[activeIndex].itemData.id;
      const symbolBeforeAfterId = sheet.sheetItems[overIndex].itemData.id;

      const addAfter = activeIndex < overIndex;
      setLocalSheetItems((items) => {
        const updatedItems = [...items];
        const [movedItem] = updatedItems.splice(activeIndex, 1);
        updatedItems.splice(overIndex, 0, movedItem);
        return updatedItems;
      });

      moveSymbolBeforeAfter(symbolToMoveId, symbolBeforeAfterId, addAfter);
    }
  };

  const updateSheet = React.useCallback(
    (diffInput: EnjcWorkspaceSectionInput) => {
      updateWorkspace(workspace.id, -1, {
        items: [{ title: '[FIXME] updateSheet', timestamp: Date.now().toString(), section: [diffInput] }],
      });
    },
    [updateWorkspace, workspace.id],
  );

  const hasErrorsToScroll = React.useMemo(() => {
    if (queryActiveSheet !== sheet.id) {
      return false;
    }

    return localSheetItems.some((item) => {
      if (item.itemData.__typename === 'EnjcSection') {
        return false;
      }

      if (isLiteralVoid(item.itemData.valueTree.result)) {
        return true;
      }

      const itemHasErrorInNodes = item.itemData.valueTree.nodes?.some(
        (node) =>
          node.symbol?.id !== undefined &&
          node.mode === ValueTreeNodeMode.Symbol &&
          getEnjcSymbolOrUndefined(workspace, node.symbol?.id)?.visibility !== EnjcWorkspaceItemVisibility.Visible,
      );

      if (itemHasErrorInNodes) {
        return true;
      }

      // New condition
      const sheetSymbolIndex = sheet.symbols.findIndex((sy) => sy.id === item.itemData.id);
      const usedSymbols = item.itemData.valueTree.nodes
        .filter((n) => n.mode === ValueTreeNodeMode.Symbol)
        .map((node) => node.symbol?.id ?? '')
        .filter((syId) => syId.length > 0);

      const errorOrder = usedSymbols.find((uSy) => {
        return sheet.symbols.findIndex((sSy) => sSy.id === uSy) >= sheetSymbolIndex;
      });

      const errorOrderName = errorOrder ? sheet.symbols.find((sSy) => sSy.id === errorOrder)?.glyph : null;

      return !!errorOrderName; // Return true if errorOrderName exists
    });
  }, [localSheetItems, workspace, queryActiveSheet, sheet]);

  const scrollToSymbolsError = () => {
    const elements = document.body.getElementsByClassName('symbolError');
    if (elements.length > 0) {
      elements[currentElementErrorIndex].scrollIntoView({ behavior: 'smooth', block: 'end' });
      setCurrentElementErrorIndex((prevIndex) => (prevIndex + 1) % elements.length);
    }
  };

  const renderItems = React.useMemo(() => {
    return (
      <>
        {localSheetItems.map((sheetItem, index) => (
          <SheetDocumentEditorItem
            key={sheetItem.itemData.id}
            item={sheetItem}
            index={index}
            modalSymbolId={modalSymbolId}
            setModalSymbolId={setModalSymbolId}
            performWorkspaceEdit={performWorkspaceEdit}
          />
        ))}
      </>
    );
  }, [localSheetItems, modalSymbolId, performWorkspaceEdit, setModalSymbolId]);

  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');

  return (
    <>
      {isLargerThan900 &&
        createPortal(
          <HStack
            pos="absolute"
            zIndex={1}
            height="35px"
            paddingLeft={5}
            paddingRight={5}
            paddingTop={1}
            top={0}
            right={0}
            backgroundColor="white"
            className="manage-sheet-buttons"
          >
            <HStack>
              <Tooltip label="Undo (Coming soon)">
                <IconButton
                  aria-label="Undo"
                  icon={<LiaUndoSolid />}
                  backgroundColor="transparent"
                  _hover={{ backgroundColor: 'transparent' }}
                  isDisabled
                />
              </Tooltip>

              <Tooltip label="Redo (Coming soon)">
                <IconButton
                  aria-label="Redo"
                  icon={<LiaRedoSolid />}
                  backgroundColor="transparent"
                  _hover={{ backgroundColor: 'transparent' }}
                  isDisabled
                />
              </Tooltip>
            </HStack>

            <Tooltip maxW="150px" label="Scroll to error">
              <IconButton
                aria-label="Scroll to error"
                icon={<WarningIcon />}
                onClick={scrollToSymbolsError}
                color="red"
                backgroundColor="transparent"
                _hover={{ backgroundColor: 'transparent' }}
                hidden={!hasErrorsToScroll}
              />
            </Tooltip>

            <PrintButton
              onClick={() => {
                print();
                setIsOpen(false);
              }}
            />

            {isNodeEnvDevelopment() && (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Symbol Options"
                  icon={<IoSettingsOutline />}
                  w={4}
                  h={25}
                  m={0}
                  variant={'ghost'}
                  _hover={{ bg: 'none' }}
                  _active={{ bg: 'none' }}
                />
                <MenuList>
                  <MenuItem fontSize={'15px'} pr={'20px'} onClick={() => setShowImportSheetUpdateModal(true)}>
                    {'Import sheet update'}
                  </MenuItem>
                  <MenuItem fontSize={'15px'} pr={'20px'} onClick={() => setShowExportWorkspaceModal(true)}>
                    {'Export workspace'}
                  </MenuItem>
                  <MenuItem fontSize={'15px'} pr={'20px'} onClick={() => setShowExportSheetModal(true)}>
                    {'Export sheet'}
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </HStack>,
          document.body,
        )}

      <VStack ref={ref} className="page" align={'stretch'} gap={'0'}>
        <SheetHeader workspace={workspace} sheet={sheet} updateSheet={updateSheet} />

        <Box>
          <Text color="rgba(0, 0, 0, 0.8)" fontSize="15px" fontWeight="600">
            {'Calculations'}
          </Text>

          <div className="tableRow">
            <div className={'emptyCell initColumn emptyCellPrint'} />
            <div className={'description initColumn'}>{'Description'}</div>
            <div className={'symbol initColumn'}>{'Symbol Name'}</div>
            <div className={'value initColumn'}>{'Value'}</div>
            <div className={'unit initColumn'}>{'Unit'}</div>
            <div className={'comment initColumn'}>{'Comment'}</div>
          </div>
        </Box>

        <DndContext
          modifiers={[restrictToVerticalAxis]}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={localSheetItems.map(({ itemData }) => itemData.id)}
            strategy={verticalListSortingStrategy}
          >
            {renderItems}
          </SortableContext>
        </DndContext>

        <HStack className="noPrint">
          <Button
            onClick={handleSheetSymbolCreate}
            m="5px 0px"
            w="210px"
            colorScheme="white"
            backgroundColor="primary.100"
            style={{ fontSize: '15px' }}
            _hover={{ bg: 'primary.200' }}
            _active={{ opacity: '0.95', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 24px 48px' }}
            className={
              isAlpha
                ? QUICK_START_TOUR_STEP_NAMES.AddSymbol
                : isBeta
                  ? QUICK_START_TOUR_STEP_NAMES.AddSymbolBetta
                  : QUICK_START_TOUR_STEP_NAMES.AddSymbolGamma
            }
          >
            {'Add Symbol'}
          </Button>
          <Button
            onClick={handleSheetSectionCreate}
            m="5px 0px"
            w="210px"
            variant="outline"
            colorScheme="blackAlpha"
            style={{ fontSize: '15px', color: 'black' }}
            _active={{ opacity: '0.95', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 24px 48px' }}
          >
            {'Add Section'}
          </Button>
        </HStack>

        <SymbolModalView
          workspace={workspace}
          symbol={modalSymbol}
          section={section}
          onClose={() => setModalSymbolId('')}
        />

        <Modal isOpen={showImportSheetUpdateModal} onClose={() => setShowImportSheetUpdateModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Import Sheet Update'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Textarea placeholder="Update JSON" value={updateJson} onChange={(e) => setUpdateJson(e.target.value)} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={importSheetUpdate}>{'Import'}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={showExportWorkspaceModal} onClose={() => setShowExportWorkspaceModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Export Workspace'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Code>
                <pre>{exportWorkspace(workspace)}</pre>
              </Code>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal isOpen={showExportSheetModal} onClose={() => setShowExportSheetModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{'Export Sheet'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Code>
                <pre>{exportSheet(workspace, sheet.id)}</pre>
              </Code>
            </ModalBody>
          </ModalContent>
        </Modal>
      </VStack>
    </>
  );
};

export const SheetDocumentEditor = React.memo(SheetDocumentEditorF);
