import React from 'react';
import { Center, Spinner, Text } from '@chakra-ui/react';

interface IProps {
  name?: string;
}

export const LoadingPlaceholder: React.FC<IProps> = ({ name }) => (
  <Center flex={1} gap={5} height="100%">
    <Spinner />
    <Text>{name ? `${name} is loading...` : 'Loading...'}</Text>
  </Center>
);
