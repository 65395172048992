import { EnjicalcWorkspace, TSymbolId, findWorkspaceItemContainer } from '../../enjc-workspace';
import { EnjcWorkspaceSectionDelta, EnjcWorkspaceSymbolDelta, WorkspaceEditHistoryEntry } from '../model';
import { WORKSPACE_CREATE_ID } from '../../../libenjc/enjc-client/constants';
import { mkLiteralNumber, mkLiteralVoidNull } from '../../../libenjc/enjc-literal';

export const editCreateSymbolBeforeAfter = (
  workspace: EnjicalcWorkspace,
  symbolBeforeAfterId: TSymbolId,
  addAfter: boolean,
): WorkspaceEditHistoryEntry => {
  const containerDstInfo = findWorkspaceItemContainer(workspace, symbolBeforeAfterId);
  if (!containerDstInfo) {
    // TODO: report error
    throw new Error('Dst container not found');
  }

  const symbolDelta: EnjcWorkspaceSymbolDelta = {
    id: WORKSPACE_CREATE_ID,
    valueTree: {
      nodes: [
        {
          key: 'ab',
          // mode: ValueTreeNodeMode.Literal,
          // This literal works as a more recognizable (compared to square of
          // null literal) placeholder for initial empty draft
          literal: {
            atomBefore: mkLiteralVoidNull(),
            atomAfter: mkLiteralNumber(0),
          },
        },
      ],
    },
  };

  const sectionDelta: EnjcWorkspaceSectionDelta = {
    id: containerDstInfo.container.id,
    items: [{ slicePosition: containerDstInfo.index + (addAfter ? 1 : 0), sliceAfter: [WORKSPACE_CREATE_ID] }],
  };

  return {
    title: 'editCreateSymbolBeforeAfter',
    timestamp: Date.now(),
    delta: {
      section: [sectionDelta],
      symbol: [symbolDelta],
    },
  };
};
