import { EnjcWorkspaceItemVisibility } from '../../enjc-workspace';
import { EnjicalcWorkspaceMeta } from '../../enjc-workspace/model';
import { mkAtomDelta } from '../../enjc-delta';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry } from '../utils';

export const editSetWorkspaceVisibility = (
  workspace: EnjicalcWorkspaceMeta,
  nextVisibility: EnjcWorkspaceItemVisibility,
): WorkspaceEditHistoryEntry => {
  return mkWorkspaceEditHistoryEntry('editSetWorkspaceVisibility', {
    meta:
      workspace.visibility === nextVisibility
        ? undefined
        : [
            {
              id: workspace.id,
              visibility: mkAtomDelta(workspace.visibility, nextVisibility),
            },
          ],
  });
};
