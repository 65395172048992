import { Flex, Image, Link, Stack } from '@chakra-ui/react';
import logo from '../../svg/enjicalc-black-logo.svg';
import { SidebarButton } from './SidebarButton';
import { useQuickStartTour } from 'src/hooks';
import { useWorkspacesList } from 'src/libenjc/enjc-react/enjc-react-client';

export const SideBar = () => {
  const { loading } = useWorkspacesList();

  const { handleStart } = useQuickStartTour();

  return (
    <Flex as="section" minH="100vh">
      <Stack
        flex="1"
        maxW={{ base: 'full', sm: 'xs' }}
        py="23px"
        pl="32px"
        pr="150px"
        bg="bg.surface"
        justifyContent="space-between"
      >
        <Stack>
          <Image w="104px" src={logo} />

          <Stack marginTop="60px" spacing={2}>
            <Link w="100%" href="https://enjicalc.gitbook.io/documentation/" isExternal>
              <SidebarButton>Documentation</SidebarButton>
            </Link>
            <SidebarButton isDisabled>Recent</SidebarButton>
            <SidebarButton isDisabled>Your Projects</SidebarButton>
            <SidebarButton isDisabled>Settings</SidebarButton>
            {!loading && <SidebarButton onClick={handleStart}>Quick Start</SidebarButton>}
          </Stack>
        </Stack>
      </Stack>
    </Flex>
  );
};
