import React, { ReactElement } from 'react';

import { EnjicalcSheetContext, useCtxEnjicalcWorkspace } from '../../libenjc/enjc-react/enjc-react-context';
import { SheetDocumentEditor } from '../../components/editors/sheet-document-editor';
import { IItemViewProps } from '../../components/enjc-items/interfaces';
import { EnjcSectionFragment } from '../../libenjc/enjicalc-graphql';
import { mkEnjicalcSheet } from '../../libenjc/enjc-workspace';
import { VStack } from '@chakra-ui/react';

type TSheet = EnjcSectionFragment;

interface IProps extends IItemViewProps<TSheet> {}

const WorkspaceSheetViewF = ({ item: enjcSection }: IProps): ReactElement => {
  const { workspace } = useCtxEnjicalcWorkspace();
  const enjicalcSheet = React.useMemo(() => mkEnjicalcSheet(workspace, enjcSection), [enjcSection, workspace]);

  return (
    <EnjicalcSheetContext.Provider value={{ sheet: enjicalcSheet }}>
      <VStack overflow="hidden">
        <SheetDocumentEditor />
      </VStack>
    </EnjicalcSheetContext.Provider>
  );
};

export const WorkspaceSheetView = WorkspaceSheetViewF; // React.memo(WorkspaceSheetViewF);
