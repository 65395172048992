import { keyListDeltaToInput, stringDeltaToInput } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta } from '../../enjc-workspace-editing';
import { EnjcWorkspaceSectionInput } from '../../enjicalc-graphql';

export const convertWorkspaceSectionDeltaToDiffInput = (
  delta: EnjcWorkspaceSectionDelta,
): EnjcWorkspaceSectionInput => {
  return {
    id: delta.id,
    visibility: delta.visibility?.atomAfter,
    title: delta.title && delta.title.map((d) => stringDeltaToInput(d, false)),
    items: delta.items && delta.items.map((d) => keyListDeltaToInput(d, false)),
  };
};
