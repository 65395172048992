import { EnjicalcSymbol } from '../../enjc-workspace';
import { UValueLiteral, valueLiteralToString } from '../../enjc-literal';
import { mkTreeLiteral } from '../../enjc-symbol-value-tree/tree-node';
import { calculateNodeDelta } from '../../enjc-symbol-value-tree/tree-navigation/actions';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry } from '../utils';

export const editSetSymbolValueToLiteral = (
  symbol: EnjicalcSymbol,
  literal: UValueLiteral,
  draft: string | undefined,
): WorkspaceEditHistoryEntry => {
  const existingNode = symbol.valueTree.nodes.find((n) => n.key === symbol.valueTree.rootNode?.key);
  return mkWorkspaceEditHistoryEntry('editSetSymbolValueToLiteral', {
    symbol: [
      {
        id: symbol.id,
        valueTree: {
          nodes: [
            calculateNodeDelta(
              existingNode,
              mkTreeLiteral(symbol.valueTree.rootNode?.key ?? 'ab', draft ?? valueLiteralToString(literal), literal),
            ),
          ],
        },
      },
    ],
  });
};
