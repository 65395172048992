import React, { ReactNode } from 'react';

export const TreeFunctionPowView = ({ argBase, argExpo }: { argBase: ReactNode; argExpo: ReactNode }) => {
  return (
    <msup>
      {argBase}
      {argExpo}
    </msup>
  );
};
