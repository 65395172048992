import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { TSymbolId } from '../libenjc/enjc-workspace';
import { SymbolEditor } from '../components/editors';
import { ErrorMessage } from '../components/errors';

export const SymbolRoute = (): ReactElement => {
  const { symbolId } = useParams<{ symbolId: TSymbolId }>();

  if (!symbolId) {
    return <ErrorMessage title={'SymbolRoute Error'} description={'undefined symbolId'} />;
  }

  //TODO: implement
  return <></>;
  // <WorkspaceSymbolContextLoader symbolId={symbolId}>
  //   <SymbolEditor />
  // </WorkspaceSymbolContextLoader>
};
