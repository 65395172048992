import { ValueTreeStructureError } from './ValueTreeStructureError';
import { TValueTreeNodeKey } from '../tree-node';

export class ValueTreeFunctionInvalidArgumentAtError extends ValueTreeStructureError {
  constructor(argumentIndex: number, nodeKey: TValueTreeNodeKey, argumentCount: number) {
    super(
      `Invalid TreeFunction argument at: ${argumentIndex}. TreeFunction with node key '${nodeKey}' has ${argumentCount} arguments.`,
    );
    this.name = 'ValueTreeFunctionInvalidArgumentAtError';
  }
}
