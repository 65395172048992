import { ApolloError } from '@apollo/client';
import { useGetEnjcMathQuery, ValueFunctionFragment } from '../../../enjicalc-graphql';

export interface IEnjcMath {
  enjcFunctions: ReadonlyArray<ValueFunctionFragment>;
}

interface IHook {
  loading: boolean;
  error?: ApolloError;
  math?: IEnjcMath;
}

export const useEnjcMath = (): IHook => {
  const { loading, error, data } = useGetEnjcMathQuery({});

  return {
    loading,
    error,
    math: data && { enjcFunctions: data.functions },
  };
};
