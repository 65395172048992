import { Button, ButtonProps } from '@chakra-ui/react';

export const SidebarButton = (props: ButtonProps) => (
  <Button
    width="100%"
    variant="ghost"
    size="xs"
    justifyContent="start"
    fontSize={16}
    fontWeight={400}
    {...props}
    bg="#F3F5FA"
    color="#0F172A"
    _hover={{ bg: '#F3F5FA' }}
  />
);
