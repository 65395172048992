import { isTreeNodeFunction, ValueTreeNode } from '../../tree-node';
import { getNodeOperator } from '../../../enjc-value-operators';
import { IOperatorsChain } from '../interfaces';
import { mkOperatorsChain, mkOperatorsChainLink } from '../utils';
import { IValueTreeViewContext, getNodeByKeyNVTV } from '../../../enjc-value-view-ctx';

export const collectLeafOperatorsChainNVTV = (
  vtvCtx: IValueTreeViewContext,
  chainRootNode: ValueTreeNode,
): IOperatorsChain => {
  if (isTreeNodeFunction(chainRootNode)) {
    const nodeOperator = getNodeOperator(chainRootNode);
    // FIXME: take into account implicit parenthesis

    if (nodeOperator) {
      const nodeLeafChains = chainRootNode.arguments
        .map((leafRef) => getNodeByKeyNVTV(vtvCtx, leafRef.key))
        // TODO: review method of skiping leafes with failed node resolution
        .flatMap((leaf) => (leaf ? [collectLeafOperatorsChainNVTV(vtvCtx, leaf)] : []));

      return mkOperatorsChain(nodeLeafChains[0].head, [
        ...nodeLeafChains[0].body,
        mkOperatorsChainLink(chainRootNode, nodeLeafChains[1].head, nodeOperator),
        ...nodeLeafChains[1].body,
      ]);
    }
  }

  return mkOperatorsChain(chainRootNode, []);
};
