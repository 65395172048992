import { assertNever } from '../../../../utils';
import { isTreePositionNode, isTreePositionNodeDraft } from '../../tree-position';
import { encloseValueTreeNode, ValueTreeClientDelta, changeTreeNodeFunction } from '../../tree-editing';
import {
  IValueTreeViewContext,
  getCursorPositionNode,
  getValueFunctionByDraftNVTV,
  getValueFunctionOrUndefinedNVTV,
} from '../../../enjc-value-view-ctx';

export const handleTreeKeyBracketRoundOpen = (vtvCtx: IValueTreeViewContext): ValueTreeClientDelta => {
  const { cursorPosition, positionNode } = getCursorPositionNode(vtvCtx)!;

  if (isTreePositionNode(cursorPosition)) {
    return encloseValueTreeNode(vtvCtx, positionNode);
  }

  if (isTreePositionNodeDraft(cursorPosition)) {
    if (isTreePositionNodeDraft(cursorPosition) && cursorPosition.at === 0) {
      return encloseValueTreeNode(vtvCtx, positionNode);
    }

    if (cursorPosition.at === positionNode.draft.length) {
      const draftFunction =
        getValueFunctionByDraftNVTV(vtvCtx, positionNode.draft) ?? getValueFunctionOrUndefinedNVTV(vtvCtx, 'fg.stub');

      if (draftFunction !== undefined) {
        return changeTreeNodeFunction(vtvCtx, positionNode, draftFunction);
      }
    }

    // FIXME: review
    return {};
  }

  return assertNever(cursorPosition);
};
