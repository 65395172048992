import { EnjicalcSection } from '../../enjc-workspace';
import { EnjicalcWorkspaceUpdateResult } from '../../enjc-workspace-editing';
import { WORKSPACE_CREATE_ID } from '../constants';
import { extractUpdateAllocatedId } from './extractUpdateAllocatedId';

export const extractUpdateCreatedSection = (updateResult: EnjicalcWorkspaceUpdateResult): EnjicalcSection => {
  const allocatedId = extractUpdateAllocatedId(updateResult, WORKSPACE_CREATE_ID);
  if (!allocatedId) {
    throw Error('Cannot find allocated ID for the created section');
  }
  const createdSection = updateResult.sections.find((upSe) => upSe.id === allocatedId.id);
  if (!createdSection) {
    throw Error(`Cannot find created section with ID '${allocatedId.id}'`);
  }
  return createdSection;
};
