import React, { ReactElement } from 'react';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { Flex, HStack, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { RiDraggable } from 'react-icons/ri';
import { AiOutlineInsertRowBelow } from 'react-icons/ai';
import { DocumentItemIndexView, TextPopover } from '../../misc';
import { EnjicalcSection } from '../../../libenjc/enjc-workspace';
import {
  editCreateSymbolIn,
  editHideSection,
  editSetSectionTitle,
  EnjicalcWorkspaceUpdateResult,
  WorkspaceEditHistoryEntry,
} from '../../../libenjc/enjc-workspace-editing';
import { useCtxEnjicalcWorkspace } from '../../../libenjc/enjc-react/enjc-react-context';
import { extractUpdateCreatedSymbol } from '../../../libenjc/enjc-client/utils';
import { IoCloseSharp } from 'react-icons/io5';
import { VscKebabVertical } from 'react-icons/vsc';

interface IProps {
  readonly index: number;
  readonly section: EnjicalcSection;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateResult>;
  readonly dndListeners: SyntheticListenerMap | undefined;
}

export const SectionHeaderView = ({ index, section, performWorkspaceEdit, dndListeners }: IProps): ReactElement => {
  const { workspace } = useCtxEnjicalcWorkspace();
  const [showOverlayButtons, setShowOverlayButtons] = React.useState(false);

  const setSectionTitle = React.useCallback(
    (sectionId: string, title: string) => {
      const hEntry = editSetSectionTitle(workspace, sectionId, title);
      performWorkspaceEdit(hEntry);
    },
    [performWorkspaceEdit, workspace],
  );

  const onSectionDissolve = React.useCallback(() => {
    const hEntry = editHideSection(workspace, section.id);
    performWorkspaceEdit(hEntry);
  }, [performWorkspaceEdit, section.id, workspace]);

  const onSectionCreateSymbol = React.useCallback(() => {
    const hEntry = editCreateSymbolIn(workspace, section.id);
    performWorkspaceEdit(hEntry).then((updateResult) => {
      return extractUpdateCreatedSymbol(updateResult);
    });
    // .then((sy) => {
    //   setModalSymbolId(sy.id);
    // });
  }, [performWorkspaceEdit, section.id, workspace]);

  return (
    <Flex
      direction="column"
      onMouseOver={() => setShowOverlayButtons(true)}
      onMouseLeave={() => setShowOverlayButtons(false)}
    >
      <DocumentItemIndexView index={index} padding="6.5px 2px 0px 0px" />
      {showOverlayButtons && (
        <RiDraggable {...dndListeners} size={17} color="black" style={{ position: 'absolute', top: 5, left: -75 }} />
      )}
      <Flex className="noPrint" as="span" pos="relative" left="780px" w="0px" h="0px">
        <HStack position={'absolute'} left={0}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Section Options"
              icon={<VscKebabVertical />}
              w={4}
              h={25}
              m={0}
              variant={'ghost'}
              _hover={{ bg: 'none' }}
              _active={{ bg: 'none' }}
            />
            <MenuList>
              <MenuItem
                fontSize={'14px'}
                pr={'20px'}
                icon={<IoCloseSharp size={17} color="red" />}
                onClick={onSectionDissolve}
              >
                {'Delete Section'}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                fontSize={'14px'}
                pr={'20px'}
                icon={<AiOutlineInsertRowBelow size={17} color="black" />}
                onClick={onSectionCreateSymbol}
              >
                {'Create Symbol'}
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
      <TextPopover initialValue={section.title} onSave={(title) => setSectionTitle(section.id, title)}>
        <Text
          flex={1}
          display={'block'}
          p={'0'}
          py="5px"
          color="rgba(0, 0, 0, 0.8)"
          fontFamily={'Plus Jakarta Sans'}
          fontSize="13px"
          fontWeight="600"
          borderTopWidth="1px"
          borderTopStyle="solid"
          borderTopColor="lightgray"
          _hover={{
            background: '#f4f8fa',
            cursor: 'pointer',
          }}
        >
          {section.title || 'Untitled Section'}
        </Text>
      </TextPopover>
    </Flex>
  );
};
