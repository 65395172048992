import { ApolloError } from '@apollo/client';

import { noopl } from '../../../../utils';
import { TWorkspaceId } from '../../../../libenjc/enjc-workspace';
import {
  EnjicalcWorkspaceFragment,
  useGetEnjicalcWorkspaceQuery,
  useWatchWorkspaceItemsSubscription,
} from '../../../../libenjc/enjicalc-graphql';

interface IHook {
  loading: boolean;
  error?: ApolloError;
  workspace?: EnjicalcWorkspaceFragment;
  refetch: () => void;
}

export const useEnjcWorkspace = (workspaceId: TWorkspaceId): IHook => {
  const { loading, error, data, refetch } = useGetEnjicalcWorkspaceQuery({
    variables: { id: workspaceId },
  });

  // TODO: re-enable subscription
  // const { loading: subLoading, data: subData } = useWatchWorkspaceItemsSubscription({
  //   variables: { workspace: workspaceId },
  // });
  // noopl(subLoading, subData);

  return {
    loading,
    error,
    workspace: data?.workspace,
    refetch,
  };
};
