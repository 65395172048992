import React, { ReactElement } from 'react';
import { isCursorNodeShadow } from '../../../../enjc-symbol-value-tree/tree-cursor';
import { ISymbolValueGroupViewProps } from '../../enjc-symbol-value-composite-view/props';
import { MathMLGlobalAttributes } from '../../../../enjc-react-mathml/attributes';

export const SymbolValueGroupMathmlView = ({ prefix, children, cursor }: ISymbolValueGroupViewProps): ReactElement => {
  const mathProps: MathMLGlobalAttributes = { mathbackground: isCursorNodeShadow(cursor) ? 'lightgray' : undefined };
  return (
    <mrow {...mathProps}>
      {prefix}
      {children.length > 0 && (
        <>
          <mo fence={'true'}>{'('}</mo>
          {children.flatMap((rn, argIndex, arr) => [
            rn,
            ...(argIndex < arr.length - 1
              ? [
                  <mo key={argIndex} separator={'true'}>
                    {','}
                  </mo>,
                ]
              : []),
          ])}
          <mo fence={'true'}>{')'}</mo>
        </>
      )}
    </mrow>
  );
};
