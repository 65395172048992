import React, { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';

interface IProps {
  index: number;
  padding?: string;
}

type FComp = (props: IProps) => ReactElement;

export const DocumentItemIndexView = React.memo<FComp>(({ index, padding = '3px' }) => {
  return (
    <Box as="span" pos="absolute" left="-75px" w="0px" h="0px">
      <Flex
        p={padding}
        fontSize="12px"
        color="rgba(0, 0, 0, 0.50)"
        justifyContent="flex-start"
        className="noPrint"
        direction="row-reverse"
        w="40px"
        borderTop="1px solid lightgray"
      >
        {index}
      </Flex>
    </Box>
  );
});
