import { EnjcSectionFragment } from '../../enjicalc-graphql';

export const findSheetSections = (sections: ReadonlyArray<EnjcSectionFragment>): ReadonlyArray<EnjcSectionFragment> => {
  const usedSections = new Set(
    sections
      .flatMap((section) => section.items)
      .filter((item) => item.__typename === 'EnjcSection')
      .map((item) => item.id),
  );
  return sections.filter((section) => !usedSections.has(section.id));
};
