import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

const defaultOptions = {} as const;
export const EnjcUserFragmentDoc = gql`
  fragment EnjcUser on EnjcUser {
    id
    fullName
  }
`;
export const EnjcWorkspaceItemFragmentDoc = gql`
  fragment EnjcWorkspaceItem on IEnjcWorkspaceItem {
    id
    created
    modified
    visibility
  }
`;
export const EnjcSectionItemRefFragmentDoc = gql`
  fragment EnjcSectionItemRef on IEnjcWorkspaceItem {
    id
  }
`;
export const EnjcWorkspaceSheetAuxMetaFragmentDoc = gql`
  fragment EnjcWorkspaceSheetAuxMeta on EnjcWorkspaceSheetAuxMeta {
    checkedName
    checkedDate
    approvedName
    approvedDate
  }
`;
export const EnjcSectionFragmentDoc = gql`
  fragment EnjcSection on EnjcSection {
    ...EnjcWorkspaceItem
    originalId
    title
    items {
      ...EnjcSectionItemRef
    }
    sheetAuxMeta {
      ...EnjcWorkspaceSheetAuxMeta
    }
  }
  ${EnjcWorkspaceItemFragmentDoc}
  ${EnjcSectionItemRefFragmentDoc}
  ${EnjcWorkspaceSheetAuxMetaFragmentDoc}
`;
export const ValueTreeNodeKeyFragmentDoc = gql`
  fragment ValueTreeNodeKey on ValueTreeNode {
    key
  }
`;
export const ValueLiteralVoidFragmentDoc = gql`
  fragment ValueLiteralVoid on ValueLiteralVoid {
    cause
  }
`;
export const ValueLiteralBooleanFragmentDoc = gql`
  fragment ValueLiteralBoolean on ValueLiteralBoolean {
    bool
  }
`;
export const ValueLiteralNumberFragmentDoc = gql`
  fragment ValueLiteralNumber on ValueLiteralNumber {
    numb
  }
`;
export const ValueLiteralStringFragmentDoc = gql`
  fragment ValueLiteralString on ValueLiteralString {
    str
  }
`;
export const ValueLiteralFragmentDoc = gql`
  fragment ValueLiteral on UValueLiteral {
    ... on ValueLiteralVoid {
      ...ValueLiteralVoid
    }
    ... on ValueLiteralBoolean {
      ...ValueLiteralBoolean
    }
    ... on ValueLiteralNumber {
      ...ValueLiteralNumber
    }
    ... on ValueLiteralString {
      ...ValueLiteralString
    }
  }
  ${ValueLiteralVoidFragmentDoc}
  ${ValueLiteralBooleanFragmentDoc}
  ${ValueLiteralNumberFragmentDoc}
  ${ValueLiteralStringFragmentDoc}
`;
export const EnjcSymbolRefFragmentDoc = gql`
  fragment EnjcSymbolRef on EnjcSymbol {
    id
  }
`;
export const ValueFunctionRefFragmentDoc = gql`
  fragment ValueFunctionRef on ValueFunction {
    id
  }
`;
export const ValueTreeNodeFragmentDoc = gql`
  fragment ValueTreeNode on ValueTreeNode {
    key
    tags
    draft
    mode
    literal {
      ...ValueLiteral
    }
    symbol {
      ...EnjcSymbolRef
    }
    function {
      ...ValueFunctionRef
    }
    arguments {
      ...ValueTreeNodeKey
    }
    result {
      ...ValueLiteral
    }
  }
  ${ValueLiteralFragmentDoc}
  ${EnjcSymbolRefFragmentDoc}
  ${ValueFunctionRefFragmentDoc}
  ${ValueTreeNodeKeyFragmentDoc}
`;
export const ValueTreeFragmentDoc = gql`
  fragment ValueTree on ValueTree {
    rootNode {
      ...ValueTreeNodeKey
    }
    nodes {
      ...ValueTreeNode
    }
    result {
      ...ValueLiteral
    }
  }
  ${ValueTreeNodeKeyFragmentDoc}
  ${ValueTreeNodeFragmentDoc}
  ${ValueLiteralFragmentDoc}
`;
export const EnjcSymbolFragmentDoc = gql`
  fragment EnjcSymbol on EnjcSymbol {
    ...EnjcWorkspaceItem
    originalId
    glyph
    glyphPlaintext
    valueTree {
      ...ValueTree
    }
    unit
    description
    comment
    aliases
  }
  ${EnjcWorkspaceItemFragmentDoc}
  ${ValueTreeFragmentDoc}
`;
export const SectionItemUiFragmentDoc = gql`
  fragment SectionItemUi on UEnjcSectionItem {
    ... on EnjcSection {
      ...EnjcSection
    }
    ... on EnjcSymbol {
      ...EnjcSymbol
    }
  }
  ${EnjcSectionFragmentDoc}
  ${EnjcSymbolFragmentDoc}
`;
export const SectionUiFragmentDoc = gql`
  fragment SectionUi on EnjcSection {
    ...EnjcSection
    items {
      ...SectionItemUi
    }
  }
  ${EnjcSectionFragmentDoc}
  ${SectionItemUiFragmentDoc}
`;
export const EnjcUserRefFragmentDoc = gql`
  fragment EnjcUserRef on EnjcUser {
    id
  }
`;
export const EnjcSectionRefFragmentDoc = gql`
  fragment EnjcSectionRef on EnjcSection {
    id
  }
`;
export const EnjcWorkspaceMetaFragmentDoc = gql`
  fragment EnjcWorkspaceMeta on EnjcWorkspace {
    ...EnjcWorkspaceItem
    owner {
      ...EnjcUserRef
    }
    title
    sections {
      ...EnjcSectionRef
    }
    symbols {
      ...EnjcSymbolRef
    }
  }
  ${EnjcWorkspaceItemFragmentDoc}
  ${EnjcUserRefFragmentDoc}
  ${EnjcSectionRefFragmentDoc}
  ${EnjcSymbolRefFragmentDoc}
`;
export const EnjcAllocatedIdFragmentDoc = gql`
  fragment EnjcAllocatedId on EnjcAllocatedId {
    id
    supplied
  }
`;
export const EnjcWorkspaceUpdateResultFragmentDoc = gql`
  fragment EnjcWorkspaceUpdateResult on EnjcWorkspaceUpdateResult {
    workspace {
      ...EnjcWorkspaceMeta
    }
    sections {
      ...EnjcSection
    }
    symbols {
      ...EnjcSymbol
    }
    allocatedIds {
      ...EnjcAllocatedId
    }
  }
  ${EnjcWorkspaceMetaFragmentDoc}
  ${EnjcSectionFragmentDoc}
  ${EnjcSymbolFragmentDoc}
  ${EnjcAllocatedIdFragmentDoc}
`;
export const EnjicalcWorkspaceFragmentDoc = gql`
  fragment EnjicalcWorkspace on EnjcWorkspace {
    ...EnjcWorkspaceMeta
    sections {
      ...EnjcSection
    }
    symbols {
      ...EnjcSymbol
    }
  }
  ${EnjcWorkspaceMetaFragmentDoc}
  ${EnjcSectionFragmentDoc}
  ${EnjcSymbolFragmentDoc}
`;
export const ValueTreeNodeUiFragmentDoc = gql`
  fragment ValueTreeNodeUi on ValueTreeNode {
    ...ValueTreeNode
    symbol {
      ...EnjcSymbolRef
      glyph
      glyphPlaintext
      valueTree {
        result {
          ...ValueLiteral
        }
      }
    }
    function {
      ...ValueFunctionRef
      glyph
      glyphPlaintext
    }
  }
  ${ValueTreeNodeFragmentDoc}
  ${EnjcSymbolRefFragmentDoc}
  ${ValueLiteralFragmentDoc}
  ${ValueFunctionRefFragmentDoc}
`;
export const ValueTreeUiFragmentDoc = gql`
  fragment ValueTreeUi on ValueTree {
    ...ValueTree
    nodes {
      ...ValueTreeNodeUi
    }
  }
  ${ValueTreeFragmentDoc}
  ${ValueTreeNodeUiFragmentDoc}
`;
export const SymbolUnwrappedFragmentDoc = gql`
  fragment SymbolUnwrapped on EnjcSymbol {
    ...EnjcSymbol
    valueTree {
      ...ValueTreeUi
    }
  }
  ${EnjcSymbolFragmentDoc}
  ${ValueTreeUiFragmentDoc}
`;
export const EnjicalcSymbolFragmentDoc = gql`
  fragment EnjicalcSymbol on EnjcSymbol {
    ...SymbolUnwrapped
  }
  ${SymbolUnwrappedFragmentDoc}
`;
export const ValueFunctionFragmentDoc = gql`
  fragment ValueFunction on ValueFunction {
    id
    glyph
    glyphPlaintext
    aliases
    defaultArgCount
    flagInternal
  }
`;
export const EnjcWorkspaceRefFragmentDoc = gql`
  fragment EnjcWorkspaceRef on EnjcWorkspace {
    id
  }
`;
export const EnjcWorkspaceItemRefFragmentDoc = gql`
  fragment EnjcWorkspaceItemRef on IEnjcWorkspaceItem {
    id
  }
`;
export const GetApiVersionDocument = gql`
  query getApiVersion {
    apiVersion
  }
`;

/**
 * __useGetApiVersionQuery__
 *
 * To run a query within a React component, call `useGetApiVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetApiVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>(GetApiVersionDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetApiVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>(
    GetApiVersionDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetApiVersionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>(
    GetApiVersionDocument,
    options,
  );
}
export type GetApiVersionQueryHookResult = ReturnType<typeof useGetApiVersionQuery>;
export type GetApiVersionLazyQueryHookResult = ReturnType<typeof useGetApiVersionLazyQuery>;
export type GetApiVersionSuspenseQueryHookResult = ReturnType<typeof useGetApiVersionSuspenseQuery>;
export type GetApiVersionQueryResult = Apollo.QueryResult<Types.GetApiVersionQuery, Types.GetApiVersionQueryVariables>;
export const GetEnjcMathDocument = gql`
  query getEnjcMath {
    functions {
      ...ValueFunction
    }
  }
  ${ValueFunctionFragmentDoc}
`;

/**
 * __useGetEnjcMathQuery__
 *
 * To run a query within a React component, call `useGetEnjcMathQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnjcMathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetEnjcMathQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnjcMathQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>(GetEnjcMathDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjcMathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>(GetEnjcMathDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjcMathSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>(GetEnjcMathDocument, options);
}
export type GetEnjcMathQueryHookResult = ReturnType<typeof useGetEnjcMathQuery>;
export type GetEnjcMathLazyQueryHookResult = ReturnType<typeof useGetEnjcMathLazyQuery>;
export type GetEnjcMathSuspenseQueryHookResult = ReturnType<typeof useGetEnjcMathSuspenseQuery>;
export type GetEnjcMathQueryResult = Apollo.QueryResult<Types.GetEnjcMathQuery, Types.GetEnjcMathQueryVariables>;
export const GetEnjicalcWorkspaceDocument = gql`
  query getEnjicalcWorkspace($id: ID!) {
    workspace: workspaceById(id: $id) {
      ...EnjicalcWorkspace
    }
  }
  ${EnjicalcWorkspaceFragmentDoc}
`;

/**
 * __useGetEnjicalcWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetEnjicalcWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnjicalcWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetEnjicalcWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEnjicalcWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables> &
    ({ variables: Types.GetEnjicalcWorkspaceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>(
    GetEnjicalcWorkspaceDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjicalcWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>(
    GetEnjicalcWorkspaceDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetEnjicalcWorkspaceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    Types.GetEnjicalcWorkspaceQuery,
    Types.GetEnjicalcWorkspaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetEnjicalcWorkspaceQuery, Types.GetEnjicalcWorkspaceQueryVariables>(
    GetEnjicalcWorkspaceDocument,
    options,
  );
}
export type GetEnjicalcWorkspaceQueryHookResult = ReturnType<typeof useGetEnjicalcWorkspaceQuery>;
export type GetEnjicalcWorkspaceLazyQueryHookResult = ReturnType<typeof useGetEnjicalcWorkspaceLazyQuery>;
export type GetEnjicalcWorkspaceSuspenseQueryHookResult = ReturnType<typeof useGetEnjicalcWorkspaceSuspenseQuery>;
export type GetEnjicalcWorkspaceQueryResult = Apollo.QueryResult<
  Types.GetEnjicalcWorkspaceQuery,
  Types.GetEnjicalcWorkspaceQueryVariables
>;
export const GetValueFunctionsDocument = gql`
  query getValueFunctions {
    functions {
      ...ValueFunction
    }
  }
  ${ValueFunctionFragmentDoc}
`;

/**
 * __useGetValueFunctionsQuery__
 *
 * To run a query within a React component, call `useGetValueFunctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValueFunctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetValueFunctionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetValueFunctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetValueFunctionsQuery, Types.GetValueFunctionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetValueFunctionsQuery, Types.GetValueFunctionsQueryVariables>(
    GetValueFunctionsDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetValueFunctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetValueFunctionsQuery, Types.GetValueFunctionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetValueFunctionsQuery, Types.GetValueFunctionsQueryVariables>(
    GetValueFunctionsDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetValueFunctionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetValueFunctionsQuery, Types.GetValueFunctionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetValueFunctionsQuery, Types.GetValueFunctionsQueryVariables>(
    GetValueFunctionsDocument,
    options,
  );
}
export type GetValueFunctionsQueryHookResult = ReturnType<typeof useGetValueFunctionsQuery>;
export type GetValueFunctionsLazyQueryHookResult = ReturnType<typeof useGetValueFunctionsLazyQuery>;
export type GetValueFunctionsSuspenseQueryHookResult = ReturnType<typeof useGetValueFunctionsSuspenseQuery>;
export type GetValueFunctionsQueryResult = Apollo.QueryResult<
  Types.GetValueFunctionsQuery,
  Types.GetValueFunctionsQueryVariables
>;
export const GetWorkspacesListDocument = gql`
  query getWorkspacesList {
    workspaces {
      ...EnjcWorkspaceMeta
    }
  }
  ${EnjcWorkspaceMetaFragmentDoc}
`;

/**
 * __useGetWorkspacesListQuery__
 *
 * To run a query within a React component, call `useGetWorkspacesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspacesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetWorkspacesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkspacesListQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>(
    GetWorkspacesListDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspacesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>(
    GetWorkspacesListDocument,
    options,
  );
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspacesListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetWorkspacesListQuery, Types.GetWorkspacesListQueryVariables>(
    GetWorkspacesListDocument,
    options,
  );
}
export type GetWorkspacesListQueryHookResult = ReturnType<typeof useGetWorkspacesListQuery>;
export type GetWorkspacesListLazyQueryHookResult = ReturnType<typeof useGetWorkspacesListLazyQuery>;
export type GetWorkspacesListSuspenseQueryHookResult = ReturnType<typeof useGetWorkspacesListSuspenseQuery>;
export type GetWorkspacesListQueryResult = Apollo.QueryResult<
  Types.GetWorkspacesListQuery,
  Types.GetWorkspacesListQueryVariables
>;
export const GetWorkspaceDocument = gql`
  query getWorkspace($id: ID!) {
    workspaceById(id: $id) {
      ...EnjicalcWorkspace
    }
  }
  ${EnjicalcWorkspaceFragmentDoc}
`;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables> &
    ({ variables: Types.GetWorkspaceQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
}
/**
 *
 * @param baseOptions
 */
export function useGetWorkspaceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>(
    GetWorkspaceDocument,
    options,
  );
}
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceSuspenseQueryHookResult = ReturnType<typeof useGetWorkspaceSuspenseQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<Types.GetWorkspaceQuery, Types.GetWorkspaceQueryVariables>;
export const CreateWorkspaceDocument = gql`
  mutation createWorkspace($diff: EnjcWorkspaceInput) {
    createWorkspace(diff: $diff) {
      ...EnjcWorkspaceUpdateResult
    }
  }
  ${EnjcWorkspaceUpdateResultFragmentDoc}
`;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  Types.CreateWorkspaceMutation,
  Types.CreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CreateWorkspaceMutation, Types.CreateWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateWorkspaceMutation, Types.CreateWorkspaceMutationVariables>(
    CreateWorkspaceDocument,
    options,
  );
}
export type CreateWorkspaceMutationHookResult = ReturnType<typeof useCreateWorkspaceMutation>;
export type CreateWorkspaceMutationResult = Apollo.MutationResult<Types.CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateWorkspaceMutation,
  Types.CreateWorkspaceMutationVariables
>;
export const UpdateWorkspaceDocument = gql`
  mutation updateWorkspace($workspace: ID!, $generation: Int!, $diff: EnjcWorkspaceInput!) {
    updateWorkspace(workspace: $workspace, generation: $generation, diff: $diff) {
      ...EnjcWorkspaceUpdateResult
    }
  }
  ${EnjcWorkspaceUpdateResultFragmentDoc}
`;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<
  Types.UpdateWorkspaceMutation,
  Types.UpdateWorkspaceMutationVariables
>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *      generation: // value for 'generation'
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdateWorkspaceMutation, Types.UpdateWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateWorkspaceMutation, Types.UpdateWorkspaceMutationVariables>(
    UpdateWorkspaceDocument,
    options,
  );
}
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<Types.UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateWorkspaceMutation,
  Types.UpdateWorkspaceMutationVariables
>;
export const ForkWorkspaceDocument = gql`
  mutation forkWorkspace($workspace: ID!, $generation: Int!, $diff: EnjcWorkspaceInput) {
    forkWorkspace(workspace: $workspace, generation: $generation, diff: $diff) {
      ...EnjcWorkspaceUpdateResult
    }
  }
  ${EnjcWorkspaceUpdateResultFragmentDoc}
`;
export type ForkWorkspaceMutationFn = Apollo.MutationFunction<
  Types.ForkWorkspaceMutation,
  Types.ForkWorkspaceMutationVariables
>;

/**
 * __useForkWorkspaceMutation__
 *
 * To run a mutation, you first call `useForkWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForkWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 * @example
 * const [forkWorkspaceMutation, { data, loading, error }] = useForkWorkspaceMutation({
 *   variables: {
 *      workspace: // value for 'workspace'
 *      generation: // value for 'generation'
 *      diff: // value for 'diff'
 *   },
 * });
 */
export function useForkWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ForkWorkspaceMutation, Types.ForkWorkspaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ForkWorkspaceMutation, Types.ForkWorkspaceMutationVariables>(
    ForkWorkspaceDocument,
    options,
  );
}
export type ForkWorkspaceMutationHookResult = ReturnType<typeof useForkWorkspaceMutation>;
export type ForkWorkspaceMutationResult = Apollo.MutationResult<Types.ForkWorkspaceMutation>;
export type ForkWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  Types.ForkWorkspaceMutation,
  Types.ForkWorkspaceMutationVariables
>;
export const WatchWorkspaceItemsDocument = gql`
  subscription watchWorkspaceItems($workspace: ID!) {
    workspaceItemUpdate(workspace: $workspace) {
      ...EnjicalcSymbol
    }
  }
  ${EnjicalcSymbolFragmentDoc}
`;

/**
 * __useWatchWorkspaceItemsSubscription__
 *
 * To run a query within a React component, call `useWatchWorkspaceItemsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchWorkspaceItemsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 * @example
 * const { data, loading, error } = useWatchWorkspaceItemsSubscription({
 *   variables: {
 *      workspace: // value for 'workspace'
 *   },
 * });
 */
export function useWatchWorkspaceItemsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Types.WatchWorkspaceItemsSubscription,
    Types.WatchWorkspaceItemsSubscriptionVariables
  > &
    ({ variables: Types.WatchWorkspaceItemsSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<Types.WatchWorkspaceItemsSubscription, Types.WatchWorkspaceItemsSubscriptionVariables>(
    WatchWorkspaceItemsDocument,
    options,
  );
}
export type WatchWorkspaceItemsSubscriptionHookResult = ReturnType<typeof useWatchWorkspaceItemsSubscription>;
export type WatchWorkspaceItemsSubscriptionResult = Apollo.SubscriptionResult<Types.WatchWorkspaceItemsSubscription>;
