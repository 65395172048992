import { createContext } from 'react';

interface IUserContext {
  currentUser: null | undefined;
}

const UserContext = createContext<IUserContext>({
  currentUser: undefined,
});

export default UserContext;
