import {
  EnjicalcWorkspace,
  getEnjcSection,
  getEnjcSymbolOrUndefined,
  TSectionId,
  getEnjcSectionOrUndefined,
} from '../../enjc-workspace';
import { ValueTreeNodeMode } from '../../../generated/graphql/types';
import { ISheetUpdate } from './parseSheetUpdateJson';

export const exportSheet = (workspace: EnjicalcWorkspace, sheetId: TSectionId): string => {
  return JSON.stringify(exportSheetJson(workspace, sheetId), null, 2);
};

export const exportSheetJson = (workspace: EnjicalcWorkspace, sheetId: TSectionId): ISheetUpdate => {
  const sheetToExport = getEnjcSection(workspace, sheetId);
  return {
    title: workspace.title,
    sections: sheetToExport.items.flatMap((shSe) => {
      const currentSection = getEnjcSectionOrUndefined(workspace, shSe.id);
      if (!currentSection) return [];

      return [
        {
          title: currentSection.title,
          symbols: currentSection.items.flatMap((seSy) => {
            const currentSymbol = getEnjcSymbolOrUndefined(workspace, seSy.id);
            if (!currentSymbol) return [];
            return [
              {
                glyph: currentSymbol.glyph,
                unit: currentSymbol.unit,
                description: currentSymbol.description,
                comment: currentSymbol.comment,
                varname: currentSymbol.originalId ?? '',
                valueTree: currentSymbol.valueTree.nodes.map((syNo) => {
                  switch (syNo.mode) {
                    case ValueTreeNodeMode.Literal:
                      return {
                        key: syNo.key,
                        literal: syNo.literal.__typename === 'ValueLiteralNumber' ? syNo.literal.numb : 0,
                      };
                    case ValueTreeNodeMode.Symbol:
                      return {
                        key: syNo.key,
                        symbol:
                          getEnjcSectionOrUndefined(workspace, syNo.symbol?.id ?? '')?.originalId?.substring(4) ?? '',
                      };
                    case ValueTreeNodeMode.Function:
                      return {
                        key: syNo.key,
                        function: syNo.function?.id ?? '',
                        arguments: syNo.arguments.map((argNodeRef) => argNodeRef.key),
                      };
                  }
                }),
              },
            ];
          }),
        },
      ];
    }),
  };
};
