import React, { ReactElement } from 'react';
import { WorkspacesListContextLoader } from '../libenjc/enjc-react/enjc-react-context';
import { AppDashboard } from '../app/dashboard';

export const DashboardRoute = (): ReactElement => {
  return (
    <WorkspacesListContextLoader>
      <AppDashboard />
    </WorkspacesListContextLoader>
  );
};
