import { ValueTreeFragment } from '../../../generated/graphql/operations';
import {
  isTreeNodeFunction,
  isTreeNodeLiteral,
  isTreeNodeSymbol,
  TValueTreeNodeKey,
} from '../../enjc-symbol-value-tree/tree-node';
import { TSymbolId } from '../../../generated/graphql/types';
import { mkLiteralVoidNull, UValueLiteral } from '../../enjc-literal';
import { BranchEvaluationResult } from '../model';
import { getBranchNodes } from '../../enjc-workspace-editing/utils/getBranchNodes';
import { evaluateValueFunction } from './evaluateValueFunction';
import { getTreeNodeByKey } from '../../enjc-symbol-value-tree/tree-methods';

export const evaluateValueTreeBranch = (
  valueTree: ValueTreeFragment,
  nodeKey: TValueTreeNodeKey,
  symbols: Map<TSymbolId, UValueLiteral>,
): BranchEvaluationResult => {
  const branchRoot = getTreeNodeByKey(valueTree, nodeKey);
  if (isTreeNodeLiteral(branchRoot)) {
    return {
      node: { ...branchRoot, result: branchRoot.literal },
      args: getBranchNodes(valueTree, branchRoot).map((n) => ({ ...n, result: mkLiteralVoidNull() })),
    };
  }

  if (isTreeNodeSymbol(branchRoot)) {
    const symbolLiteral = (branchRoot.symbol?.id && symbols.get(branchRoot.symbol.id)) || mkLiteralVoidNull();
    return {
      node: { ...branchRoot, result: symbolLiteral },
      args: getBranchNodes(valueTree, branchRoot).map((n) => ({ ...n, result: mkLiteralVoidNull() })),
    };
  }

  if (isTreeNodeFunction(branchRoot)) {
    const argEvalResults = branchRoot.arguments.map((arg) => evaluateValueTreeBranch(valueTree, arg.key, symbols));
    const evaluatorArgs = argEvalResults.map((evalRes) => evalRes.node.result);
    const evaluationResult =
      (branchRoot.function?.id && evaluateValueFunction(branchRoot.function.id, evaluatorArgs)) || mkLiteralVoidNull();
    return {
      node: { ...branchRoot, result: evaluationResult },
      args: argEvalResults.flatMap((evalRes) => [evalRes.node, ...evalRes.args]),
    };
  }

  throw Error(`Unknown mode in the node: '${JSON.stringify(branchRoot)}'`);
};
