import React, { ReactElement } from 'react';
import { Stat, StatGroup, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';
import { EnjcWorkspaceItemVisibility } from '../../../generated/graphql/types';
import { useCtxEnjicalcWorkspace } from '../../../libenjc/enjc-react/enjc-react-context';

export const WorkspaceSymbolsStats = (): ReactElement => {
  const {
    workspace: { symbols },
  } = useCtxEnjicalcWorkspace();

  const stats = React.useMemo(
    (): ISymStatData => ({
      total: symbols.length,
      evaluable: symbols.filter((symbol) => symbol.valueTree.result.__typename !== 'ValueLiteralVoid').length,
      hidden: symbols.filter((symbol) => symbol.visibility !== EnjcWorkspaceItemVisibility.Visible).length,
      missingGlyph: symbols.filter((symbol) => !symbol.glyph).length,
      // TODO: fix and re-enable
      missingValue: 0,
      missingUnit: symbols.filter((symbol) => !symbol.unit).length,
    }),
    [symbols],
  );

  return (
    <StatGroup>
      <Stat>
        <StatLabel>Total symbols</StatLabel>
        <StatNumber>{stats.total}</StatNumber>
      </Stat>

      <Stat>
        <StatLabel>Evaluable</StatLabel>
        <StatNumber>{stats.evaluable}</StatNumber>
        <StatHelpText></StatHelpText>
      </Stat>

      {!!stats.hidden && (
        <Stat>
          <StatLabel>Hidden</StatLabel>
          <StatNumber>{stats.hidden}</StatNumber>
          <StatHelpText></StatHelpText>
        </Stat>
      )}

      {!!stats.missingGlyph && (
        <Stat>
          <StatLabel>Missing Glyph</StatLabel>
          <StatNumber>{stats.missingGlyph}</StatNumber>
          <StatHelpText></StatHelpText>
        </Stat>
      )}

      {!!stats.missingValue && (
        <Stat>
          <StatLabel>Missing Value</StatLabel>
          <StatNumber>{stats.missingValue}</StatNumber>
          <StatHelpText></StatHelpText>
        </Stat>
      )}

      {!!stats.missingUnit && (
        <Stat>
          <StatLabel>Missing Unit</StatLabel>
          <StatNumber>{stats.missingUnit}</StatNumber>
          <StatHelpText></StatHelpText>
        </Stat>
      )}
    </StatGroup>
  );
};

interface ISymStatData {
  total: number;
  evaluable: number;
  hidden: number;
  missingGlyph: number;
  missingValue: number;
  missingUnit: number;
}
