import { ValueTreeNode, TValueTreeNodeKey } from '../../enjc-symbol-value-tree/tree-node';
import { IValueTreeViewContext } from '../model';
import { getNodeByKeyOrUndefinedNVTV } from './getNodeByKeyOrUndefinedNVTV';

export const getNodeByKeyNVTV = (vtvCtx: IValueTreeViewContext, nodeKey: TValueTreeNodeKey): ValueTreeNode => {
  const result = getNodeByKeyOrUndefinedNVTV(vtvCtx, nodeKey);
  if (result === undefined) {
    throw Error(`Node with key '${nodeKey}' not found`);
  }
  return result;
};
