import React, { ReactElement } from 'react';
import {
  SymbolValueBinaryOperatorMathmlView,
  SymbolValueDraftMathmlView,
  SymbolValueGlyphMathmlView,
  SymbolValueGroupMathmlView,
  SymbolValueLiteralMathmlView,
} from './components';
import { EnjcSymbolValueCompositeView } from '../enjc-symbol-value-composite-view';
import { ISymbolValueViewOptions } from '../enjc-symbol-value-composite-view/interfaces';
import { SymbolValueRootMathmlView } from './components/SymbolValueRootMathmlView';
import { TreeFunctionSpecialView } from './components/TreeFunctionSpecialView';

interface IProps {
  readonly viewOptions: ISymbolValueViewOptions;
  readonly onNodeClick?: (nodeKey: string) => void;
}

export const EnjcSymbolValueMathmlView = ({ viewOptions, onNodeClick }: IProps): ReactElement => {
  return (
    <EnjcSymbolValueCompositeView
      viewComponents={{
        rootViewComp: SymbolValueRootMathmlView,
        draftViewComp: SymbolValueDraftMathmlView,
        literalViewComp: SymbolValueLiteralMathmlView,
        glyphViewComp: SymbolValueGlyphMathmlView,
        groupViewComp: SymbolValueGroupMathmlView,
        binaryOperatorViewComp: SymbolValueBinaryOperatorMathmlView,
        specialFunctionView: TreeFunctionSpecialView,
      }}
      viewOptions={viewOptions}
      onNodeClick={onNodeClick ?? (() => {})}
    />
  );
};
