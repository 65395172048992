import {
  ValueTreeClientDelta,
  mkNodeRemovalDelta,
  resetTreeNodeNVTV,
  calculateNodeDeltaNVTV,
} from '../../tree-editing';
import { isValueTreePositionAtStartEnd, isTreePositionNode, isTreePositionNodeDraft } from '../../tree-position';
import {
  IValueTreeViewContext,
  collectTreeNodeDescendantsNVTV,
  getCursorPositionNode,
  getTreeNodeParentInfoOrUndefinedNVTV,
} from '../../../enjc-value-view-ctx';
import { isLiteralVoid, mkLiteralVoidNull } from '../../../enjc-literal';
import { mkTreeCursorNode } from '../../tree-cursor';
import { checkTreeNodeDraftIndexIsValid } from '../../tree-utils';
import {
  isTreeNodeFunction,
  isTreeNodeLiteral,
  mkTreeLiteral,
  mkValueTreeNodeRef,
  ValueTreeNode,
} from '../../tree-node';
import { spliceNodeDraftNVTV } from '../../tree-navigation';
import { deleteFunctionArgumentNVTV } from '../../tree-editing/actions-nvtv';
import { constructNodeOperatorsChainNVTV } from '../../tree-operators/utils-nvtv';
import { removeOperatorsChainOperator, assembleOperatorsChain } from '../../tree-operators';
import { findOperatorsChainOperandIndex } from '../../tree-operators/utils';

export const handleTreeKeyRemove = (vtvCtx: IValueTreeViewContext, removeBack: boolean): ValueTreeClientDelta => {
  const { cursorPosition, positionNode } = getCursorPositionNode(vtvCtx)!;
  const removeNeighbour = isValueTreePositionAtStartEnd(positionNode, cursorPosition, !removeBack);

  // Special case for forward removal at the end of function draft
  if (
    isTreeNodeFunction(positionNode) &&
    isTreePositionNodeDraft(cursorPosition) &&
    cursorPosition.at === positionNode.draft.length &&
    !removeBack
  ) {
    return resetTreeNodeNVTV(vtvCtx, positionNode, positionNode.draft);
  }

  // Draft manipulation branch
  if (isTreePositionNodeDraft(cursorPosition) && !removeNeighbour) {
    const nodeDraftDeleteIndex = cursorPosition.at + (removeBack ? -1 : 0);
    const draftIndexIsValid = checkTreeNodeDraftIndexIsValid(positionNode, nodeDraftDeleteIndex);
    // TODO: assert  nodeDraftDeleteIndex is valid i.e. position < length
    return spliceNodeDraftNVTV(vtvCtx, positionNode, nodeDraftDeleteIndex, 1, '');
  }

  // Node removal (node reset) branch
  if (isTreePositionNode(cursorPosition) && !removeNeighbour) {
    // TODO: check if null literal node should be reset or removed
    return resetTreeNodeNVTV(vtvCtx, positionNode);
  }

  // Neighbour manipulation branch
  if (removeNeighbour) {
    const parentInfo = getTreeNodeParentInfoOrUndefinedNVTV(vtvCtx, positionNode);
    if (!parentInfo) {
      // Node is root, there are no neighbours, do nothing
      return {};
    }

    const { chainRootNode, operatorsChain } = constructNodeOperatorsChainNVTV(vtvCtx, positionNode);
    const chainParentInfo = getTreeNodeParentInfoOrUndefinedNVTV(vtvCtx, chainRootNode);
    const operandIndex = findOperatorsChainOperandIndex(operatorsChain, positionNode);
    const targetOperatorIndex = operandIndex + (removeBack ? -1 : 0);

    if (operatorsChain.body.length === 0) {
      // Node removal (node-arg remove) branch
      if (isTreeNodeLiteral(positionNode) && isLiteralVoid(positionNode.literal) && positionNode.draft.length === 0) {
        // Reset parent node
        if (parentInfo.node.arguments.length === 1) {
          // replace function with placeholder
          const placeholderNode: ValueTreeNode = mkTreeLiteral(
            parentInfo.node.key,
            parentInfo.node.draft,
            mkLiteralVoidNull(),
          );

          const nodesUpsert = [placeholderNode];
          return {
            delta: {
              nodes: [
                ...nodesUpsert.map((n) => calculateNodeDeltaNVTV(vtvCtx, n)),
                ...collectTreeNodeDescendantsNVTV(vtvCtx, parentInfo.node).map((n) => mkNodeRemovalDelta(n)),
              ],
            },
            cursor: mkTreeCursorNode(placeholderNode.key, true),
          };
        } else {
          // remove function argument
          return {
            delta: { nodes: deleteFunctionArgumentNVTV(vtvCtx, parentInfo.node, parentInfo.index) },
            cursor: removeBack
              ? mkTreeCursorNode(parentInfo.node.arguments[parentInfo.index > 0 ? parentInfo.index - 1 : 0].key, true)
              : mkTreeCursorNode(
                  parentInfo.node.arguments[
                    parentInfo.index < parentInfo.node.arguments.length - 1
                      ? parentInfo.index + 1
                      : parentInfo.node.arguments.length - 1
                  ].key,
                  false,
                ),
          };
        }
      } else {
        // TODO: move to other argument if exists
        return {};
      }
    }

    if (targetOperatorIndex >= 0 && targetOperatorIndex < operatorsChain.body.length) {
      // Inside current operator chain
      // if ((removeBack && operandIndex === 0) || (!removeBack && operatorsChain.body.length === operandIndex)) {}

      const removedOperatorNode = operatorsChain.body[targetOperatorIndex].operatorNode;
      const [nextOperatorsChain, mergedNode] = removeOperatorsChainOperator(
        operatorsChain,
        targetOperatorIndex,
        removeBack,
      );
      if (!nextOperatorsChain) {
        // Failed to remove the operator (Cannot join operands)
        return {};
      }
      // throw Error(`Unit test: ${JSON.stringify(nextOperatorsChain, null, 2)}`);

      const assembledOperatorsChain = assembleOperatorsChain(nextOperatorsChain);
      // TODO: skip if no changes
      const nextChainParent: ReadonlyArray<ValueTreeNode> = chainParentInfo
        ? [
            {
              ...chainParentInfo.node,
              arguments: [
                ...chainParentInfo.node.arguments.slice(0, chainParentInfo.index),
                mkValueTreeNodeRef(assembledOperatorsChain.rootNode.key),
                ...chainParentInfo.node.arguments.slice(chainParentInfo.index + 1),
              ],
            },
          ]
        : [];
      // throw Error(`Unit test: ${JSON.stringify(mergedNode, null, 2)}`);
      // throw Error(`Unit test: ${JSON.stringify(assembledOperatorsChain, null, 2)}`);

      const nodesUpsert = [
        ...nextChainParent,
        ...assembledOperatorsChain.updatedNodes,
        ...(mergedNode ? [mergedNode] : []),
      ];

      const nodesDelta = [
        ...nodesUpsert.map((n) => calculateNodeDeltaNVTV(vtvCtx, n)),
        ...collectTreeNodeDescendantsNVTV(vtvCtx, positionNode, true).map((n) => mkNodeRemovalDelta(n)),
        mkNodeRemovalDelta(removedOperatorNode),
      ];
      // throw Error(`Unit test: ${JSON.stringify(nodesDelta, null, 2)}`);
      return {
        delta: {
          nodes: nodesDelta,
        },
        // TODO: review cursor position
        cursor: mergedNode && mkTreeCursorNode(mergedNode.key, removeBack),
      };
    } else {
      // Outside current operator chain
    }
  }

  // TODO: review
  return {};
};
