import React, { ReactElement } from 'react';
import { glyphFromAsciiMathStr } from '../../../enjc-glyph';
import { EnjcGlyphPlainView } from './EnjcGlyphPlainView';

interface IProps {
  readonly glyphAsciiMathStr: string;
}

export const EnjcGlyphPlainAdapterView = ({ glyphAsciiMathStr, ...mathProps }: IProps): ReactElement => {
  const enjcGlyph = React.useMemo(() => {
    if (!glyphAsciiMathStr) {
      return undefined;
    }
    try {
      return glyphFromAsciiMathStr(glyphAsciiMathStr);
    } catch (error) {
      return undefined;
    }
  }, [glyphAsciiMathStr]);

  if (!enjcGlyph) {
    return <span style={{ color: 'red' }}>?</span>;
  }

  return <EnjcGlyphPlainView enjcGlyph={enjcGlyph} {...mathProps} />;
};
