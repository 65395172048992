import { ValueTreeNode } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { calculateKeyListDelta, calculateStringDelta, calculateKeySetDelta } from '../../../enjc-delta';
import { ValueTreeNodeMode } from '../../../../generated/graphql/types';
import { mkLiteralVoidNull } from '../../../enjc-literal';

export const calculateNodeDelta = (
  nodeBefore: ValueTreeNode | undefined,
  nodeAfter: ValueTreeNode,
): EnjcValueTreeNodeDelta => {
  // FIXME: review
  return {
    key: nodeAfter.key,
    tags: calculateKeySetDelta(nodeBefore?.tags ?? [], nodeAfter.tags),
    draft: calculateStringDelta(nodeBefore?.draft ?? '', nodeAfter.draft),
    mode:
      nodeBefore?.mode !== nodeAfter.mode
        ? { atomBefore: nodeBefore?.mode ?? ValueTreeNodeMode.Literal, atomAfter: nodeAfter.mode }
        : undefined,
    literal:
      nodeBefore?.literal !== nodeAfter.literal
        ? { atomBefore: nodeBefore?.literal ?? mkLiteralVoidNull(), atomAfter: nodeAfter.literal }
        : undefined,
    symbol:
      nodeBefore?.symbol !== nodeAfter.symbol
        ? { atomBefore: nodeBefore?.symbol?.id, atomAfter: nodeAfter.symbol?.id }
        : undefined,
    function:
      nodeBefore?.function !== nodeAfter.function
        ? { atomBefore: nodeBefore?.function?.id, atomAfter: nodeAfter.function?.id }
        : undefined,
    arguments: calculateKeyListDelta(
      nodeBefore?.arguments.map((n) => n.key) ?? [],
      nodeAfter?.arguments.map((n) => n.key) ?? [],
    ),
    removeAdd: nodeBefore ? undefined : 'add',
  };
};
