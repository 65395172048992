import { getNodeOperator } from '../../enjc-value-operators';
import { TValueTreeNodeOrKey } from '../../enjc-symbol-value-tree/tree-node';
import { INodeParentInfoWithOperator } from '../../enjc-symbol-value-tree/symbol-value-tree-review/interfaces';
import { IValueTreeViewContext } from '../model';
import { getTreeNodeParentInfoOrUndefinedNVTV } from './getTreeNodeParentInfoOrUndefinedNVTV';

export const getNodeParentInfoWithOperatorNVTV = (
  vtvCtx: IValueTreeViewContext,
  nodeOrKey: TValueTreeNodeOrKey,
): INodeParentInfoWithOperator | undefined => {
  const nodeParentInfo = getTreeNodeParentInfoOrUndefinedNVTV(vtvCtx, nodeOrKey);
  const parentOperator = nodeParentInfo && getNodeOperator(nodeParentInfo.node);
  return (
    nodeParentInfo && {
      ...nodeParentInfo,
      operator: parentOperator,
    }
  );
};
