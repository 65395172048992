import React, { ReactElement } from 'react';
import { ISymbolValueGlyphViewProps } from '../../enjc-symbol-value-composite-view/props';
import { isCursorNodeAtEnd, isCursorNodeAtStart } from '../../../../enjc-symbol-value-tree/tree-cursor';
import { SymbolValuePlainViewContainer } from './SymbolValuePlainViewContainer';
import { SymbolValuePlainCursor } from './SymbolValuePlainCursor';
import { EnjcGlyphPlainAdapterView } from '../../enjc-glyph-plain-view';

export const SymbolValueGlyphPlainView = ({ glyph, cursor, onClick }: ISymbolValueGlyphViewProps): ReactElement => {
  return (
    <>
      <SymbolValuePlainViewContainer onClick={onClick}>
        {isCursorNodeAtStart(cursor) && <SymbolValuePlainCursor />}
        <EnjcGlyphPlainAdapterView glyphAsciiMathStr={glyph} />
        {isCursorNodeAtEnd(cursor) && <SymbolValuePlainCursor />}
      </SymbolValuePlainViewContainer>
    </>
  );
};
