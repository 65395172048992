import { TSymbolId } from '../../enjc-workspace';
import { TValueTreeNodeKey } from '../../enjc-symbol-value-tree/tree-node';
import { EnjcWorkspaceDTO } from '../model';

export interface SymbolNode {
  symbolId: TSymbolId;
  nodeKey: TValueTreeNodeKey;
}

export const findWorkspaceSymbolNodes = (
  workspace: EnjcWorkspaceDTO,
  symbolId: TSymbolId,
): ReadonlyArray<SymbolNode> => {
  return workspace.symbols.flatMap((sy) =>
    sy.valueTree.nodes
      .filter((node) => node.symbol?.id === symbolId)
      .map((node) => {
        return { symbolId: sy.id, nodeKey: node.key };
      }),
  );
};
