import { EnjicalcWorkspace } from '../index';
import { WorkspaceItemContainerInfo } from '@/src/libenjc/enjc-workspace/model/WorkspaceItemContainerInfo';

export const findWorkspaceItemContainer = (
  workspace: EnjicalcWorkspace,
  itemId: string,
): WorkspaceItemContainerInfo | undefined =>
  workspace.sections
    .map((se) => {
      const itemIndex = se.items.findIndex((seIt) => seIt.id === itemId);
      return itemIndex >= 0 ? { container: se, index: itemIndex } : undefined;
    })
    .find((icf) => icf !== undefined);
