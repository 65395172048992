import React from 'react';
import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { TSectionId } from '../../libenjc/enjc-workspace';
import {
  editSetSectionTitle,
  EnjicalcWorkspaceUpdateResult,
  WorkspaceEditHistoryEntry,
} from '../../libenjc/enjc-workspace-editing';
import { useCtxEnjicalcWorkspace } from '../../libenjc/enjc-react/enjc-react-context';
import { EnjcSectionFragment } from '../../libenjc/enjicalc-graphql';
import { SearchInput } from '../../components/misc';
import { SheetMenu } from './SheetMenu';
import { QUICK_START_TOUR_STEP_NAMES, QUICK_START_TOUR_STEP_COUNT, useQuickStartTour } from 'src/hooks';

// TODO: replace with interface with typename, id and title
type TSheet = EnjcSectionFragment;

interface IProps {
  readonly sheets: ReadonlyArray<TSheet>;
  readonly openSheets: ReadonlyArray<TSheet>;
  readonly activeSheet?: TSheet;
  readonly onSheetOpen: (sheetSectionId: TSectionId) => void;
  readonly onSheetCreate: () => void;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateResult>;

  readonly onSearchTermChange?: (text: string) => void;
}

const WorkspaceSheetsSidebarPrimaryF = ({
  sheets,
  openSheets,
  activeSheet,
  onSheetOpen,
  onSheetCreate,
  performWorkspaceEdit,
  onSearchTermChange,
}: IProps): React.ReactElement => {
  const { setCurrentStep, setIsOpen, isOpen } = useQuickStartTour();

  const { workspace } = useCtxEnjicalcWorkspace();

  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [editingSheetId, setEditingSheetId] = React.useState<string | null>(null);
  const [titleValue, setTitleValue] = React.useState<string>('');

  const filteredSheets = React.useMemo(() => {
    return sheets.filter((sheet) => !searchTerm || sheet.title.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, sheets]);

  const handleSearchTermChange = React.useCallback(
    (nextSearchTerm: string) => {
      setSearchTerm(nextSearchTerm);
      onSearchTermChange && onSearchTermChange(nextSearchTerm);
    },
    [onSearchTermChange],
  );

  const handleTitleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTitleValue(e.target.value),
    [],
  );

  const setEnjcWorkspaceTitle = React.useCallback(
    (nextTitle: string) => {
      if (editingSheetId) {
        const hEntry = editSetSectionTitle(workspace, editingSheetId, nextTitle);
        performWorkspaceEdit(hEntry);
        setEditingSheetId(null);
      }
    },
    [performWorkspaceEdit, workspace, editingSheetId],
  );

  const handleTitleKeyDown = React.useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setEnjcWorkspaceTitle(titleValue);
      }
    },
    [titleValue, setEnjcWorkspaceTitle],
  );

  const handleInputClick = React.useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }, []);

  const handleRenameClick = React.useCallback((sheetId: string, currentTitle: string) => {
    setEditingSheetId(sheetId);
    setTitleValue(currentTitle);
  }, []);

  const handleInputBlur = React.useCallback(() => {
    setEnjcWorkspaceTitle(titleValue);
  }, [titleValue, setEnjcWorkspaceTitle]);

  React.useEffect(() => {
    if (isOpen) {
      setIsOpen(true);
      setCurrentStep(QUICK_START_TOUR_STEP_COUNT.CreateSheet);
    }
  }, [setCurrentStep, setIsOpen, isOpen]);

  const renderTitle = React.useCallback(
    (sheet: TSheet) => {
      if (editingSheetId === sheet.id) {
        return (
          <Input
            placeholder="Empty"
            value={titleValue}
            onKeyDown={handleTitleKeyDown}
            onChange={handleTitleChange}
            onBlur={handleInputBlur}
            variant="flushed"
            paddingLeft={4}
            focusBorderColor="primary.100"
            borderBottomWidth={0}
            h="100%"
            autoFocus
            onClick={handleInputClick}
          />
        );
      }

      return (
        <Text isTruncated textAlign="left" ml={'16px'} color={'black'}>
          {sheet.title || 'Untitled'}
        </Text>
      );
    },
    [editingSheetId, titleValue, handleTitleKeyDown, handleTitleChange, handleInputClick, handleInputBlur],
  );

  return (
    <VStack w={'100%'} h={'100%'} align={'stretch'} p={'8px 0px'}>
      <VStack align={'start'}>
        <HStack justify={'space-between'} alignItems={'flex-start'} maxW={'210px'} m={'0px 16px 2px'}>
          <Text isTruncated fontSize={'12px'}>
            {'WORKSPACE SHEETS'}
          </Text>
        </HStack>

        <HStack maxW={'210px'} m={'0px 16px'}>
          <SearchInput searchTerm={searchTerm} onSearchTermChange={handleSearchTermChange} />
        </HStack>

        <Button
          m={'0px 16px 0px 16px'}
          pl="20px"
          w={'210px'}
          onClick={onSheetCreate}
          colorScheme="white"
          backgroundColor="primary.100"
          _hover={{ bg: 'primary.200' }}
          style={{ fontSize: '15px' }}
          _active={{ opacity: '0.95', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 24px 48px' }}
          className={QUICK_START_TOUR_STEP_NAMES.CreateSheet}
        >
          {'Create Sheet'}
        </Button>
      </VStack>

      <VStack overflowY="auto" align={'stretch'} flexGrow={1} p={'0px'} spacing={0}>
        {filteredSheets.map((sheet) => (
          <HStack
            key={sheet.id}
            justify={'space-between'}
            cursor={'pointer'}
            onClick={() => {
              onSheetOpen(sheet.id);
              setCurrentStep(QUICK_START_TOUR_STEP_COUNT.AddSymbol);
            }}
            backgroundColor={
              sheet.id === activeSheet?.id ? 'gray.50' : openSheets.find((s) => sheet.id === s?.id) && 'white'
            }
            _hover={{ bg: 'gray.100' }}
            pr={'3px'}
            className={QUICK_START_TOUR_STEP_NAMES.OpenSheet}
          >
            {renderTitle(sheet)}

            <SheetMenu
              workspace={workspace}
              sheet={sheet}
              onSheetOpen={onSheetOpen}
              performWorkspaceEdit={performWorkspaceEdit}
              onRenameTitle={() => handleRenameClick(sheet.id, sheet.title)}
            />
          </HStack>
        ))}
      </VStack>
    </VStack>
  );
};

export const WorkspaceSheetsSidebarPrimary = React.memo(WorkspaceSheetsSidebarPrimaryF);
