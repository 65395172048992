import React, { ReactElement } from 'react';
import { Button } from '@chakra-ui/react';
import { ButtonProps } from '@chakra-ui/button/dist/button';
import { VscFilePdf } from 'react-icons/vsc';
import { QUICK_START_TOUR_STEP_NAMES } from 'src/hooks';

type FComp = (props: ButtonProps) => ReactElement;

export const PrintButton = React.memo<FComp>((props) => {
  return (
    <Button
      fontSize="xs"
      _active={{ bg: 'white', color: 'white' }}
      colorScheme="white"
      rightIcon={<VscFilePdf size={20} />}
      variant="link"
      className={QUICK_START_TOUR_STEP_NAMES.Print}
      {...props}
    >
      PRINT TO PDF
    </Button>
  );
});
