import { calculateStringDelta } from '../../enjc-delta';
import { EnjicalcWorkspace, getEnjcSection, TSectionId } from '../../enjc-workspace';
import { WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry } from '../utils';

export const editSetSectionTitle = (
  workspace: EnjicalcWorkspace,
  sectionId: TSectionId,
  title: string,
): WorkspaceEditHistoryEntry => {
  const existingSection = getEnjcSection(workspace, sectionId);
  const sectionDeltaPart = calculateStringDelta(existingSection.title, title);
  return mkWorkspaceEditHistoryEntry('editSetSectionTitle', {
    section: sectionDeltaPart && [{ id: sectionId, title: sectionDeltaPart }],
  });
};
