import React, { ReactElement } from 'react';
import { Wrap, WrapItem, Text, Box } from '@chakra-ui/react';
import { EnjicalcWorkspaceMeta } from '../../../libenjc/enjc-workspace';
import { EnjicalcWorkspaceUpdateResult, WorkspaceEditHistoryEntry } from '../../../libenjc/enjc-workspace-editing';
import { WorkspaceCard } from './WorkspaceCard';

interface IProps {
  readonly workspaces: ReadonlyArray<EnjicalcWorkspaceMeta>;
  readonly performWorkspaceEdit: (
    workspace: EnjicalcWorkspaceMeta,
    editEntry: WorkspaceEditHistoryEntry,
  ) => Promise<EnjicalcWorkspaceUpdateResult>;
}

const WorkspacesListViewF = ({ workspaces, performWorkspaceEdit }: IProps): ReactElement => {
  if (!workspaces.length) {
    return (
      <Box display="flex" pt="150px" flexDirection="column" alignItems="left" height="100%" width="100%">
        <Text fontSize="2xl" fontWeight="bold" color="dimgray">
          No workspaces found.
        </Text>
        <Text fontSize="md">It looks like you don't have any workspaces yet.</Text>
      </Box>
    );
  }

  return (
    <Wrap spacing="0px">
      {workspaces.map((workspace) => (
        <WrapItem key={workspace.id}>
          <WorkspaceCard
            workspace={workspace}
            performWorkspaceEdit={(editEntry: WorkspaceEditHistoryEntry) => performWorkspaceEdit(workspace, editEntry)}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
};

export const WorkspacesListView = React.memo(WorkspacesListViewF);
