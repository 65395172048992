import { mkLiteralVoidNull, UValueLiteral } from '../../../enjc-literal';
import { ValueTreeNode } from '../model';
import { ValueTreeNodeMode } from '../../../../generated/graphql/types';

export const mkTreeLiteral = (key: string, draft: string, literal: UValueLiteral): ValueTreeNode => ({
  __typename: 'ValueTreeNode',
  key,
  tags: [],
  draft,
  mode: ValueTreeNodeMode.Literal,
  literal,
  arguments: [],
  result: mkLiteralVoidNull(),
});
