import React, { ReactElement } from 'react';
import { Highlight } from '@chakra-ui/react';
import { isLiteralVoid, UValueLiteral, valueLiteralToString } from '../../../libenjc/enjc-literal';

interface IProps {
  readonly literal: UValueLiteral;
  // TODO: review
  // readonly localEditor?: boolean;
}

const SymbolResultViewF = ({ literal }: IProps): ReactElement => {
  const formattedValue = valueLiteralToString(literal);

  return (
    <div className={'value'}>
      {!isLiteralVoid(literal) ? (
        formattedValue
      ) : (
        <Highlight
          query={'NaN'}
          styles={{
            px: '1.5',
            py: '0',
            mx: '0',
            my: '0',
            rounded: 'var(--app-border-radius)',
            bg: 'rgba(255, 132, 0, 0.7)',
            opacity: '1',
            height: 'min',
          }}
        >
          {'NaN'}
        </Highlight>
      )}
    </div>
  );
};

export const SymbolResultView = React.memo(SymbolResultViewF);
