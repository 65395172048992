import { IValueTreeViewContext, getCursorPositionNode } from '../../../enjc-value-view-ctx';
import { ValueTreeClientDelta } from '../../tree-editing';
import { getFunctionBinaryOperatorById, getNodeOperator } from '../../../enjc-value-operators';
import { isTreePositionNode } from '../../tree-position';
import { isTreeNodeFunction } from '../../tree-node';
import { addBinaryOperatorVvCtx } from '../../tree-navigation';
import { TNavKeyOperator } from '../types';
import { applyModifierToOperatorNVTV, operatorKeyToFunctionId } from '../utils';

export const handleTreeKeyOperator = (vtvCtx: IValueTreeViewContext, navKey: TNavKeyOperator): ValueTreeClientDelta => {
  const { cursorPosition, positionNode } = getCursorPositionNode(vtvCtx)!;

  const keyFunctionId = operatorKeyToFunctionId(navKey);
  const keyOperator = getFunctionBinaryOperatorById(keyFunctionId);

  if (!keyOperator) {
    throw new Error(`Operator with id ${keyFunctionId} not found`);
  }

  if (isTreePositionNode(cursorPosition) && isTreeNodeFunction(positionNode)) {
    const positionOperator = getNodeOperator(positionNode);
    if (positionOperator) {
      return applyModifierToOperatorNVTV(vtvCtx, positionNode, positionOperator, keyOperator);
    }
  }

  return addBinaryOperatorVvCtx(vtvCtx, positionNode, cursorPosition, keyOperator);
};
