import React, { ReactElement } from 'react';
import { ISymbolValueDraftViewProps } from '../../enjc-symbol-value-composite-view/props';
import { isDraftPositionValid } from '../../enjc-symbol-value-composite-view/utils';

export const SymbolValueDraftMathmlView = ({ draft, position, onClick }: ISymbolValueDraftViewProps): ReactElement => {
  const draftRef = React.useRef(null);

  return position !== undefined && isDraftPositionValid({ draft, position }) ? (
    // FIXME
    <mrow onClick={onClick}>
      {position > 0 && <mtext>{draft.substring(0, position)}</mtext>}
      {/*<SymbolValuePlainCursor />*/}
      {position < draft.length && <mtext>{draft.substring(position)}</mtext>}
    </mrow>
  ) : (
    <mrow onClick={onClick}>
      {/* TODO: change color if literalIsVoid */}
      <mtext ref={draftRef} mathcolor={false ? 'red' : undefined}>
        {draft}
      </mtext>
    </mrow>
  );
};
