import { EnjcKeyListDeltaEntry } from '../model';
import { countMatchingKeys } from '../../enjc-misc';

export const calculateKeyListDeltaEntry = (
  keyListBefore: ReadonlyArray<string>,
  keyListAfter: ReadonlyArray<string>,
): EnjcKeyListDeltaEntry | undefined => {
  const matchingStart = countMatchingKeys(keyListBefore, keyListAfter, false);
  const matchingEnd = countMatchingKeys(keyListBefore, keyListAfter, true);
  if (keyListBefore.length === keyListAfter.length && keyListBefore.length === matchingStart) {
    // Return undefined if key lists are matching
    return undefined;
  }

  const minLen = keyListAfter.length <= keyListBefore.length ? keyListAfter.length : keyListBefore.length;
  const matchingEndUsed = matchingStart + matchingEnd > minLen ? minLen - matchingStart : matchingEnd;
  const sliceBefore = keyListBefore.slice(matchingStart, keyListBefore.length - matchingEndUsed);
  const sliceAfter = keyListAfter.slice(matchingStart, keyListAfter.length - matchingEndUsed);

  return {
    slicePosition: matchingStart === 0 ? undefined : matchingStart,
    sliceBefore: sliceBefore.length > 0 ? sliceBefore : undefined,
    sliceAfter: sliceAfter.length > 0 ? sliceAfter : undefined,
  };
};
