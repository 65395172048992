import { EnjicalcWorkspace, getEnjcSection, TSectionId } from '../../enjc-workspace';
import {
  EnjcWorkspaceSectionDelta,
  EnjcWorkspaceSymbolDelta,
  WorkspaceEditHistoryEntry,
} from '../../enjc-workspace-editing';
import { WORKSPACE_CREATE_ID } from '../../enjc-client/constants';
import { mkLiteralNumber, mkLiteralVoidNull, UValueLiteral } from '../../enjc-literal';
import { mkAtomDelta } from '../../enjc-delta';
import { mkWorkspaceEditHistoryEntry } from '../utils';

export const editCreateSymbolIn = (workspace: EnjicalcWorkspace, sectionId: TSectionId): WorkspaceEditHistoryEntry => {
  const existingSection = getEnjcSection(workspace, sectionId);

  const sectionDelta: EnjcWorkspaceSectionDelta = {
    id: existingSection.id,
    items: [{ slicePosition: existingSection.items.length, sliceAfter: [WORKSPACE_CREATE_ID] }],
  };

  const symbolDelta: EnjcWorkspaceSymbolDelta = {
    id: WORKSPACE_CREATE_ID,
    valueTree: {
      nodes: [
        {
          key: 'ab',
          // This literal works as a more recognizable (compared to square of
          // null literal) placeholder for initial empty draft
          literal: mkAtomDelta<UValueLiteral>(mkLiteralVoidNull(), mkLiteralNumber(0)),
        },
      ],
    },
  };

  return mkWorkspaceEditHistoryEntry('editCreateSymbolIn', {
    section: [sectionDelta],
    symbol: [symbolDelta],
  });
};
