import React, { ReactElement } from 'react';
import { ISymbolValueGlyphViewProps } from '../../enjc-symbol-value-composite-view/props';
import { MathMLGlobalAttributes } from '../../../../enjc-react-mathml/attributes';
import { isCursorNodeShadow } from '../../../../enjc-symbol-value-tree/tree-cursor';
import { EnjcGlyphMathmlAdapterView } from '../../enjc-glyph-mathml-view';

export const SymbolValueGlyphMathmlView = ({ glyph, cursor, onClick }: ISymbolValueGlyphViewProps): ReactElement => {
  const mathProps: MathMLGlobalAttributes = { mathbackground: isCursorNodeShadow(cursor) ? 'lightgray' : undefined };
  return <EnjcGlyphMathmlAdapterView glyphAsciiMathStr={glyph} {...mathProps} onClick={onClick} />;
};
