import { countMatchingChars } from '../../enjc-misc';
import { EnjcStringDeltaEntry } from '../model';

export const calculateStringDeltaEntry = (strBefore: string, strAfter: string): EnjcStringDeltaEntry | undefined => {
  if (strBefore === strAfter) {
    // Return undefined if strings are matching
    return undefined;
  }

  const matchingStart = countMatchingChars(strBefore, strAfter, false);
  const matchingEnd = countMatchingChars(strBefore, strAfter, true);
  const minLen = strAfter.length <= strBefore.length ? strAfter.length : strBefore.length;
  const matchingEndUsed = matchingStart + matchingEnd > minLen ? minLen - matchingStart : matchingEnd;
  const sliceBefore = strBefore.slice(matchingStart, strBefore.length - matchingEndUsed);
  const sliceAfter = strAfter.slice(matchingStart, strAfter.length - matchingEndUsed);

  return {
    slicePosition: matchingStart === 0 ? undefined : matchingStart,
    sliceBefore: sliceBefore ? sliceBefore : undefined,
    sliceAfter: sliceAfter ? sliceAfter : undefined,
  };
};
