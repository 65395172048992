import { ValueTreeFragment } from '../../enjicalc-graphql';
import { UValueLiteral } from '../../enjc-literal';
import { EnjicalcWorkspace, TSymbolId } from '../../enjc-workspace';
import { evaluateValueTreeBranch } from './evaluateValueTreeBranch';

export const evaluateValueTree = (workspace: EnjicalcWorkspace, valueTree: ValueTreeFragment): ValueTreeFragment => {
  if (!valueTree.rootNode?.key) return valueTree;
  const evaluatorSymbols = new Map<TSymbolId, UValueLiteral>(workspace.symbols.map((s) => [s.id, s.valueTree.result]));
  const evalResult = evaluateValueTreeBranch(valueTree, valueTree.rootNode.key, evaluatorSymbols);
  return {
    __typename: 'ValueTree',
    rootNode: valueTree.rootNode,
    nodes: [evalResult.node, ...evalResult.args],
    result: evalResult.node.result,
  };
};
