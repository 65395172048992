import {
  collectSectionSymbols,
  EnjcWorkspaceItemVisibility,
  findWorkspaceItemContainers,
  findWorkspaceSymbolNodes,
  getEnjcSection,
  isEnjcSection,
  TSectionId,
  collectSectionItems,
} from '../../enjc-workspace';
import { EnjcWorkspaceDTO } from '../../enjc-workspace/model';
import { mkAtomDelta } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta, EnjcWorkspaceSymbolDelta, WorkspaceEditHistoryEntry } from '../model';
import { mkWorkspaceEditHistoryEntry, changeSetSymbolVisibility, changeSetSectionVisibility } from '../utils';

export const editDeleteSection = (workspace: EnjcWorkspaceDTO, sectionId: TSectionId): WorkspaceEditHistoryEntry => {
  const existingSection = getEnjcSection(workspace, sectionId);
  const sectionContainers = findWorkspaceItemContainers(workspace, sectionId);
  // TODO: group by container id
  const containerDeltas: EnjcWorkspaceSectionDelta[] = sectionContainers.map((iCt) => {
    return {
      id: iCt.container.id,
      items: [
        {
          slicePosition: iCt.index,
          sliceBefore: [iCt.container.items[iCt.index].id],
        },
      ],
    };
  });
  const sectionDeltas = [existingSection, ...collectSectionItems(workspace, existingSection)]
    .map((sectionItem) => {
      return isEnjcSection(sectionItem)
        ? changeSetSectionVisibility(sectionItem, EnjcWorkspaceItemVisibility.Deleted)
        : undefined;
    })
    .flatMap((tDelta) => (tDelta ? [tDelta] : []));
  const deletedSymbols = collectSectionSymbols(workspace, existingSection);
  const symbolDeltas = deletedSymbols
    .map((tSy) => changeSetSymbolVisibility(tSy, EnjcWorkspaceItemVisibility.Deleted))
    .flatMap((tDelta) => (tDelta ? [tDelta] : []));
  const deletedSymbolsId = deletedSymbols.map((sy) => sy.id);
  const symbolsNodeDeltas: EnjcWorkspaceSymbolDelta[] = deletedSymbolsId.flatMap((delSymId) => {
    const symbolNodes = findWorkspaceSymbolNodes(workspace, delSymId);
    return symbolNodes
      .filter((syNo) => !deletedSymbolsId.includes(syNo.symbolId))
      .map((syNo) => {
        return { id: syNo.symbolId, valueTree: { nodes: [{ key: syNo.nodeKey, symbol: mkAtomDelta(delSymId, '') }] } };
      });
  });
  const symbolCombinedDeltas: EnjcWorkspaceSymbolDelta[] = [...symbolDeltas, ...symbolsNodeDeltas];
  const sectionCombinedDeltas = [...containerDeltas, ...sectionDeltas];
  return mkWorkspaceEditHistoryEntry('editDeleteSection', {
    section: sectionCombinedDeltas.length > 0 ? sectionCombinedDeltas : undefined,
    symbol: symbolCombinedDeltas.length > 0 ? symbolCombinedDeltas : undefined,
  });
};
