import React from 'react';
import FocusLock from 'react-focus-lock';
import { Text, Button, Box } from '@chakra-ui/react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/shadcn';

interface IProps {
  onConfirm: () => void;
  children: React.ReactNode;
}

export const DeleteConfirmationPopover = ({ onConfirm, children }: IProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const firstFieldRef = React.useRef(null);

  const handleToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
    setInputValue(''); // Reset input value when toggling the popover
  }, [isOpen]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleConfirm = () => {
    if (inputValue === 'delete') {
      onConfirm();
      handleToggle();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.toLowerCase() === 'delete') {
      handleConfirm();
    }
  };

  return (
    <Popover open={isOpen} onOpenChange={handleToggle}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="p-0">
        <FocusLock returnFocus persistentFocus={false} className="rounded-lg shadow-[0px_24px_48px_rgba(0,0,0,0.16)]">
          <Box p="4">
            <Text fontSize="0.875rem" textColor="black" mb="2">
              This action <b>CANNOT</b> be undone. <br /> Type 'delete' to confirm:
            </Text>
            <input
              ref={firstFieldRef}
              value={inputValue}
              onChange={handleInputChange}
              placeholder="delete"
              className="border focus-visible:outline-none"
              style={{
                padding: '8px',
                width: '100%',
                marginBottom: '8px',
                fontSize: '0.875rem',
                borderRadius: '0.375rem',
              }}
              onKeyDown={handleKeyDown}
            />
            <Button
              w="100%"
              fontSize="0.875rem"
              colorScheme="red"
              onClick={handleConfirm}
              isDisabled={inputValue !== 'delete'}
            >
              Confirm
            </Button>
          </Box>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
