import React, { ReactElement } from 'react';
import { EnjcGlyph } from '../../../enjc-glyph';
import { EnjcGlyphItemPlainView } from './EnjcGlyphItemPlainView';

interface IProps {
  readonly enjcGlyph: EnjcGlyph;
}

export const EnjcGlyphPlainView = ({ enjcGlyph }: IProps): ReactElement => {
  return (
    <>
      {enjcGlyph.map((glyi) => (
        <EnjcGlyphItemPlainView enjcGlyphItem={glyi} />
      ))}
    </>
  );
};
