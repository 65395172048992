import { TValueTreeNodeKey } from '../tree-node';
import { ValueTreeStructureError } from './ValueTreeStructureError';

export class ValueTreeFunctionInvalidArgumentIndexError extends ValueTreeStructureError {
  constructor(argumentIndex: number, nodeKey: TValueTreeNodeKey, argumentCount: number) {
    super(
      `Invalid TreeFunction argument index: ${argumentIndex}. TreeFunction with node key '${nodeKey}' has ${argumentCount} arguments.`,
    );
    this.name = 'ValueTreeFunctionInvalidArgumentIndexError';
  }
}
