import { mkLiteralNumber, mkLiteralVoid, mkLiteralVoidNull, ValueLiteralVoidCause } from '../../../enjc-literal';
import { isTreeNodeFunction, isTreeNodeLiteral, mkTreeLiteral, ValueTreeNode } from '../../tree-node';
import { mkValueFunctionRef } from '../../../enjc-workspace';

/**
 * Updates the draft of a given ValueTreeNode.
 * @param node - The ValueTreeNode to update.
 * @param nextDraft - The new draft value.
 * @returns - The updated ValueTreeNode.
 */
export const updateNodeDraft = (node: ValueTreeNode, nextDraft: string): ValueTreeNode => {
  const draftNumber = nextDraft.length > 0 ? Number(nextDraft) : NaN;

  if (isTreeNodeLiteral(node)) {
    return mkTreeLiteral(node.key, nextDraft, !isNaN(draftNumber) ? mkLiteralNumber(draftNumber) : mkLiteralVoidNull());
  } else if (isTreeNodeFunction(node)) {
    return { ...node, draft: nextDraft, function: mkValueFunctionRef(node.draft.length === 0 ? 'fg.noop' : 'fg.stub') };
  } else {
    return {
      ...node,
      draft: nextDraft,
    };
  }
};
