import { isTreeNodeFunction, TValueTreeNodeOrKey } from '../../tree-node';
import { getNodeOperator } from '../../../enjc-value-operators';
import { mkTreePositionNode } from './mkTreePositionNode';
import { mkTreePositionNodeDraft } from './mkTreePositionNodeDraft';
import { UValueTreePosition } from '../types';
import { IValueTreeViewContext, getTreeNodeParentInfoOrUndefinedNVTV } from '../../../enjc-value-view-ctx';
import { findNodePositionNVTV } from './findNodePositionNVTV';

export const findNodePositionParentNeighbourNVTV = (
  vtvCtx: IValueTreeViewContext,
  nodeOrKey: TValueTreeNodeOrKey,
  searchForward: boolean,
): UValueTreePosition | undefined => {
  const parentInfo = getTreeNodeParentInfoOrUndefinedNVTV(vtvCtx, nodeOrKey);
  if (!parentInfo) {
    return undefined;
  }

  const nextArgIndex = parentInfo.index + (searchForward ? 1 : -1);
  const moveParent = nextArgIndex < 0 || nextArgIndex >= parentInfo.node.arguments.length;

  if (moveParent) {
    return getNodeOperator(parentInfo.node)
      ? findNodePositionParentNeighbourNVTV(vtvCtx, parentInfo.node, searchForward)
      : isTreeNodeFunction(parentInfo.node) &&
          parentInfo.node.function?.id === 'fg.stub' &&
          parentInfo.node.draft.length > 0 &&
          !searchForward
        ? mkTreePositionNodeDraft(parentInfo.node.key, parentInfo.node.draft.length)
        : mkTreePositionNode(parentInfo.node.key, searchForward);
  } else {
    return findNodePositionNVTV(vtvCtx, parentInfo.node.arguments[nextArgIndex].key, searchForward);
  }
};
