export interface ISheetUpdate {
  title: string;
  sections: ReadonlyArray<ISheetUpdateSection>;
}

interface ISheetUpdateSection {
  title: string;
  symbols: ReadonlyArray<ISheetUpdateSymbol>;
}

interface ISheetUpdateSymbol {
  glyph: string;
  valueTree: ReadonlyArray<USheetUpdateNode>;
  unit: string;
  description: string;
  comment: string;
  varname: string;
}

export type USheetUpdateNode = ISheetUpdateTreeLiteral | ISheetUpdateTreeSymbol | ISheetUpdateTreeFunction;

interface ISheetUpdateTreeLiteral {
  key: string;
  literal: number;
}

interface ISheetUpdateTreeSymbol {
  key: string;
  symbol: string;
}

interface ISheetUpdateTreeFunction {
  key: string;
  function: string;
  arguments: ReadonlyArray<string>;
}

export const parseSheetUpdateJson = (value: string): ISheetUpdate => JSON.parse(value);
