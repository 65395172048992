import { EnjicalcSymbol } from '../../enjc-workspace';
import { EnjicalcWorkspaceUpdateResult } from '../../enjc-workspace-editing';
import { WORKSPACE_CREATE_ID } from '../constants';
import { extractUpdateAllocatedId } from './extractUpdateAllocatedId';

export const extractUpdateCreatedSymbol = (updateResult: EnjicalcWorkspaceUpdateResult): EnjicalcSymbol => {
  const allocatedId = extractUpdateAllocatedId(updateResult, WORKSPACE_CREATE_ID);
  if (!allocatedId) {
    throw Error('Cannot find allocated ID for the created section');
  }
  const createdSymbol = updateResult.symbols.find((upSy) => upSy.id === allocatedId.id);
  if (!createdSymbol) {
    throw Error(`Cannot find created symbol with ID '${allocatedId.id}'`);
  }
  return createdSymbol;
};
