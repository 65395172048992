import { createContext, ReactNode, useCallback, useState } from 'react';

interface ISideBarContext {
  isShow: boolean;
  onToggle: () => void;
}

export const SideBarContext = createContext<ISideBarContext>({
  isShow: true,
  onToggle: () => {},
});

export const SideBarProvider = ({ children }: { children: ReactNode }) => {
  const [isShow, setIsShow] = useState(true);

  const handleToggle = useCallback(() => setIsShow((prevState) => !prevState), []);

  const value = {
    isShow,
    onToggle: handleToggle,
  };

  return <SideBarContext.Provider value={value}>{children}</SideBarContext.Provider>;
};
