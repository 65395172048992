import React, { ReactElement, useEffect, useRef } from 'react';
import { List, ListItem } from '@chakra-ui/react';
import { useValueTreeViewContext } from '../../../enjc-react-ui';
import { useCtxEnjicalcSheet } from '../../../enjc-react-context';
import { getValueHintEntries } from '../../../../enjc-value-view-ctx';
import { EnjcGlyphMathBlockView } from '../../../../../components/editors/symbol-math-view';
import { UValueHintItem } from '../../../../enjc-value-view-ctx/model/IValueTreeViewContext';
import { EnjicalcSymbol } from '../../../../enjc-workspace';
import { valueLiteralToString } from '../../../../enjc-literal';

export const isHintEntrySymbol = (entry: UValueHintItem): entry is EnjicalcSymbol =>
  '__typename' in entry && entry.__typename === 'EnjcSymbol';

interface Props {
  readonly onHintClick?: (hint: UValueHintItem) => void;
}

export const SymbolValueHintsView = ({ onHintClick }: Props): ReactElement => {
  const vtvCtx = useValueTreeViewContext();
  const { symbol, valueHintItem } = vtvCtx;
  const symbolSheet = useCtxEnjicalcSheet().sheet;
  const valueHints = getValueHintEntries(vtvCtx, symbol, symbolSheet);

  console.log(`[DEBUG] SymbolValueHintsView symbolSheet ${symbolSheet.id}-'${symbolSheet.title}'`);

  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (listRef.current && valueHintItem) {
      const selectedItem = listRef.current.querySelector(`li[data-id="${valueHintItem.id}"]`);
      if (selectedItem) {
        selectedItem?.scrollIntoView({ behavior: 'auto', block: 'end' });
      }
    }
  }, [valueHintItem, valueHints]);

  return (
    <List ref={listRef}>
      {valueHints.map((hSy) => (
        <ListItem
          width="100%"
          key={hSy.item.id}
          data-id={hSy.item.id}
          background={valueHintItem?.id === hSy.item.id ? 'gray.200' : undefined}
          cursor="pointer"
          _hover={{ bg: 'gray.300' }}
          px={2}
          py={1}
          borderRadius={0}
          onClick={() => onHintClick?.(hSy.item)}
        >
          {isHintEntrySymbol(hSy.item) ? (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.25rem' }}>
              <span style={{ fontSize: '12px', color: '#718096' }}>{`${hSy.sheetIndex}.`}</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <EnjcGlyphMathBlockView glyph={hSy.item.glyph} mathProps={{}} />
              </span>
              <span style={{ display: 'flex', alignItems: 'center' }}>{' = '}</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {valueLiteralToString(hSy.item.valueTree.result)}
              </span>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '12px',
                  color: '#718096',
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '0.25rem',
                }}
              >
                {hSy.item.description}
              </span>
            </div>
          ) : (
            <EnjcGlyphMathBlockView glyph={hSy.item.glyph} mathProps={{}} />
          )}
        </ListItem>
      ))}
    </List>
  );
};
