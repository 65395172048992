import React, { ReactElement } from 'react';
import { assertNever } from '../../../../utils';
import { EnjcGlyphPrimitive } from '../../../enjc-glyph/model';
import { MathMathMLAttributes } from '../../../enjc-react-mathml/attributes';
import { EnjcGlyphGroupMathmlView } from './EnjcGlyphGroupMathmlView';

interface IProps extends MathMathMLAttributes {
  readonly glyphPrimitive: EnjcGlyphPrimitive;
}

export const EnjcGlyphPrimitiveMathmlView = ({ glyphPrimitive, ...mathProps }: IProps): ReactElement => {
  switch (glyphPrimitive.__typename) {
    case 'EnjcGlyphGroup':
      return <EnjcGlyphGroupMathmlView glyphGroup={glyphPrimitive} {...mathProps} />;
    case 'EnjcGlyphV':
      return <mi {...mathProps}>{glyphPrimitive.value}</mi>;
    case 'EnjcGlyphCmd':
      return (
        <mrow {...mathProps}>
          {glyphPrimitive.args.map((cmdArg) => (
            <EnjcGlyphPrimitiveMathmlView glyphPrimitive={cmdArg} />
          ))}
        </mrow>
      );
    default:
      return assertNever(glyphPrimitive);
  }
};
