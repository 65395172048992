import React, { ReactElement } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogTitle,
} from 'src/shadcn';

interface IProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
}

export const CloseSymbolEditorDialog = ({ isOpen, onClose, onConfirm }: IProps): ReactElement => {
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogOverlay />

      <AlertDialogContent style={{ zIndex: 2000 }}>
        <AlertDialogHeader>
          <AlertDialogTitle>Confirm Close</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to close the Symbol Editor? Any unsaved changes will be lost.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>Close</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
