import React, { ReactElement, ReactNode } from 'react';
import { EnjicalcWorkspace } from '../../../enjc-workspace';
import { EnjicalcWorkspaceContext } from '../context';

interface IProps {
  readonly workspace: EnjicalcWorkspace;
  readonly children: ReactNode;
}

type FComp = (props: IProps) => ReactElement;
export const WorkspaceContextProvider = React.memo<FComp>(({ workspace, children }) => {
  return <EnjicalcWorkspaceContext.Provider value={{ workspace }}>{children}</EnjicalcWorkspaceContext.Provider>;
});
