import { ReactElement, ReactNode } from 'react';
import { HStack } from '@chakra-ui/react';

interface IProps {
  readonly children: ReadonlyArray<ReactNode>;
  readonly onClick?: () => void;
}

export const SymbolValuePlainViewContainer = ({ children, onClick }: IProps): ReactElement => {
  return (
    <HStack display={'inline'} position={'relative'} onClick={onClick}>
      {children}
    </HStack>
  );
};
