import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { ErrorMessage } from '../components/errors';
// import { SheetEditor } from '../components/editors';
// import { WorkspaceSheetLoader } from '../components/loaders';

export const SheetRoute = (): ReactElement => {
  const { sheetId } = useParams<{ sheetId: string }>();

  if (!sheetId) {
    return <ErrorMessage title={'SheetRoute Error'} description={'undefined sheetId'} />;
  }

  //TODO: implement
  return <></>;
  // return <WorkspaceSheetLoader sheetId={sheetId} sheetViewer={(props) => <SheetEditor {...props} />} />;
};
