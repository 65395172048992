import React, { ReactElement } from 'react';
import { ISymbolValueBinaryOperatorViewProps } from '../../enjc-symbol-value-composite-view/props';
import { SymbolValuePlainViewContainer } from './SymbolValuePlainViewContainer';

export const SymbolValueBinaryOperatorPlainView = ({
  operator,
  argLeft,
  argRight,
}: ISymbolValueBinaryOperatorViewProps): ReactElement => {
  const unicodeOperator = operator.glyph;
  return (
    <SymbolValuePlainViewContainer>
      {argLeft}
      <span>{` ${unicodeOperator} `}</span>
      {argRight}
    </SymbolValuePlainViewContainer>
  );
};
