import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdMoreHoriz, MdOutlineDriveFileRenameOutline, MdOutlineFileOpen } from 'react-icons/md';
import { EnjcWorkspaceItemVisibility, EnjicalcWorkspaceMeta } from '../../../libenjc/enjc-workspace';
import {
  EnjicalcWorkspaceUpdateResult,
  WorkspaceEditHistoryEntry,
  editSetWorkspaceVisibility,
} from '../../../libenjc/enjc-workspace-editing';
import { TbArchiveFilled, TbArchiveOff } from 'react-icons/tb';
import { CiViewTable } from 'react-icons/ci';
import { IoCloseSharp } from 'react-icons/io5';
import { DeleteConfirmationPopover } from 'src/components/misc';

interface IProps {
  readonly workspace: EnjicalcWorkspaceMeta;
  readonly onOpenRenameTitle: () => void;
  readonly performWorkspaceEdit: (editEntry: WorkspaceEditHistoryEntry) => Promise<EnjicalcWorkspaceUpdateResult>;
}

const WorkspaceMenuF = ({ workspace, onOpenRenameTitle, performWorkspaceEdit }: IProps): ReactElement => {
  const navigate = useNavigate();

  const setEnjcWorkspaceVisibility = React.useCallback(
    (nextVisibility: EnjcWorkspaceItemVisibility) => {
      const hEntry = editSetWorkspaceVisibility(workspace, nextVisibility);
      performWorkspaceEdit && performWorkspaceEdit(hEntry);
    },
    [performWorkspaceEdit, workspace],
  );

  const handleDeleteWorkspace = React.useCallback(() => {
    setEnjcWorkspaceVisibility(EnjcWorkspaceItemVisibility.Deleted);
  }, [setEnjcWorkspaceVisibility]);

  return (
    <>
      <Menu>
        <MenuButton
          onClick={(e) => e.stopPropagation()}
          as={IconButton}
          size={'sm'}
          aria-label={'workspace menu'}
          icon={<MdMoreHoriz />}
          backgroundColor="transparent"
        />
        <MenuList
          minW="fit-content"
          border={'1px solid transparent'}
          onClick={(e) => e.stopPropagation()}
          style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 24px 48px' }}
        >
          <MenuItem
            fontSize={'0.875rem'}
            pr={'2rem'}
            _hover={{ bg: '#F3F5FA' }}
            icon={<MdOutlineFileOpen size={17} color="black" />}
            onClick={() => {
              navigate(`/workspace/${workspace.id}`);
            }}
          >
            {'Open workspace'}
          </MenuItem>
          <MenuItem
            _hover={{ bg: '#F3F5FA' }}
            pr={'2rem'}
            fontSize={'0.875rem'}
            icon={<MdOutlineDriveFileRenameOutline size={17} color="black" />}
            onClick={onOpenRenameTitle}
          >
            {'Rename workspace'}
          </MenuItem>
          <MenuItem
            isDisabled
            _hover={{ bg: '#F3F5FA' }}
            pr={'2rem'}
            fontSize={'0.875rem'}
            icon={<CiViewTable size={17} color="black" />}
          >
            {'Vectorisation Table (Pro Version)'}
          </MenuItem>
          {workspace.visibility === EnjcWorkspaceItemVisibility.Visible ? (
            <MenuItem
              _hover={{ bg: '#F3F5FA' }}
              pr={'2rem'}
              fontSize={'0.875rem'}
              icon={<TbArchiveFilled size={17} color="black" />}
              onClick={() => setEnjcWorkspaceVisibility(EnjcWorkspaceItemVisibility.Hidden)}
            >
              {'Archive workspace'}
            </MenuItem>
          ) : (
            <MenuItem
              _hover={{ bg: '#F3F5FA' }}
              pr={'2rem'}
              fontSize={'0.875rem'}
              icon={<TbArchiveOff size={17} color="black" />}
              onClick={() => setEnjcWorkspaceVisibility(EnjcWorkspaceItemVisibility.Visible)}
            >
              {'Un-archive workspace'}
            </MenuItem>
          )}
          <DeleteConfirmationPopover onConfirm={handleDeleteWorkspace}>
            <MenuItem
              icon={<IoCloseSharp size={17} color="red" />}
              _hover={{ bg: '#F3F5FA' }}
              pr={'2rem'}
              fontSize={'0.875rem'}
            >
              {'Delete workspace'}
            </MenuItem>
          </DeleteConfirmationPopover>
        </MenuList>
      </Menu>
    </>
  );
};

export const WorkspaceMenu = React.memo(WorkspaceMenuF);
