import { assertNever } from '../../../utils';
import { EnjcAsciiMathParsedExpression } from '../../enjc-asciimath';
import {
  EnjcAsciiMathCmdBinary,
  EnjcAsciiMathCmdUnary,
  EnjcAsciiMathParsedExpressionDivision,
  EnjcAsciiMathParsedExpressionSequence,
  EnjcAsciiMathParsedExpressionSimple,
  EnjcAsciiMathParsedGroup,
  EnjcAsciiMathParsedNumber,
  EnjcAsciiMathParsedString,
  EnjcAsciiMathParsedSubscriptSuperscript,
} from '../../enjc-asciimath/model';
import {
  EnjcGlyphCmd,
  EnjcGlyphDivision,
  EnjcGlyphGroup,
  EnjcGlyphItem,
  EnjcGlyphPrimitive,
  EnjcGlyphSubSup,
  EnjcGlyphV,
} from '../model';
import { mkGlyphDivision } from './mkGlyphDivision';
import { mkGlyphCmd, mkGlyphV } from './mkGlyphPrimitive';
import { mkGlyphGroup } from './mkGlyphGroup';
import { mkGlyphItem } from './mkGlyphItem';
import { processParsedGlyph } from './processParsedGlyph';

export const glyphFromAsciiMath = (asciiMathParsed: EnjcAsciiMathParsedExpression): ReadonlyArray<EnjcGlyphItem> => {
  const parsedGlyph = glyphFromAsciiMathExpressionSequence(asciiMathParsed);
  const processedGlyph = processParsedGlyph(parsedGlyph);
  return processedGlyph;
};

const glyphFromAsciiMathExpressionSequence = (
  asciiMathParsed: EnjcAsciiMathParsedExpressionSequence,
): ReadonlyArray<EnjcGlyphItem> => {
  const left =
    asciiMathParsed.left.type === 'SubscriptSuperscript'
      ? glyphFromAsciiMathExpressionSubscriptSuperscript(asciiMathParsed.left)
      : glyphFromAsciiMathExpressionDivision(asciiMathParsed.left);
  const right = asciiMathParsed.right && glyphFromAsciiMathExpressionSequence(asciiMathParsed.right);
  return right ? [left, ...right] : [left];
};

const glyphFromAsciiMathExpressionDivision = (
  asciiMathParsed: EnjcAsciiMathParsedExpressionDivision,
): EnjcGlyphDivision => {
  const numerator = glyphFromAsciiMathExpressionSubscriptSuperscript(asciiMathParsed.numerator);
  const denominator = glyphFromAsciiMathExpressionSubscriptSuperscript(asciiMathParsed.denominator);
  return mkGlyphDivision(numerator, denominator);
};

const glyphFromAsciiMathExpressionSubscriptSuperscript = (
  asciiMathParsed: EnjcAsciiMathParsedSubscriptSuperscript,
): EnjcGlyphSubSup => {
  const base = glyphFromEnjcAsciiMathParsedExpressionSimple(asciiMathParsed.base);
  const subscript =
    asciiMathParsed.subscript && glyphFromEnjcAsciiMathParsedExpressionSimple(asciiMathParsed.subscript, true);
  const superscript =
    asciiMathParsed.superscript && glyphFromEnjcAsciiMathParsedExpressionSimple(asciiMathParsed.superscript, true);
  return mkGlyphItem(base, subscript, superscript);
};

const glyphFromEnjcAsciiMathParsedExpressionSimple = (
  asciiMathParsed: EnjcAsciiMathParsedExpressionSimple,
  stripBrackets: boolean = false,
): EnjcGlyphPrimitive => {
  switch (asciiMathParsed.type) {
    case 'NumberFloat':
    case 'NumberInteger':
      return glyphFromAsciiMathNumber(asciiMathParsed);
    case 'StrVarname':
      return glyphFromAsciiMathVarname(asciiMathParsed);
    case 'StrChar':
    case 'StrLine':
      return glyphFromAsciiMathKnownString(asciiMathParsed);
    case 'StrQuoted':
      return glyphFromAsciiMathQuotedString(asciiMathParsed);
    case 'CmdGroup':
      return glyphFromAsciiMathGroup(asciiMathParsed, stripBrackets);
    case 'CmdUnary':
    case 'CmdFont':
      return glyphFromAsciiMathCmdUnary(asciiMathParsed);
    case 'CmdBinary':
      return glyphFromAsciiMathCmdBinary(asciiMathParsed);
    default:
      return assertNever(asciiMathParsed);
  }
};

const glyphFromAsciiMathNumber = (asciiMathParsed: EnjcAsciiMathParsedNumber): EnjcGlyphV => {
  return mkGlyphV(asciiMathParsed.value);
};

const glyphFromAsciiMathKnownString = (asciiMathParsed: EnjcAsciiMathParsedString): EnjcGlyphV => {
  return mkGlyphV(asciiMathParsed.value, asciiMathParsed.value);
};

const glyphFromAsciiMathQuotedString = (asciiMathParsed: EnjcAsciiMathParsedString): EnjcGlyphV => {
  const glyphValue = asciiMathParsed.value.substring(1, asciiMathParsed.value.length - 1).replace(/(?:\\(.))/g, '$1');
  return mkGlyphV(glyphValue);
};

const glyphFromAsciiMathVarname = (asciiMathParsed: EnjcAsciiMathParsedString): EnjcGlyphV => {
  return mkGlyphV(asciiMathParsed.value);
};

const glyphFromAsciiMathGroup = (asciiMathParsed: EnjcAsciiMathParsedGroup, stripBrackets: boolean): EnjcGlyphGroup => {
  const expression = glyphFromAsciiMathExpressionSequence(asciiMathParsed.expression);
  return stripBrackets
    ? mkGlyphGroup(expression, '', '')
    : mkGlyphGroup(expression, asciiMathParsed.lBracket, asciiMathParsed.rBracket);
};

const glyphFromAsciiMathCmdUnary = (asciiMathParsed: EnjcAsciiMathCmdUnary): EnjcGlyphCmd => {
  return mkGlyphCmd(
    asciiMathParsed.cmd,
    [asciiMathParsed.argument].map((arg) => glyphFromEnjcAsciiMathParsedExpressionSimple(arg, true)),
  );
};

const glyphFromAsciiMathCmdBinary = (asciiMathParsed: EnjcAsciiMathCmdBinary): EnjcGlyphCmd => {
  return mkGlyphCmd(
    asciiMathParsed.cmd,
    [asciiMathParsed.argument1, asciiMathParsed.argument2].map((arg) =>
      glyphFromEnjcAsciiMathParsedExpressionSimple(arg, true),
    ),
  );
};
