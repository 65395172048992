import { EnjcWorkspaceUpdateInput } from '../../enjicalc-graphql';

export interface EnjcWorkspaceUpdateInputUnpatched extends Omit<EnjcWorkspaceUpdateInput, 'timestamp'> {
  timestamp: number;
}

export const tmpPatchEnjcWorkspaceUpdateInput = (
  unpatchedInput: EnjcWorkspaceUpdateInputUnpatched,
): EnjcWorkspaceUpdateInput => {
  return {
    ...unpatchedInput,
    timestamp: unpatchedInput.timestamp.toString(),
  };
};
