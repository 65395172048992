import React, { ReactElement } from 'react';
import { IconButton, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { MdClear } from 'react-icons/md';

interface IProps {
  readonly searchTerm: string;
  readonly onSearchTermChange: (text: string) => void;
}

const SearchInputF = ({ searchTerm, onSearchTermChange }: IProps): ReactElement => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.600" />} />
      <Input
        value={searchTerm}
        placeholder={'Search...'}
        focusBorderColor={'primary.100'}
        _placeholder={{ opacity: 1, color: 'gray', fontFamily: 'Plus Jakarta Sans', fontSize: '15px' }}
        onChange={(e) => onSearchTermChange(e.target.value)}
      />
      ;
      {searchTerm.length > 0 && (
        <InputRightElement>
          <IconButton
            size={'sm'}
            aria-label={'clear input'}
            icon={<MdClear />}
            onClick={() => onSearchTermChange('')}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export const SearchInput = React.memo(SearchInputF);
