import { IValueTreeViewContext, getCursorPositionNode } from '../../../enjc-value-view-ctx';
import { ValueTreeClientDelta, processLiteralDraft } from '../../tree-editing';
import { isNodeOperator } from '../../../enjc-value-operators';
import { isTreePositionNode } from '../../tree-position';
import { spliceNodeDraftNVTV } from '../../tree-navigation';
import { isTreeNodeFunction, isTreeNodeLiteral } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { ValueTreeNodeMode } from '../../../../generated/graphql/types';
import { mkTreeCursorNodeDraft } from '../../tree-cursor';
import { getValueTreePositionAtStartEnd } from '../../tree-position/utils/getValueTreePositionAtStartEnd';

export const handleTreeKeyDraftChar = (vtvCtx: IValueTreeViewContext, navKey: string): ValueTreeClientDelta => {
  const { cursorPosition, positionNode } = getCursorPositionNode(vtvCtx)!;

  // No action if cursor is at operator
  if (isNodeOperator(positionNode)) {
    return {};
  }

  const insertPosition = isTreePositionNode(cursorPosition)
    ? cursorPosition.atEnd
      ? positionNode.draft.length
      : 0
    : cursorPosition.at;
  const positionStartEnd = getValueTreePositionAtStartEnd(positionNode, cursorPosition);

  if (positionStartEnd) {
    if (isTreePositionNode(cursorPosition) && !isTreeNodeLiteral(positionNode)) {
      const updatedPositionNode: EnjcValueTreeNodeDelta = isTreeNodeFunction(positionNode)
        ? {
            key: positionNode.key,
            draft: [{ slicePosition: undefined, sliceBefore: positionNode.draft || undefined, sliceAfter: navKey }],
            function: { atomBefore: positionNode.function?.id, atomAfter: 'fg.stub' },
          }
        : {
            key: positionNode.key,
            mode: { atomBefore: positionNode.mode, atomAfter: ValueTreeNodeMode.Literal },
            draft: [{ slicePosition: undefined, sliceBefore: positionNode.draft || undefined, sliceAfter: navKey }],
            literal: { atomBefore: positionNode.literal, atomAfter: processLiteralDraft(navKey) },
          };

      return {
        delta: { nodes: [updatedPositionNode] },
        cursor: mkTreeCursorNodeDraft(updatedPositionNode.key, 1),
      };
    }
  }

  return spliceNodeDraftNVTV(vtvCtx, positionNode, insertPosition, 0, navKey);
};
