import { getNodeKey, isTreeNodeFunction, TValueTreeNodeOrKey } from '../../tree-node';
import { isNodeOperator } from '../../../enjc-value-operators';
import { ValueTreeNodePosition } from '../model';
import { mkTreePositionNode } from './mkTreePositionNode';
import { IValueTreeViewContext, resolveTreeNodeNVTV } from '../../../enjc-value-view-ctx';

export const findNodePositionNVTV = (
  vtvCtx: IValueTreeViewContext,
  nodeOrKey: TValueTreeNodeOrKey,
  searchStart: boolean,
): ValueTreeNodePosition | undefined => {
  const positionNode = resolveTreeNodeNVTV(vtvCtx, nodeOrKey);
  if (!positionNode) {
    // TODO: review and report error
    return undefined;
  }
  if (isTreeNodeFunction(positionNode) && isNodeOperator(positionNode) && positionNode.arguments.length > 0) {
    return findNodePositionNVTV(
      vtvCtx,
      positionNode.arguments[searchStart ? 0 : positionNode.arguments.length - 1].key,
      searchStart,
    );
  } else {
    return mkTreePositionNode(getNodeKey(nodeOrKey), !searchStart);
  }
};
