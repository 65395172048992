import { TValueFunctionId } from '../../../enjc-value-math/model/TValueFunctionId';
import { mkLiteralVoidNull } from '../../../enjc-literal';
import { ValueTreeNodeMode } from '../../../enjicalc-graphql';
import { mkValueFunctionRef } from '../../../enjc-workspace';
import { TValueTreeNodeKey } from '../types';
import { ValueTreeNode } from '../model';
import { mkValueTreeNodeRef } from './mkValueTreeNodeRef';

export const mkTreeFunction = (
  key: string,
  draft: string,
  functionId: TValueFunctionId | undefined,
  args: ReadonlyArray<TValueTreeNodeKey>,
): ValueTreeNode => ({
  __typename: 'ValueTreeNode',
  key,
  tags: [],
  draft,
  mode: ValueTreeNodeMode.Function,
  literal: mkLiteralVoidNull(),
  function: mkValueFunctionRef(functionId),
  arguments: args.map((k) => mkValueTreeNodeRef(k)),
  result: mkLiteralVoidNull(),
});
