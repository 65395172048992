import React, { ReactElement } from 'react';
import { MathMathMLAttributes } from '../../../libenjc/enjc-react-mathml/attributes';
import { EnjcGlyphMathmlAdapterView } from '../../../libenjc/enjc-react/enjc-react-components/enjc-glyph-mathml-view';
import { defaultMathBlockProps } from '../../../config/mathml-props';

interface IProps {
  // TODO: review type
  readonly unit: string;
  readonly mathProps?: MathMathMLAttributes;
  readonly optional?: boolean;
}

const EnjcUnitMathBlockViewF = ({ unit, mathProps, optional }: IProps): ReactElement => {
  return (
    <math {...defaultMathBlockProps} {...mathProps}>
      <EnjcGlyphMathmlAdapterView glyphAsciiMathStr={unit} optional={optional} />
    </math>
  );
};

export const EnjcUnitMathBlockView = React.memo(EnjcUnitMathBlockViewF);
