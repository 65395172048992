import { EnjcStringDeltaEntry } from '../model';
import { calculateStringDeltaEntry } from './calculateStringDeltaEntry';

export const calculateStringDelta = (
  strBefore: string,
  strAfter: string,
): ReadonlyArray<EnjcStringDeltaEntry> | undefined => {
  // TODO: implement optimisations to reduce size of delta
  const deltaEntry = calculateStringDeltaEntry(strBefore, strAfter);
  return deltaEntry && [deltaEntry];
};
