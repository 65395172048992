import { ValueTreeFunctionInvalidArgumentAtError } from '../../tree-errors';
import { ValueTreeNode } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';

export const insertFunctionArgumentNVTV = (
  treeFunction: ValueTreeNode,
  at: number,
  argumentNode: ValueTreeNode,
): EnjcValueTreeNodeDelta => {
  if (at < 0 || at > treeFunction.arguments.length) {
    throw new ValueTreeFunctionInvalidArgumentAtError(at, treeFunction.key, treeFunction.arguments.length);
  }

  return {
    key: treeFunction.key,
    arguments: [{ slicePosition: at, sliceAfter: [argumentNode.key] }],
  };
};
