import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { TSectionId } from '../../generated/graphql/types';

const SEARCH_PARAMETER_KEY_OPEN = 'open';
const SEARCH_PARAMETER_KEY_ACTIVE = 'active';

export const useSearchParamsSheetDocumentTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedSheetId = React.useMemo(() => searchParams.get(SEARCH_PARAMETER_KEY_ACTIVE) || '', [searchParams]);
  const openSheetsIds = React.useMemo(() => searchParams.getAll(SEARCH_PARAMETER_KEY_OPEN), [searchParams]);

  const setSheetTabsState = React.useCallback(
    (nexTabSheetIds: ReadonlyArray<TSectionId>, nextSelectedSheetId: TSectionId | undefined) => {
      if (nextSelectedSheetId && nexTabSheetIds.indexOf(nextSelectedSheetId) < 0) {
        // TODO: report error
        return;
      }

      const sheetTabsStateParams = [
        ...nexTabSheetIds.map((tabSheetId) => [SEARCH_PARAMETER_KEY_OPEN, tabSheetId]),
        ...(nextSelectedSheetId ? [[SEARCH_PARAMETER_KEY_ACTIVE, nextSelectedSheetId]] : []),
      ];

      setSearchParams(
        (prev) =>
          new URLSearchParams([
            ...[...prev.entries()].filter(
              (v) => v[0] !== SEARCH_PARAMETER_KEY_OPEN && v[0] !== SEARCH_PARAMETER_KEY_ACTIVE,
            ),
            ...sheetTabsStateParams,
          ]),
      );
    },
    [setSearchParams],
  );

  return {
    selectedSheetId,
    openSheetsIds,
    setSheetTabsState,
  };
};
