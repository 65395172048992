export const generateValueTreeNodeKey = (treeNodeKeys: Iterable<string>): string => {
  const keysSet = new Set(treeNodeKeys);

  let keyCapacity = 0xff - 0x10;
  while (keysSet.size >= keyCapacity) {
    keyCapacity *= 0xff;
  }

  while (true) {
    const keyNum = 0x10 + Math.round(Math.random() * keyCapacity);
    const key = keyNum.toString(16);
    if (!keysSet.has(key)) {
      return key;
    }
  }
};
