import React, { MouseEvent as ReactMouseEvent, ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

interface IProps {
  readonly onResizeStart?: () => void;
  readonly onResizeUpdate?: (resizeDelta: number) => void;
  readonly onResizeEnd?: (resizeDelta: number) => void;
}

const ResizeDividerF = ({ onResizeStart, onResizeUpdate, onResizeEnd }: IProps): ReactElement => {
  // const [resizeDelta, setResizeDelta] = React.useState<number>(0);
  const [mouseDownPos, setMouseDownPos] = React.useState<number | undefined>();

  const handleReactMouseDown = React.useCallback(
    (e: ReactMouseEvent) => {
      setMouseDownPos(e.clientX);
      onResizeStart && onResizeStart();
    },
    [onResizeStart],
  );

  const handleMouseMove = React.useCallback(
    (e: MouseEvent) => {
      if (mouseDownPos !== undefined) {
        const resizeDelta = e.clientX - mouseDownPos;
        onResizeUpdate && onResizeUpdate(resizeDelta);
      }
    },
    [mouseDownPos, onResizeUpdate],
  );

  const handleMouseUp = React.useCallback(
    (e: MouseEvent) => {
      if (mouseDownPos !== undefined) {
        const resizeDelta = e.clientX - mouseDownPos;
        setMouseDownPos(undefined);
        onResizeEnd && onResizeEnd(resizeDelta);
      }
    },
    [mouseDownPos, onResizeEnd],
  );

  React.useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    // window.addEventListener('touchmove', handleMouseMove);
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      // window.removeEventListener('touchmove', handleMouseMove);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <Box
      backgroundColor={'transparent'}
      borderRight={'1px solid transparent'}
      borderLeft={'7px solid transparent'}
      marginLeft={'-7px'}
      cursor="col-resize"
      onMouseDown={handleReactMouseDown}
    />
  );
};

export const ResizeDivider = React.memo(ResizeDividerF);
