import { EnjcGlyphSubSup, EnjcGlyphPrimitive } from '../model';

export const mkGlyphItem = (
  base: EnjcGlyphPrimitive,
  subscript: EnjcGlyphPrimitive | undefined,
  superscript: EnjcGlyphPrimitive | undefined,
): EnjcGlyphSubSup => {
  return {
    __typename: 'EnjcGlyphSubSup',
    base,
    subscript,
    superscript,
  };
};
