import { ValueTreeNode } from '../../tree-node';
import { EnjcValueTreeNodeDelta } from '../../../enjc-workspace-editing/model';
import { calculateNodeDelta } from '../../tree-navigation/actions';
import { ValueTreeNodeMode } from '../../../../generated/graphql/types';
import { mkLiteralVoidNull } from '../../../enjc-literal';

export const mkNodeRemovalDelta = (node: ValueTreeNode): EnjcValueTreeNodeDelta => {
  return {
    ...calculateNodeDelta(node, {
      __typename: 'ValueTreeNode',
      key: node.key,
      draft: '',
      mode: ValueTreeNodeMode.Literal,
      literal: mkLiteralVoidNull(),
      arguments: [],
      function: undefined,
      result: mkLiteralVoidNull(),
      symbol: undefined,
      tags: [],
    }),
    removeAdd: 'remove',
  };
};
