import { TSymbolId, EnjicalcSymbol } from '../../enjc-workspace';
import { IValueTreeViewContext } from '../model';
import { getValueSymbolOrUndefinedNVTV } from './getValueSymbolOrUndefinedNVTV';

export const getValueSymbolNVTV = (vtvCtx: IValueTreeViewContext, symbolId: TSymbolId | undefined): EnjicalcSymbol => {
  const result = getValueSymbolOrUndefinedNVTV(vtvCtx, symbolId);
  if (result === undefined) {
    throw Error(`Symbol with id ${symbolId} not found`);
  }
  return result;
};
