import { IValueTreeViewContext } from '../../../enjc-value-view-ctx';
import { ValueTreeNode } from '../../tree-node';
import { TSymbolId, ValueTreeNodeMode } from '../../../../generated/graphql/types';
import { mkTreeCursorNode } from '../../tree-cursor';
import { ValueTreeClientDelta } from '../model';

export const changeTreeNodeSymbol = (
  vtvCtx: IValueTreeViewContext,
  node: ValueTreeNode,
  symbolId: TSymbolId,
): ValueTreeClientDelta => {
  return {
    delta: {
      nodes: [
        {
          key: node.key,
          symbol: { atomBefore: node.symbol?.id, atomAfter: symbolId },
          mode: { atomBefore: node.mode, atomAfter: ValueTreeNodeMode.Symbol },
        },
      ],
    },
    // TODO: remove this when combines symbol / draft editor is ready
    cursor: mkTreeCursorNode(node, true),
  };
};
