import React, { ReactElement } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { Popover, PopoverTrigger, PopoverContent } from 'src/shadcn';
import { useValueTreeViewContext } from '../../../enjc-react-ui';
import { UValueHintItem } from '../../../../../libenjc/enjc-value-view-ctx/model/IValueTreeViewContext';
import { getValueHintEntries } from '../../../../../libenjc/enjc-value-view-ctx';
import { useCtxEnjicalcSheet } from '../../../../../libenjc/enjc-react/enjc-react-context';
import { SymbolValueHintsView } from './SymbolValueHintsView';

interface Props {
  readonly onHintClick?: (hint: UValueHintItem) => void;
  readonly qfixHideCursor?: boolean;
}

export const SymbolValuePlainCursor = ({ onHintClick, qfixHideCursor }: Props): ReactElement => {
  const vtvCtx = useValueTreeViewContext();
  const { symbol, hasFocus, showValueHints } = vtvCtx;
  const symbolSheet = useCtxEnjicalcSheet().sheet;
  const valueHints = getValueHintEntries(vtvCtx, symbol, symbolSheet);

  // TODO: fix <div> and <section> elements inside <p> elements
  // TODO: check if popover is correct component for the value hints

  return (
    <Popover open={showValueHints && valueHints.length > 0}>
      <PopoverTrigger asChild>
        <Box
          display={hasFocus ? 'inline' : 'none'}
          style={{
            width: '1px',
            height: '30px',
            backgroundColor: !qfixHideCursor ? 'black' : undefined,
            position: 'absolute',
            top: -5,
            animation: 'blink 1s step-end infinite',
          }}
        />
      </PopoverTrigger>
      <PopoverContent style={{ zIndex: 9999, padding: 0, width: '300px', maxWidth: '300px' }}>
        <Heading size="sm" borderBottomWidth={1} p={2} bg="gray.100">
          {'Hints'}
        </Heading>
        <Box maxH={160} overflow="auto">
          <SymbolValueHintsView onHintClick={onHintClick} />
        </Box>
      </PopoverContent>
    </Popover>
  );
};
