import React, { ReactElement } from 'react';
import { Box, Button, HStack, Link, Show, Stack, Text } from '@chakra-ui/react';

const AppWelcomeF = (): ReactElement => {
  return (
    <Stack
      bgColor="#F3F5FA"
      justifyContent="space-between"
      w="100%"
      h="calc(100vh - 35px)"
      pl="175px"
      pr="50px"
      spacing="0"
    >
      <Show breakpoint="(min-width: 700px)">
        <Box>
          <Text pt="100px" color="black" fontSize="l">
            Dear user,
          </Text>
          <Text pt="30px" w="600px" color="black" fontSize="l">
            Welcome to our closed beta tests of Enjicalc. We would appreciate your support and feedback on our product.
          </Text>
          <Text pt="30px" w="600px" color="black" fontSize="l">
            By visiting our Canny Feedback platform and actively participating in discussions, voting on feature
            suggestions, or sharing your thoughts, you are playing a crucial role in shaping the future of our product.
            Your input is not just valuable, it's essential.
          </Text>
          <Text pt="30px" w="600px" color="black" fontSize="l">
            Let's make Enjicalc better together.
          </Text>
          <Text pt="30px" w="600px" color="black" fontSize="l">
            Best regards,
          </Text>
          <Text w="600px" color="black" fontSize="l">
            Team Enjicalc
          </Text>
          <HStack gap="30px" p="50px 0px">
            <Link href="https://enjicalc.canny.io/feedback" isExternal>
              <Button w={300} h={50} style={{ fontSize: '15px' }} variant="outline" borderColor="black">
                Submit Feedback
              </Button>
            </Link>
            <Link href="https://docs.enji.io/documentation" isExternal>
              <Button w={300} h={50} style={{ fontSize: '15px' }} variant="outline" borderColor="black">
                Documentation
              </Button>
            </Link>
          </HStack>
        </Box>
      </Show>
    </Stack>
  );
};

export const AppWelcome = React.memo(AppWelcomeF);
