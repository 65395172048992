import { assertNever } from '../../../utils';
import { EnjicalcWorkspace } from '../../enjc-workspace';
import { ValueTreeNodeInput, ValueTreeNodeMode } from '../../enjicalc-graphql';
import { USheetUpdateNode } from './parseSheetUpdateJson';

export const convertUpdateImportTreeNode = (
  workspace: EnjicalcWorkspace,
  node: USheetUpdateNode,
): ValueTreeNodeInput => {
  // Handle 'pi' symbol (convert to function)
  if ('symbol' in node && node.symbol === 'pi') {
    return {
      key: node.key,
      draft: [{ value: 'pi' }],
      function: 'fg.pi',
      mode: ValueTreeNodeMode.Function,
      arguments: [],
    };
  }

  if ('literal' in node) {
    return {
      key: node.key,
      draft: [{ value: `${node.literal}` }],
      literal: { numb: node.literal },
      mode: ValueTreeNodeMode.Literal,
    };
  }
  if ('symbol' in node) {
    return {
      key: node.key,
      draft: [{ value: `${node.symbol}` }],
      symbol: workspace.symbols.find((sy) => sy.originalId === 'lcr_' + node.symbol)?.id ?? 'lcr_' + node.symbol,
      mode: ValueTreeNodeMode.Symbol,
    };
  }
  if ('function' in node) {
    return {
      key: node.key,
      draft: [{ value: `${node.function}` }],
      function: node.function,
      mode: ValueTreeNodeMode.Function,
      arguments: [{ value: node.arguments }],
    };
  }
  return assertNever(node);
};
