import React, { ReactElement, ReactNode } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useAuth } from '@clerk/clerk-react';
import fragmentMatcher from '../generated/graphql/fragment-matcher';
import typePolicies from './type-policies';
import { setContext } from '@apollo/client/link/context';
import { splitLink } from '../config/apollo';
import { isNodeEnvDevelopment } from '../utils';

interface IProps {
  children?: ReactNode;
}

export const EnjcApolloProvider = ({ children }: IProps): ReactElement => {
  const { getToken } = useAuth();

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const apiToken = await getToken({ template: 'enjc-webapp-v1' });

      return {
        headers: apiToken
          ? {
              ...headers,
              authorization: `Bearer ${apiToken}`,
            }
          : headers,
      };
    });

    return new ApolloClient({
      // connectToDevTools: true,
      connectToDevTools: isNodeEnvDevelopment(),
      name: 'enjicalc-webapp',
      // version: '1.0.0',
      link: authLink.concat(splitLink),
      // link: from([authMiddleware, httpLink]),
      cache: new InMemoryCache({
        possibleTypes: fragmentMatcher.possibleTypes,
        typePolicies,
      }),
      assumeImmutableResults: true,
    });
  }, [getToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
