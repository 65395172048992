import React, { ReactElement } from 'react';
import { EnjcGlyphGroup } from '../../../enjc-glyph/model';
import { MathMathMLAttributes } from '../../../enjc-react-mathml/attributes';
import { EnjcGlyphItemMathmlView } from './EnjcGlyphItemMathmlView';

interface IProps extends MathMathMLAttributes {
  readonly glyphGroup: EnjcGlyphGroup;
}

export const EnjcGlyphGroupMathmlView = ({ glyphGroup, ...mathProps }: IProps): ReactElement => {
  return (
    <mrow {...mathProps}>
      {glyphGroup.lb && <mo fence={'true'}>{glyphGroup.lb}</mo>}
      {glyphGroup.items.map((groupItem, index, arr) => (
        <EnjcGlyphItemMathmlView key={index} enjcGlyphItem={groupItem} />
      ))}
      {glyphGroup.rb && <mo fence={'true'}>{glyphGroup.rb}</mo>}
    </mrow>
  );
};
