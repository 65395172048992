'use client';

import * as React from 'react';

import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from 'src/shadcn';
import { Box, HStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStringColor } from '../../utils/getStringColor';
import { EnjicalcWorkspaceMeta } from '../../libenjc/enjc-workspace/model';

interface IProps {
  readonly workspaces: ReadonlyArray<EnjicalcWorkspaceMeta>;
}

export const SearchWorkspaces = ({ workspaces }: IProps): ReactElement => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpenSearch = React.useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        handleOpenSearch();
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [handleOpenSearch]);

  return (
    <HStack gap={'28px'}>
      <HStack
        cursor="pointer"
        justify="space-between"
        bg="white"
        w={256}
        py={'6px'}
        borderRadius={6}
        borderWidth={1}
        borderColor="#CBD5E1"
        px={'12px'}
        onClick={handleOpenSearch}
      >
        <p className="text-sm text-muted-foreground">Search</p>
        <p className="text-sm text-muted-foreground">
          <span className="text-xs">⌘</span>K
        </p>
      </HStack>

      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Search workspaces ..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          {workspaces.map((workspace) => {
            const workspaceColor = getStringColor(workspace.id);

            return (
              <CommandGroup key={workspace.id}>
                <CommandItem
                  onSelect={() => navigate(`/workspace/${workspace.id}`)}
                  className="hover:bg-gray-200 cursor-pointer"
                >
                  <Box w="18px" h="18px" bg={workspaceColor} marginRight={2} />
                  <span>{workspace.title || 'Untitled'}</span>
                </CommandItem>
              </CommandGroup>
            );
          })}
        </CommandList>
      </CommandDialog>
    </HStack>
  );
};
