import { EnjcWorkspaceItemVisibility, EnjicalcSection } from '../../enjc-workspace';
import { mkAtomDelta } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta } from '../model';

export const changeSetSectionVisibility = (
  existingSection: EnjicalcSection,
  visibility: EnjcWorkspaceItemVisibility,
): EnjcWorkspaceSectionDelta | undefined => {
  return existingSection.visibility === visibility
    ? undefined
    : {
        id: existingSection.id,
        visibility: mkAtomDelta(existingSection.visibility, visibility),
      };
};
