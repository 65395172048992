import { ValueTreeNode } from '../../enjc-symbol-value-tree';
import { isTreePositionNode } from '../../enjc-symbol-value-tree/tree-position';
import { TCursorNode } from '../../enjc-symbol-value-tree/tree-cursor';
import { IValueTreeViewContext } from '../model';
import { getCursorPositionNode } from '../utils-nvtv';

export const getSymbolValueCursorAsNode = (vtvCtx: IValueTreeViewContext, node: ValueTreeNode): TCursorNode => {
  const cursorPositionNode = getCursorPositionNode(vtvCtx);
  return (
    (cursorPositionNode &&
      cursorPositionNode.positionNode.key === node.key &&
      (vtvCtx.hasFocus
        ? isTreePositionNode(cursorPositionNode.cursorPosition) &&
          (cursorPositionNode.cursorPosition.atEnd ? 'end' : 'start')
        : 'shadow')) ||
    undefined
  );
};
