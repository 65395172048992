import React, { ChangeEvent, ReactNode } from 'react';
import { CloseButton, Flex, Input, Text } from '@chakra-ui/react';

interface IProps {
  initialValue: string;
  firstFieldRef: any;
  onCancel: () => void;
  onSave: (v: string) => void;
  isHidden: boolean;
  validator: (value: string) => string;
  inputWidth: string;
  children?: (value: string) => ReactNode;
}

export const ValueInput = ({
  initialValue,
  firstFieldRef,
  onCancel,
  onSave,
  isHidden,
  validator,
  inputWidth,
  children,
}: IProps) => {
  const [value, setValue] = React.useState(initialValue);
  const [validationErrorMsg, setValidationErrorMsg] = React.useState('');

  const [hidden, setHidden] = React.useState(isHidden);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setValidationErrorMsg(validator(event.target.value));
  };

  React.useEffect(() => {
    setHidden(isHidden);
    if (!hidden) {
      setValue(initialValue);
      setValidationErrorMsg('');
    }
  }, [isHidden, hidden, initialValue]);

  const handleKeyDown = (event: { key: string }) => {
    if (!validationErrorMsg && event.key === 'Enter') {
      onSave(value);
    }
  };

  return (
    <Flex pos="relative">
      <Text
        pos="absolute"
        zIndex="5"
        left="22px"
        w="fit-content"
        h="fit-content"
        p="3px 7px 0px 7px"
        color="#999"
        fontSize="9px"
        bg="#fff"
      >
        {validationErrorMsg || 'Press ↵ to save'}
      </Text>
      {children && children(value)}
      <Input
        ref={firstFieldRef}
        w={inputWidth}
        my="10px"
        ml="10px"
        fontFamily="monospace"
        fontSize="20px"
        focusBorderColor={!validationErrorMsg ? '#000' : '#F00'}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        size="md"
        value={value}
      />
      <CloseButton mx="5px" mt="14px" color="black" onClick={onCancel} size="md" />
    </Flex>
  );
};
