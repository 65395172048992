import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from 'src/shadcn';
import FocusLock from 'react-focus-lock';
import { ValueInput } from './ValueInput';

interface IProps {
  draft: string;
  onSave: (v: string) => void;
  children: React.ReactNode;
}

const isNumber = (t: string | number): boolean => {
  return t != null && t !== '' && !isNaN(Number(t.toString()));
};

export const NumberDraftPopover = ({ draft, onSave, children }: IProps): React.ReactElement => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const firstFieldRef = React.useRef(null);

  const [isHidden, setIsHidden] = React.useState(true);

  const handleToggle = React.useCallback(() => {
    setIsPopoverOpen(!isPopoverOpen);
    setIsHidden(!isHidden);
  }, [isPopoverOpen, isHidden]);

  const handleInputSave = React.useCallback(
    (v: string) => {
      onSave(v);
      handleToggle();
    },
    [handleToggle, onSave],
  );

  return (
    <Popover open={isPopoverOpen} onOpenChange={handleToggle}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent side="bottom" align="start" className="p-0">
        <FocusLock returnFocus persistentFocus={false} className="rounded-lg">
          <ValueInput
            initialValue={draft}
            firstFieldRef={firstFieldRef}
            onCancel={handleToggle}
            onSave={handleInputSave}
            isHidden={isHidden}
            validator={(v) => (isNumber(v) ? '' : 'Enter a valid number')}
            inputWidth="250px"
          />
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
