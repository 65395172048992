import { TValueTreeNodeOrKey } from '../../enjc-symbol-value-tree/tree-node';
import { IValueTreeViewContext } from '../model';
import { getNodeByKeyNVTV } from './getNodeByKeyNVTV';

export const resolveTreeNodeNVTV = (vtvCtx: IValueTreeViewContext, nodeOrKey: TValueTreeNodeOrKey) => {
  if (typeof nodeOrKey === 'object') {
    return nodeOrKey;
  }

  return getNodeByKeyNVTV(vtvCtx, nodeOrKey);
};
