import React, { ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { TextPopover } from '../../misc';

interface IProps {
  name: string;
  text: string;
  onSubmit?: (v: string) => void;
}

export const SheetHeaderFieldPopoverF = ({ name, text, onSubmit }: IProps): ReactElement => {
  const styleProps = {
    background: '#f4f8fa',
    cursor: 'pointer',
    w: '100%',
  };

  return (
    <TextPopover initialValue={text} onSave={onSubmit}>
      <Box className="sheetHeader" w="100%" display="flex" h={'auto'}>
        <Box as="span" minW="fit-content">{`${name}:`}</Box>
        <Text as="span" w="auto" h="auto" pl="5px" color="black" _hover={onSubmit ? { ...styleProps } : undefined}>
          {text || 'Untitled'}
        </Text>
      </Box>
    </TextPopover>
  );
};

export const SheetHeaderFieldPopover = React.memo(SheetHeaderFieldPopoverF);
