import { INodeParentInfoWithOperator } from '../../symbol-value-tree-review';

export const isExpressionOperandLeft = (parentInfo: INodeParentInfoWithOperator): boolean => {
  switch (parentInfo.index) {
    case 0:
      return true;
    case 1:
      return false;
    default:
      throw new Error(`Invalid operand index: ${parentInfo.index}`);
  }
};
