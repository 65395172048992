import { EnjcKeyListDelta } from '../model';

export const invertKeyListDelta = (delta: EnjcKeyListDelta): EnjcKeyListDelta => {
  return [...delta].reverse().map((deltaEntry) => {
    return {
      slicePosition: deltaEntry.slicePosition,
      sliceBefore: deltaEntry.sliceAfter,
      sliceAfter: deltaEntry.sliceBefore,
    };
  });
};
