import React, { ReactElement } from 'react';
import { EnjcGlyphDivision } from '../../../enjc-glyph/model';
import { MathMathMLAttributes } from '../../../enjc-react-mathml/attributes';
import { EnjcGlyphSubSupMathmlView } from './EnjcGlyphSubSupMathmlView';

interface IProps extends MathMathMLAttributes {
  readonly glyphDivision: EnjcGlyphDivision;
}

export const EnjcGlyphDivisionMathmlView = ({ glyphDivision, ...mathProps }: IProps): ReactElement => {
  return (
    <mfrac {...mathProps}>
      <EnjcGlyphSubSupMathmlView glyphSubSup={glyphDivision.numerator} />
      <EnjcGlyphSubSupMathmlView glyphSubSup={glyphDivision.denominator} />
    </mfrac>
  );
};
