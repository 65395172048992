import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { EnjcSectionFragment, TSectionId } from '../../libenjc/enjicalc-graphql';
import { EnjcItemsTabs } from '../../components/enjc-items/enjc-items-tabs';
import { WorkspaceSheetView } from './WorkspaceSheetView';

type TSheet = EnjcSectionFragment;

interface IProps {
  readonly openSheets: ReadonlyArray<TSheet>;
  readonly activeSheet?: TSheet;
  readonly onSheetSelect: (sheetSectionId: TSectionId | undefined) => void;
  readonly onSheetClose: (sheetSectionId: TSectionId) => void;
}

export const WorkspaceSheetsTabs = ({ openSheets, activeSheet, onSheetSelect, onSheetClose }: IProps): ReactElement => {
  return (
    <Box flexGrow={'1'} minW={'950px'}>
      <EnjcItemsTabs
        items={openSheets}
        selectedItemId={activeSheet?.id || ''}
        onItemSelect={onSheetSelect}
        onItemClose={onSheetClose}
        itemView={WorkspaceSheetView}
      />
    </Box>
  );
};
