import React, { ChangeEvent } from 'react';
import { Flex, Textarea } from '@chakra-ui/react';
import { EnjcStringDeltaEntry, calculateStringDeltaEntry } from '../../../libenjc/enjc-delta';

interface IProps {
  readonly value: string;
  readonly onValueDelta: (delta: EnjcStringDeltaEntry) => void;
}

export const SymbolTextAreaDeltaInput = ({ value, onValueDelta }: IProps) => {
  const handleChange = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const enjcStringDelta = calculateStringDeltaEntry(value, event.target.value);
      enjcStringDelta && onValueDelta(enjcStringDelta);
    },
    [value, onValueDelta],
  );

  return (
    <Flex>
      <Textarea w="700px" fontFamily="jakarta-sans" fontSize="16px" onChange={handleChange} size="md" value={value} />
    </Flex>
  );
};
