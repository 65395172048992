import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Flex, Heading, HStack, VStack, Box } from '@chakra-ui/react';
import { UserButton } from '@clerk/clerk-react';
import { useWorkspacesListContext } from '../../libenjc/enjc-react/enjc-react-context';
import { WorkspacesListView } from '../../components/views/workspaces-list-view';
import { EnjcWorkspaceItemVisibility } from '../../generated/graphql/types';
import { useEnjcRawWorkspaceMutations } from '../../libenjc/enjc-react/enjc-react-client';
import { SearchWorkspaces } from '../../components/search-workspaces/SearchWorkspaces';
import { EnjicalcWorkspaceUpdateResult, WorkspaceEditHistoryEntry } from '../../libenjc/enjc-workspace-editing';
import { EnjicalcWorkspaceMeta } from '../../libenjc/enjc-workspace';
import { convertWorkspaceEditHistoryEntryToDiffInput } from '../../libenjc/enjc-client';
import { Switch } from '../../shadcn/components/Switch';
import { Label } from '../../shadcn';
import { useLocalStorage } from 'usehooks-ts';
import { QUICK_START_TOUR_STEP_NAMES, QUICK_START_TOUR_STEP_COUNT, useQuickStartTour } from 'src/hooks';

const AppDashboardF = (): ReactElement => {
  const { setCurrentStep } = useQuickStartTour();
  const [showHidden, setShowHidden] = useLocalStorage('archivedWorkspaces', false);

  const navigate = useNavigate();
  const { createWorkspace, updateWorkspace, forkWorkspace } = useEnjcRawWorkspaceMutations();
  const { workspaces, refetch } = useWorkspacesListContext();

  const performWorkspaceEdit = React.useCallback(
    (
      workspace: EnjicalcWorkspaceMeta,
      editEntry: WorkspaceEditHistoryEntry,
    ): Promise<EnjicalcWorkspaceUpdateResult> => {
      return updateWorkspace(workspace.id, -1, { items: [convertWorkspaceEditHistoryEntryToDiffInput(editEntry)] });
    },
    [updateWorkspace],
  );

  // FIXME
  // const clonedWorkspaces = React.useMemo(
  //   () =>
  //     workspaces.filter((w) => w.flagCloned && (showHidden || w.visibility === EnjcWorkspaceItemVisibility.Visible)),
  //   [showHidden, workspaces],
  // );

  // const filteredWorkspaces = React.useMemo(
  //   () =>
  //     workspaces.filter((w) => !w.flagCloned && (showHidden || w.visibility === EnjcWorkspaceItemVisibility.Visible)),
  //   [showHidden, workspaces],
  // );
  const filteredWorkspaces = React.useMemo(
    () =>
      workspaces
        .filter(
          (w) =>
            w.visibility === EnjcWorkspaceItemVisibility.Visible ||
            (showHidden && w.visibility === EnjcWorkspaceItemVisibility.Hidden),
        )
        .sort((a, b) => b.created - a.created),
    [showHidden, workspaces],
  );

  React.useEffect(() => {
    setCurrentStep(QUICK_START_TOUR_STEP_COUNT.OpenWorkspace);
  }, [filteredWorkspaces, setCurrentStep]);

  const handleCreateWorkspace = React.useCallback(() => {
    createWorkspace().then((updateResult) => {
      false ? navigate(`/workspace/${updateResult.workspace.id}`) : refetch();
    });
  }, [createWorkspace, navigate, refetch]);

  const handleCloneWorkspace = React.useCallback(() => {
    forkWorkspace('f149b16e', -1, undefined).then((updateResult) => {
      false ? navigate(`/workspace/${updateResult.workspace.id}`) : refetch();
    });
  }, [forkWorkspace, navigate, refetch]);

  return (
    <VStack gap={'50px'} align={'start'} h="100vh" w="100%" overflowY={'scroll'} py={'45px'} mt={'140px'} pl={'60px'}>
      <HStack position="absolute" top={'15px'} right={'26px'}>
        <SearchWorkspaces workspaces={filteredWorkspaces} />
        <Flex alignItems="center">
          <UserButton afterSignOutUrl={'/sign-in'} />
        </Flex>
      </HStack>

      <Heading size="md">{'Get started'}</Heading>
      <HStack gap={'50px'} wrap="wrap">
        <Button
          className={QUICK_START_TOUR_STEP_NAMES.CreateNewWorkspace}
          w={300}
          h={50}
          onClick={handleCreateWorkspace}
          style={{ fontSize: '15px', color: 'white' }}
          backgroundColor="primary.100"
          _hover={{ bg: 'primary.200' }}
          _active={{ opacity: '0.95', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 24px 48px' }}
        >
          {'Create New Workspace'}
        </Button>
        {false && (
          <Button w={300} h={50} style={{ fontSize: '15px' }} isDisabled variant="outline" borderColor="black">
            {'Shared Workspaces'}
          </Button>
        )}
        {false && (
          <Button w={300} h={50} mr={50} isDisabled style={{ fontSize: '15px' }} variant="outline" borderColor="black">
            {'Import Workspaces'}
          </Button>
        )}
        <Button
          w={300}
          h={50}
          style={{ fontSize: '15px' }}
          variant="outline"
          borderColor="black"
          onClick={handleCloneWorkspace}
        >
          {'Create Example Workspace'}
        </Button>
      </HStack>

      {/* TODO: Denys to introduce clonedWorkspaces  */}
      {/*{clonedWorkspaces.length > 0 && (*/}
      {/*  <>*/}
      {/*    <HStack>*/}
      {/*      <Heading size={'md'} pl={'15px'}>*/}
      {/*        {'Example workspaces'}*/}
      {/*      </Heading>*/}
      {/*    </HStack>*/}
      {/*  </>*/}
      {/*)}*/}
      {/*<WorkspacesListView workspaces={clonedWorkspaces} />*/}

      <HStack>
        <Heading size={'md'}>{'Recent workspaces'}</Heading>
        <Box display={'flex'} alignItems={'center'}>
          <Switch
            checked={showHidden}
            onCheckedChange={(checked: boolean) => setShowHidden(checked)}
            style={{ scale: '80%' }}
          />
          <Label>{'Show archived'}</Label>
        </Box>
      </HStack>
      <WorkspacesListView workspaces={filteredWorkspaces} performWorkspaceEdit={performWorkspaceEdit} />
    </VStack>
  );
};

export const AppDashboard = React.memo(AppDashboardF);
