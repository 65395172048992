import { EnjcStringDelta } from '../model';

export const invertStringDelta = (delta: EnjcStringDelta): EnjcStringDelta => {
  return [...delta].reverse().map((deltaEntry) => {
    return {
      slicePosition: deltaEntry.slicePosition,
      sliceBefore: deltaEntry.sliceAfter,
      sliceAfter: deltaEntry.sliceBefore,
    };
  });
};
