import { EnjcSymbolValueTree } from '../tree-core';
import { TValueTreeNodeKey, ValueTreeNode } from '../tree-node';
import { ValueTreeNodeKeyNotFoundError } from '../tree-errors';
import { ValueTreeFragment } from '../../../generated/graphql/operations';

export const getTreeNodeByKey = (valueTree: ValueTreeFragment, nodeKey: TValueTreeNodeKey): ValueTreeNode => {
  const node: ValueTreeNode | undefined = valueTree.nodes.find((n) => n.key === nodeKey);

  if (node === undefined) {
    throw new ValueTreeNodeKeyNotFoundError(nodeKey);
  }

  return node;
};

export const getTreeNodeByKeyBackup = (treeNodes: EnjcSymbolValueTree, nodeKey: TValueTreeNodeKey) => {
  const node = treeNodes.nodes.get(nodeKey);

  if (node === undefined) {
    throw new ValueTreeNodeKeyNotFoundError(nodeKey);
  }

  return node;
};
