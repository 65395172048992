import { TSymbolId } from '../types';
import { EnjcWorkspaceDTO, EnjicalcSymbol } from '../model';
import { getEnjcSymbolOrUndefined } from './getEnjcSymbolOrUndefined';

export const getEnjcSymbol = (enjicalcWorkspace: EnjcWorkspaceDTO, symbolId: TSymbolId): EnjicalcSymbol => {
  const result = getEnjcSymbolOrUndefined(enjicalcWorkspace, symbolId);
  if (result === undefined) {
    throw Error(`Symbol with id ${symbolId} not found`);
  }
  return result;
};
