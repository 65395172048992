import { invertAtomDelta, invertKeyListDelta, invertStringDelta } from '../../enjc-delta';
import { EnjcWorkspaceSectionDelta } from '../model';

export const invertSectionDelta = (delta: EnjcWorkspaceSectionDelta): EnjcWorkspaceSectionDelta => {
  return {
    id: delta.id,
    title: delta.title && invertStringDelta(delta.title),
    items: delta.items && invertKeyListDelta(delta.items),
    visibility: delta.visibility && invertAtomDelta(delta.visibility),
  };
};
