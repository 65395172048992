import React, { ReactElement } from 'react';
import {
  EnjcSymbolValueRootPlainView,
  SymbolValueBinaryOperatorPlainView,
  SymbolValueDraftPlainView,
  SymbolValueGlyphPlainView,
  SymbolValueGroupPlainView,
  SymbolValueLiteralPlainView,
} from './components';
import { EnjcSymbolValueCompositeView } from '../enjc-symbol-value-composite-view';
import { ISymbolValueViewOptions } from '../enjc-symbol-value-composite-view/interfaces';
import { UValueHintItem } from '../../../../libenjc/enjc-value-view-ctx/model/IValueTreeViewContext';

interface IProps {
  readonly viewOptions: ISymbolValueViewOptions;
  readonly onNodeClick?: (nodeKey: string) => void;
  readonly onHintClick?: (hint: UValueHintItem) => void;
}

export const EnjcSymbolValuePlainView = ({ viewOptions, onNodeClick, onHintClick }: IProps): ReactElement => {
  return (
    <EnjcSymbolValueCompositeView
      viewComponents={{
        rootViewComp: EnjcSymbolValueRootPlainView,
        draftViewComp: SymbolValueDraftPlainView,
        literalViewComp: SymbolValueLiteralPlainView,
        glyphViewComp: SymbolValueGlyphPlainView,
        groupViewComp: SymbolValueGroupPlainView,
        binaryOperatorViewComp: SymbolValueBinaryOperatorPlainView,
      }}
      // TODO: move to component props
      viewOptions={viewOptions}
      onNodeClick={onNodeClick ?? (() => {})}
      onHintClick={onHintClick ?? (() => {})}
    />
  );
};
