import { getEnjcFunction } from '../../enjc-workspace';
import { EnjcValueFunction, TValueFunctionId } from '../../enjc-value-math';
import { IValueTreeViewContext } from '../model';

export const getValueFunctionOrUndefinedNVTV = (
  vtvCtx: IValueTreeViewContext,
  functionId: TValueFunctionId | undefined,
): EnjcValueFunction | undefined => {
  return (functionId && getEnjcFunction(vtvCtx.workspace, functionId)) || undefined;
};
