import React, { ReactElement } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { EnjicalcSymbol, EnjicalcWorkspace } from '../../../libenjc/enjc-workspace';
import { SymbolEditor } from '../symbol-editor';
import { CloseSymbolEditorDialog } from '../symbol-editor/CloseSymbolEditorDialog';
import { ESections } from 'src/components/editors/symbol-editor/SymbolEditor';
import { QUICK_START_TOUR_STEP_NAMES } from 'src/hooks';

interface IProps {
  readonly workspace: EnjicalcWorkspace;
  readonly symbol: EnjicalcSymbol | undefined;
  readonly onClose: () => void;
  readonly section?: ESections;
}

const SymbolModalViewF = ({ workspace, symbol, onClose, section }: IProps): ReactElement => {
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const [isSaveButtonEnabled, setSaveButtonEnabled] = React.useState(false);

  const handleDialogClose = (isSaved: boolean) => {
    if (isSaved) {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  };

  const handleConfirmClose = () => {
    setDialogOpen(false);
    onClose();
  };

  const handleCancelClose = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    if (isSaveButtonEnabled) {
      setDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleSaveButtonState = React.useCallback((isEnabled: boolean) => {
    setSaveButtonEnabled(isEnabled);
  }, []);

  return (
    <>
      <Modal isOpen={!!symbol} onClose={openDialog} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent maxW="1000px" h="600px">
          <ModalHeader>Symbol Editor</ModalHeader>
          <ModalCloseButton onClick={openDialog} />
          <ModalBody className={QUICK_START_TOUR_STEP_NAMES.AddValueForSymbolGamma}>
            {symbol && (
              <SymbolEditor
                workspace={workspace}
                symbol={symbol}
                isOpen={isDialogOpen}
                onClose={handleDialogClose}
                onConfirm={handleConfirmClose}
                onCloseModal={onClose}
                onSaveButtonStateChange={handleSaveButtonState}
                section={section}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {isSaveButtonEnabled && (
        <CloseSymbolEditorDialog isOpen={isDialogOpen} onClose={handleCancelClose} onConfirm={handleConfirmClose} />
      )}
    </>
  );
};

export const SymbolModalView = React.memo(SymbolModalViewF);
