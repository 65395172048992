/**
 * Counts the number of matching characters between two strings.
 * @param str1 - The first string to compare.
 * @param str2 - The second string to compare.
 * @param countFromEnd - Specifies whether to count from the end of the strings.
 * @returns The number of matching characters.
 */
export const countMatchingChars = (str1: string, str2: string, countFromEnd: boolean): number => {
  let i = 0;
  while (
    i < str1.length &&
    i < str2.length &&
    str1[countFromEnd ? str1.length - 1 - i : i] === str2[countFromEnd ? str2.length - 1 - i : i]
  ) {
    i++;
  }
  return i;
};
