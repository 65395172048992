import React, { ReactElement } from 'react';
import { EnjcGlyphGroup } from '../../../enjc-glyph/model';
import { EnjcGlyphItemPlainView } from './EnjcGlyphItemPlainView';

interface IProps {
  readonly glyphGroup: EnjcGlyphGroup;
}

export const EnjcGlyphGroupPlainView = ({ glyphGroup }: IProps): ReactElement => {
  return (
    <>
      {glyphGroup.lb && <span>{glyphGroup.lb}</span>}
      {glyphGroup.items.map((groupItem, index, arr) => (
        <EnjcGlyphItemPlainView key={index} enjcGlyphItem={groupItem} />
      ))}
      {glyphGroup.rb && <span>{glyphGroup.rb}</span>}
    </>
  );
};
